import collectionsService from "services/collectionsService"
import addNewSource, { mapDataForSource } from "./helpers/sources"
import { SET_LAST_ADDED_SOURCE, UPDATE_ADDED_SOURCES } from "./"

export const SET_BUILDINGS_WITH_DEFAULT_SYSTEM = "SET_BUILDINGS_WITH_DEFAULT_SYSTEM"
export const SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA =
  "SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA"
export const UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES =
  "UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES"
export const LOADING_BUILDINGS_WITH_HEATING_SYSTEM =
  "LOADING_BUILDINGS_WITH_HEATING_SYSTEM"

export const updateAddedSecondarySystemSources = (newSources) => ({
  type: UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES,
  payload: newSources,
})

export const setLoadingBuildingsWithHeatingSystem = (loading) => ({
  type: LOADING_BUILDINGS_WITH_HEATING_SYSTEM,
  payload: loading,
})

export const buildingsWithDefaultSystem = (
  collectionId,
  systemName,
  addedSources,
  defaultSourceName
) => {
  return async (dispatch) => {
    dispatch(setLoadingBuildingsWithHeatingSystem(true))

    const data = await collectionsService.getBuildingsWithDefaultSystem(
      collectionId,
      systemName
    )

    dispatch(setLoadingBuildingsWithHeatingSystem(false))

    const newSources = addedSources
      .slice(0)
      .filter((source) => source.name !== defaultSourceName)

    const sourceToAdd = {
      name: defaultSourceName,
      data,
      isVisible: true,
      id: systemName,
    }

    data.features = mapDataForSource(data, sourceToAdd.name, systemName)

    newSources.push({
      ...sourceToAdd,
      data,
    })

    dispatch({
      type: SET_LAST_ADDED_SOURCE,
      payload: newSources[newSources.length - 1],
    })

    dispatch({
      type: UPDATE_ADDED_SOURCES,
      payload: newSources,
    })
  }
}

export const addSecondarySystemSource = (
  { collectionId, criteriaId, source, sources },
  defaultAddNewSourceError
) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA,
      payload: true,
    })

    const newSources = await addNewSource(
      {
        collectionId,
        criteriaId,
        source,
        sources,
      },
      defaultAddNewSourceError
    )

    // This is the case when addNewSource throws an error
    // ex: Added source doesn't has any data for it
    // In this case we don't update the sources
    if (newSources === sources) {
      dispatch({
        type: SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA,
        payload: false,
      })

      return
    }

    dispatch({
      type: SET_LAST_ADDED_SOURCE,
      payload: newSources[newSources.length - 1],
    })

    return updateAddedSecondarySystemSources(newSources)
  }
}
