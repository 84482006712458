import requestService from "./requestService"

class colorPaletteService {
  async getPalette() {
    const data = await requestService.get("/cp")

    return data
  }

  async getAllColors() {
    const data = await requestService.get("/clr")

    return data
  }

  async getAllEnergySystems() {
    const data = await requestService.get("/ocl?is_parent=false&ancestor_name=_EnergyConversionSystem")

    return data
  }


  async getAllBuildingTypes() {
    const data = await requestService.get("/obj?class_name=BuildingType")

    return data
  }
}

export default new colorPaletteService()
