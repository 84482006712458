export const SET_PROJECTS = "SET_PROJECTS"
export const SET_USE_CASES = "SET_USE_CASES"
export const SET_LOADING_PROJECTS = "SET_LOADING_PROJECTS"
export const SET_PROJECT_DATASETS = "SET_PROJECT_DATASETS"
export const SET_VERSION = "SET_VERSION"
export const SET_BUILDINGS_OF_VERSION = "SET_BUILDINGS_OF_VERSION"
export const SET_NETWORKS_OF_VERSION = "SET_NETWORKS_OF_VERSION"
export const SET_LOADING_VERSION = "SET_LOADING_VERSION"
export const SET_LOADING_NETWORKS_OF_VERSION = "SET_LOADING_NETWORKS_OF_VERSION"
export const SET_LOADING_BUILDINGS_OF_VERSION = "SET_LOADING_BUILDINGS_OF_VERSION"

import { SET_PERIMETERS } from "./perimeterActions"
import mapBuildings from "helpers/buildings"
import projectService from "services/projectsService"
import perimeterService from "services/perimeterService"

import { prepareBuildingsData } from "helpers/buildings"

const logProjectActinosError = (e) => console.log("ERROR ", e)

export const getProjects = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_PROJECTS,
    })

    try {
      const projects = await projectService.getProjects()

      dispatch({
        type: SET_PROJECTS,
        payload: { projects },
      })
    } catch (e) {
      dispatch({
        type: SET_PROJECTS,
        payload: { projects: [] },
      })
    }
  }
}

export const getDataForCreateProject = () => {
  return async (dispatch) => {
    Promise.all([
      projectService.getUseCases(),
      perimeterService.getPerimeters(),
      projectService.getDatasets(),
    ]).then(([useCases, perimeters, datasets]) => {
      dispatch({
        type: SET_USE_CASES,
        payload: { useCases },
      })

      dispatch({
        type: SET_PERIMETERS,
        payload: { perimeters: perimeters.features },
      })

      dispatch({
        type: SET_PROJECT_DATASETS,
        payload: { datasets },
      })
    })
  }
}

export const getBuildingsOfVersion = (versionId) => {
  return async (dispatch) => {
    if (!versionId) {
      return dispatch({
        type: SET_BUILDINGS_OF_VERSION,
        payload: {
          versionBuildings: [],
        },
      })
    }

    dispatch({
      type: SET_LOADING_BUILDINGS_OF_VERSION,
    })

    try {
      const data = await projectService.getBuildingsBasedOnVersion(versionId)
      const buildingsWithId = prepareBuildingsData(data)

      dispatch({
        type: SET_BUILDINGS_OF_VERSION,
        payload: { versionBuildings: mapBuildings(buildingsWithId?.features) },
      })
    } catch (e) {
      logProjectActinosError(e)

      dispatch({
        type: SET_BUILDINGS_OF_VERSION,
        payload: {
          versionBuildings: [],
          networksAndBuildingsError: "Cannot get networks and buildings",
        },
      })
    }
  }
}

export const getNetworksOfVersion = (versionId) => {
  return async (dispatch) => {
    if (!versionId) {
      return dispatch({
        type: SET_NETWORKS_OF_VERSION,
        payload: { versionNetworks: [] },
      })
    }

    dispatch({
      type: SET_LOADING_NETWORKS_OF_VERSION,
    })

    try {
      const data = await projectService.getNetworksBasedOnVersion(versionId)

      dispatch({
        type: SET_NETWORKS_OF_VERSION,
        payload: { versionNetworks: data?.features },
      })
    } catch (e) {
      logProjectActinosError(e)

      dispatch({
        type: SET_NETWORKS_OF_VERSION,
        payload: {
          versionNetworks: [],
          networksAndBuildingsError: "Cannot get networks and buildings",
        },
      })
    }
  }
}

export const getVersion = (versionId) => {
  return async (dispatch) => {
    if (!versionId) {
      return dispatch({
        type: SET_VERSION,
        payload: { version: null },
      })
    }

    dispatch({
      type: SET_LOADING_VERSION,
    })

    try {
      const version = await projectService.getVersion(versionId)

      dispatch({
        type: SET_VERSION,
        payload: { version: version },
      })
    } catch (e) {
      logProjectActinosError(e)

      dispatch({
        type: SET_VERSION,
        payload: {
          version: null,
        },
      })
    }
  }
}

export const setVersionBuildings = (buildings) => ({
  type: SET_BUILDINGS_OF_VERSION,
  payload: {
    versionBuildings: buildings,
  },
})
