import {
  ATTRIBUTES_UNIQUE_IDENTIFIERS,
  FILTER_FORMAT_KEYS,
} from "components/core/BuildingsSidebarFilters/constants"

import { AUTOSAVE_LOCAL_STORAGE_KEY } from "components/core/BuildingsSidebarFilters/components/summary/templateActions/constants"
import chartService from "services/chartService"
import { throwErrors } from "helpers/errors"

export const getAutosavedState = () => {
  if (typeof window === "undefined") return
  return JSON.parse(localStorage.getItem(AUTOSAVE_LOCAL_STORAGE_KEY)) || false
}

export const getTemplateAxes = (rawAxes) => {
  return rawAxes.map((axe, index) => {
    const axeData = {
      position: index + 1,
      criteria: axe.criteria || axe.id,
    }
    if (axe.energy_carrier) axeData.energy_carrier = axe.energy_carrier
    else if (axe.energy_system) axeData.energy_system = axe.energy_system
    return axeData
  })
}

export const getFormattedFilter = (rawFilter) => {
  const formattedFilter = {}
  for (const key of FILTER_FORMAT_KEYS) {
    formattedFilter[key] = rawFilter[key]
    if (!formattedFilter[key] && key === "propertyToFilter")
      formattedFilter[key] = rawFilter[key] || rawFilter.name
  }
  return formattedFilter
}

export const getNewSummaryTemplates = async (
  summaryTemplates,
  selectedTemplateId,
  newCriterias
) => {
  try {
    const newTemplate = await chartService.updateChartDetail(selectedTemplateId, {
      axes: getTemplateAxes(newCriterias),
    })
    return summaryTemplates.reduce((acc, template) => {
      if (template.id === Number(selectedTemplateId)) return [...acc, newTemplate]
      return [...acc, template]
    }, [])
  } catch (e) {
    throwErrors(e)
  }
}

export const getTemplateAxesData = (summaryTemplates, templateId) => {
  const newTemplateAxes =
    summaryTemplates.find((template) => template.id == templateId)?.axes || []

  return newTemplateAxes.reduce((acc, val) => {
    const axeData = {}
    for (const identifier of ATTRIBUTES_UNIQUE_IDENTIFIERS) {
      axeData[identifier] = identifier === "id" ? val.criteria : val[identifier]
    }
    axeData.position = val.position
    return [...acc, axeData]
  }, [])
}

export const updateSelected = ({ attribute, selectedAttributes = [] }) => {
  selectedAttributes.push(getFormattedFilter(attribute))
  return selectedAttributes
}

const getAllAttributes = (filteredAttributes, totalBuildingsNrAttribute) =>
  Object.values(filteredAttributes).reduce(
    (acc, categoryCriterias) => [...acc, ...categoryCriterias],
    [totalBuildingsNrAttribute]
  )
export const getSelectedTemplateFormattedAttributtes = (
  filteredAttributes,
  templateAxesData,
  totalBuildingsNrAttribute
) => {
  const allAttributes = getAllAttributes(
    filteredAttributes,
    totalBuildingsNrAttribute
  )
  return allAttributes.reduce((acc, criteria) => {
    for (const axeData of templateAxesData) {
      if (criteria.id == axeData.id) {
        acc = updateSelected({
          attribute: { ...criteria, ...axeData },
          selectedAttributes: acc,
        })
      }
    }
    return acc
  }, [])
}
