import {
  SET_HEATING_SYSTEM,
  LOADING_BUILDINGS_WITH_HEATING_SYSTEM,
  UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES,
  SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA,
} from "redux/actions/createCollectionPageActions"

export const energySystemDefaultState = {
  selectedHeatingSystem: null,
  loadingBuildingsWithHeatingSystem: false,
  addedSourcesForSecondarySystem: [],
  loadingSourceDataForSecondarySystem: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_HEATING_SYSTEM:
      return {
        ...state,
        selectedHeatingSystem: action.payload,
      }

    case UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES:
      return {
        ...state,
        loadingSourceDataForSecondarySystem: false,
        addedSourcesForSecondarySystem: action.payload,
      }

    case SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA:
      return {
        ...state,
        loadingSourceDataForSecondarySystem: action.payload,
      }
    case LOADING_BUILDINGS_WITH_HEATING_SYSTEM:
      return {
        ...state,
        loadingBuildingsWithHeatingSystem: action.payload,
      }

    default:
      return state
  }
}

export default reducer
