import { useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { getProjects, setMenuOpen } from "redux/actions/"

import styles from "./layoutWithSideBar.module.scss"

import Section from "components/core/Section"
import SideBar from "./layoutWithSideBar/SideBar"
import { ArrowCircleLeftIcon } from "components/core/SVGs"

const propTypes = {
  /** It's a component that will be rendered as main content */
  children: PropTypes.any,
}

const LayoutWithSideBar = ({ children }) => {
  const dispatch = useDispatch()
  const { menuOpen } = useSelector((state) => {
    return state.appReducer
  })

  useEffect(() => {
    dispatch(getProjects())
  }, [])

  return (
    <div className={classNames(styles.container, "main-container")}>
      <div
        className={classNames(styles.toggleIcon, {
          [styles.sideMenuClosed]: !menuOpen,
        })}
        onClick={() => dispatch(setMenuOpen(!menuOpen))}
      >
        <ArrowCircleLeftIcon />
      </div>
      <SideBar menuOpen={menuOpen} />
      <Section className={styles.content}>{children}</Section>
    </div>
  )
}

LayoutWithSideBar.propTypes = propTypes

export default LayoutWithSideBar
