import requestService from "./requestService"

class UserService {
  async login(data) {
    const { key } = await requestService.post("/auth/login/", null, data)
    return key
  }

  async getUser() {
    const user = await requestService.get("/auth/user/", {})

    return user
  }

  async updateUser(user) {
    const response = await requestService.put("/auth/user/", null, user)
    return response
  }

  signup(data) {
    return requestService.post("/auth/signup/", null, data)
  }

  sendResetPasswordEmail(data) {
    return requestService.post("/auth/password/reset/", null, data)
  }

  sendNewPasswordForReset(data) {
    return requestService.post("/auth/password/reset/confirm/", null, data)
  }

  async resetPassword(data) {
    return await requestService.post("/auth/password/change/", null, data)
  }

  async getUserOrganizationTeritory() {
    const data = await requestService.get(`/og`)
    return data
  }
}

export default new UserService()
