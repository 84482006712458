import PropTypes from "prop-types"
import styles from "./projectsSubMenu.module.scss"
import ProjectSubMenuItem from "./projectsSubMenu/ProjectSubMenuItem"
import ScenariosSubMenu from "./projectsSubMenu/ScenariosSubMenu"

const projectsSubmenuPropTypes = {
  /** object of projectId: projectName elements */
  projects: PropTypes.array,
  allProjectsScenarios: PropTypes.object,
}

const ProjectsSubMenu = ({ projects, allProjectsScenarios }) => {
  return (
    <div className={styles.projectsSubMenu}>
      {Array.isArray(projects) &&
        projects.map(
          (project) =>
            project?.id && (
              <ProjectSubMenuItem
                key={`project-submenuitem-${project.id}`}
                name={project.name}
                projectId={project.id}
              >
                <ScenariosSubMenu
                  scenarios={allProjectsScenarios[project.id]}
                  projectId={project.id}
                />
              </ProjectSubMenuItem>
            )
        )}
    </div>
  )
}

ProjectsSubMenu.propTypes = projectsSubmenuPropTypes

export default ProjectsSubMenu
