import {
  CLEAR_STATE,
  SET_HEADER_GROUP,
  SET_HEATING_SYSTEM,
  UPDATE_ADDED_SOURCES,
  SET_ATTRIBUTES_GROUPS,
  SET_LAST_ADDED_SOURCE,
  SET_UPDATE_IN_PROGRESS,
  SET_LOADING_SOURCE_DATA,
  SET_CURRENT_DRAW_CONTROL,
  SET_IS_VALID_NEW_PERIMETER,
  SET_BUILDINGS_OF_COLLECTION,
  SHOW_CONFIRM_ATTRIBUTE_MODAL,
  SET_PERIMETER_CREATION_ACTIVE,
  SET_LOADING_ATTRIBUTE_SOURCES,
  SET_SELECTED_ATTRIBUTE_DETAILS,
  SET_AVAILABLE_ATTRIBUTE_SOURCES,
  SET_SELECTED_GROUP_AND_ATTRIBUTE,
  SET_COLLECTION_CREATION_IN_PROGRESS,
  SET_FILES_TO_CREATE_COLLECTION_FROM,
  LOADING_BUILDINGS_WITH_HEATING_SYSTEM,
  UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES,
  SET_ENERGY_CERTIFICATES_TO_SELECT_FROM,
  SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA,
} from "redux/actions/createCollectionPageActions"
import energySystemReducer, {
  energySystemDefaultState,
} from "./energySystemAttributeState"

const defaultState = {
  addedSources: [],
  updateError: null,
  attributeGroup: null,
  attributesGroups: [],
  lastAddedSource: null,
  headerGroup: "building",
  updateInProgress: false,
  selectedAttribute: null,
  loadingSourceData: false,
  currentDrawControl: null,
  isValidNewPerimeter: false,
  collectionsBuildings: [],
  manuallyUpdatedFeatures: [],
  selectedAttributeDetails: null,
  confirmAttributeModalOpen: false,
  filesToCreateCollectionsFrom: [],
  collectionCreationInProgress: false,
  shouldTogglePerimeterCreation: false,
  energyCertificatesToSelectFrom: [],
  selectedAttributeAvailableSources: [],
  ...energySystemDefaultState,
}

export default function createCollectionPageReducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return defaultState

    case SET_HEADER_GROUP:
      return {
        ...state,
        headerGroup: action.payload,
      }
    case SET_ATTRIBUTES_GROUPS:
      return {
        ...state,
        attributesGroups: action.payload,
      }
    case SET_SELECTED_ATTRIBUTE_DETAILS:
      return {
        ...state,
        selectedAttributeDetails: action.payload,
      }
    case SET_LOADING_ATTRIBUTE_SOURCES:
      return {
        ...state,
        loadingAttributeSources: true,
      }
    case SET_COLLECTION_CREATION_IN_PROGRESS:
      return {
        ...state,
        collectionCreationInProgress: action.payload,
      }
    case SET_AVAILABLE_ATTRIBUTE_SOURCES:
      return {
        ...state,
        selectedAttributeAvailableSources: action.payload,
        loadingAttributeSources: false,
      }
    case SET_BUILDINGS_OF_COLLECTION:
      return {
        ...state,
        collectionsBuildings: action.payload,
      }
    case SHOW_CONFIRM_ATTRIBUTE_MODAL:
      return {
        ...state,
        confirmAttributeModalOpen: action.payload,
      }
    case SET_LOADING_SOURCE_DATA:
      return {
        ...state,
        loadingSourceData: action.payload,
      }
    case SET_SELECTED_GROUP_AND_ATTRIBUTE:
      return {
        ...state,
        attributeGroup: action.payload.group,
        selectedAttribute: action.payload.attribute,
        addedSources: [],
      }
    case UPDATE_ADDED_SOURCES:
      return {
        ...state,
        loadingSourceData: false,
        addedSources: action.payload,
      }
    case SET_LAST_ADDED_SOURCE:
      return {
        ...state,
        lastAddedSource: action.payload,
      }
    case SET_UPDATE_IN_PROGRESS:
      return {
        ...state,
        updateInProgress: action.payload,
      }
    case SET_PERIMETER_CREATION_ACTIVE: {
      return {
        ...state,
        perimeterCreationActive: action.payload,
      }
    }
    case SET_ENERGY_CERTIFICATES_TO_SELECT_FROM:
      return {
        ...state,
        energyCertificatesToSelectFrom: action.payload,
      }
    case SET_FILES_TO_CREATE_COLLECTION_FROM:
      return {
        ...state,
        filesToCreateCollectionsFrom: action.payload,
      }
    case SET_CURRENT_DRAW_CONTROL:
      return {
        ...state,
        currentDrawControl: action.payload,
      }
    case SET_IS_VALID_NEW_PERIMETER:
      return {
        ...state, 
        isValidNewPerimeter: action.payload
      }
    case SET_HEATING_SYSTEM:
    case UPDATE_ADDED_SECONDARY_SYSTEM_SOURCES:
    case SET_LOADING_SECONDARY_SYSTEM_SOURCE_DATA:
    case LOADING_BUILDINGS_WITH_HEATING_SYSTEM: {
      return energySystemReducer(state, action)
    }
    default:
      return state
  }
}
