import {
  SET_PROJECTS,
  SET_USE_CASES,
  SET_LOADING_PROJECTS,
  SET_PROJECT_DATASETS,
  SET_BUILDINGS_OF_VERSION,
  SET_VERSION,
  SET_LOADING_BUILDINGS_OF_VERSION,
  SET_NETWORKS_OF_VERSION,
  SET_LOADING_NETWORKS_OF_VERSION,
  SET_LOADING_VERSION,
} from "redux/actions/projectActions"

const defaultState = {
  projects: [],
  loading: true,
  useCases: [],
  datasets: [],
  loadingCollectionVersions: false,

  version: null,
  loadingVersionBuildings: false,
  versionBuildings: [],
  loadingVersionNetworks: false,
  versionNetworks: [],
  networksAndBuildingsError: "",
}

export default function projectReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects,
        loading: false,
      }
    case SET_LOADING_PROJECTS:
      return {
        ...state,
        loading: true,
      }
    case SET_USE_CASES:
      return {
        ...state,
        useCases: action.payload.useCases,
      }
    case SET_PROJECT_DATASETS:
      return {
        ...state,
        datasets: action.payload.datasets,
      }
    case SET_VERSION:
      return {
        ...state,
        version: action.payload.version,
        loadingVersion: false,
      }
    case SET_LOADING_VERSION:
      return {
        ...state,
        loadingVersion: true,
      }
    case SET_LOADING_NETWORKS_OF_VERSION:
      return {
        ...state,
        loadingVersionNetworks: true,
      }
    case SET_NETWORKS_OF_VERSION:
      return {
        ...state,
        versionNetworks: action.payload.versionNetworks,
        loadingVersionNetworks: false,
        networksAndBuildingsError: action.payload.networksAndBuildingsError,
      }
    case SET_BUILDINGS_OF_VERSION:
      return {
        ...state,
        versionBuildings: action.payload.versionBuildings,
        loadingVersionBuildings: false,
      }
    case SET_LOADING_BUILDINGS_OF_VERSION:
      return {
        ...state,
        loadingVersionBuildings: true,
      }

    default:
      return state
  }
}
