import { useTranslation as useReactI18NextTranslation } from "react-i18next"

const removeNewLines = (str) => str.replace(/\n|\r\n|\t|{" "}/g, " ")
const removeMultipleWhiteSpaces = (str) => str.replace(/\s{2,}/g, " ")
const removeTrailingAndLeadingWhiteSpaces = (str) => str.trim() //more efficient than replace(/^\s+|\s+$/g, "")

const useTranslation = (namespace = "common", ...restArgs) => {
  const { t, ...rest } = useReactI18NextTranslation(namespace, ...restArgs)

  const customT = (string, ...restArgs) => {
    let replacedStr = removeNewLines(string)
    replacedStr = removeMultipleWhiteSpaces(replacedStr)
    if (replacedStr !== removeTrailingAndLeadingWhiteSpaces(replacedStr)) {
      console.warn(
        `Detected trailing and/or leanding whitespace in translation of:  "${string}" . 
        Trailing and/or leanding whitespace  will be ignored in translations. 
        If they are needed please add the spaces outside the "trans" function`
      )
      replacedStr = removeTrailingAndLeadingWhiteSpaces(replacedStr)
    }
    return t(replacedStr, ...restArgs)
  }

  return { ...rest, t: customT, trans: customT }
}

export default useTranslation
