import { DropdownIcon, TextIcon, NumberIcon } from "components/core/SVGs"

export const TEXT_CRITERIA = "text"
export const DROPDOWN_CRITERIA = "dropdown"
export const NUMBER_CRITERIA = "number"

export const TEXT_CRITERIA_MAP = "string"
export const DROPDOWN_CRITERIA_MAP = "categorical"
export const NUMBER_CRITERIA_MAP = "float"

export const criteriaTypeMapping = (criteriaType) => {
  switch (criteriaType) {
    case TEXT_CRITERIA:
      return TEXT_CRITERIA_MAP
    case DROPDOWN_CRITERIA:
      return DROPDOWN_CRITERIA_MAP
    case NUMBER_CRITERIA:
      return NUMBER_CRITERIA_MAP
    default:
      return criteriaType
  }
}

export const criteriaTypeMappingReversed = (criteriaType) => {
  switch (criteriaType) {
    case TEXT_CRITERIA_MAP:
      return TEXT_CRITERIA
    case DROPDOWN_CRITERIA_MAP:
      return DROPDOWN_CRITERIA
    case NUMBER_CRITERIA_MAP:
      return NUMBER_CRITERIA
    default:
      return criteriaType
  }
}

export const filterEmptyField = (text) => {
  if (text?.trim().length) {
    return text
  }
  return ""
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const CriteriaModalbody = [
  {
    icon: <TextIcon />,
    type: TEXT_CRITERIA,
  },
  {
    icon: <NumberIcon />,
    type: NUMBER_CRITERIA,
  },
  {
    icon: <DropdownIcon />,
    type: DROPDOWN_CRITERIA,
  },
]
