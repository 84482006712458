import { stringifyJSON } from "helpers/jsonHelpers"

const DATE_RANGE_FILTERS_PROPS = ["constr_period"]

const getRangeFilterURL = (filter) => {
  // Building__storeys_above_ground__range=5&Building__storeys_above_ground__range=15
  const rangeFilterId = `${getFilterURLId(filter)}__range`

  return `${rangeFilterId}=${filter.value[0]}&${rangeFilterId}=${filter.value[1]}`
}

const getDateRangeFilterURL = (filter) => {
  // Building__constr_period_start__gte=1945&Building__constr_period_end__lte=2010
  const rangeFilterId = getFilterURLId(filter)

  return `${rangeFilterId}_start__gte=${filter.value[0]}&${rangeFilterId}_end__lte=${filter.value[1]}`
}

const getBooleanFilterURL = (filter) =>
  `${getFilterURLId(filter)}=${getBooleanValueForFilter(filter.value)}`

const getListFilterURL = (filter, energySystems) => {
  if (!filter?.value?.length) return ""
  const filterURLId = getFilterURLId(filter)

  let filterValues
  if (filter.name === "system_type")
    filterValues = filter.value.map(
      (label) => energySystems.find((system) => system.label === label).name
    )
  else filterValues = [...filter.value]
  const filterListValues = stringifyJSON(filterValues, true)

  return `${filterURLId}__in=${filterListValues}`
}

const getOrderByCriteriaName = (orderBy) => {
  if (DATE_RANGE_FILTERS_PROPS.indexOf(orderBy.criteriaName) === -1)
    return orderBy.criteriaName
  return orderBy.directionSign === "-"
    ? `${orderBy.criteriaName}_start`
    : `${orderBy.criteriaName}_end`
}

const getOrderByURL = (orderBy) =>
  orderBy.directionSign === "-"
    ? `order_by=-${orderBy.class_name}__${getOrderByCriteriaName(orderBy)}`
    : `order_by=${orderBy.class_name}__${getOrderByCriteriaName(orderBy)}`

const getBooleanValueForFilter = (booleanValue) =>
  booleanValue === true ? "True" : "False"

const getFilterFormattedPropertyToFilter = ({
  propertyToFilter,
  selectedCarrierType,
  name,
}) => {
  if (selectedCarrierType)
    return `${selectedCarrierType.class_name}.${selectedCarrierType.name}.${name}`
  return propertyToFilter
}

const getFilterURLId = (filter) =>
  `${filter.class_name}__${getFilterFormattedPropertyToFilter(filter)}`

export const getBuildingsQueryUrl = (
  mapBounds,
  mapFilters,
  orderBy,
  selectedPerimeter,
  energySystems
) => {
  let URL = ""

  if (selectedPerimeter) {
    URL += `perimeter_id=${selectedPerimeter.value}`
  } else if (mapBounds?._ne && mapBounds?._sw) {
    const north = mapBounds._ne.lat
    const est = mapBounds._ne.lng
    const south = mapBounds._sw.lat
    const west = mapBounds._sw.lng
    URL += `in_bbox=${west},${south},${est},${north}`
  }

  mapFilters.forEach((filter) => {
    if (filter.value !== false && !filter.value) return

    switch (filter.data_type) {
      case "date":
        // If filter is a date range we want to use getDateRangeFilterURL. If not it should act like float and integer
        if (DATE_RANGE_FILTERS_PROPS.indexOf(filter.propertyToFilter) >= 0) {
          URL += "&" + getDateRangeFilterURL(filter)
          break
        }
      // eslint-disable-next-line no-fallthrough
      case "float":
      case "integer":
        URL += "&" + getRangeFilterURL(filter)
        break
      case "boolean":
        URL += "&" + getBooleanFilterURL(filter)
        break
      case "categorical":
      case "string":
        URL += "&" + getListFilterURL(filter, energySystems)
        break
      default:
        break
    }
  })

  if (orderBy.class_name && orderBy.criteriaName) URL += `&${getOrderByURL(orderBy)}`

  return URL
}
