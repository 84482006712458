import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styled from "../sideBar.module.scss"

const sideBarItemPropTypes = {
  /** Icon that will be displayed for the menu item */
  Icon: PropTypes.elementType,
  /** Text that will be the name of the route || feature */
  text: PropTypes.string,
  /** This is a flag that defines if the component should
   *  show circle with notifications from the right. It's used
   *  for notifications and reporter page
   **/
  notifications: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  href: PropTypes.string,
}

/* eslint-disable react/display-name */
const SideBarItem = React.forwardRef((props, ref) => {
  const { Icon, text, notifications, onClick, active, href } = props
  return (
    <div
      className={classNames(styled.sideMenuItem, text, {
        [styled.sideMenuItemActive]: active,
      })}
      href={href}
      onClick={onClick}
      ref={ref}
    >
      <div className={styled.icon}>
        <Icon />

        {notifications && (
          <div className={classNames(styled.iconWithNotifications)}></div>
        )}
      </div>
      <p className={classNames(styled.text, "light", "fw-500")}>{text}</p>
      {notifications && (
        <div className={classNames(styled.notificationsCount)}>{notifications}</div>
      )}
    </div>
  )
})

SideBarItem.propTypes = sideBarItemPropTypes

export default SideBarItem
