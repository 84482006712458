import { HYDRATE } from "next-redux-wrapper"
import { SET_MENU_OPEN, SET_MAP_VIEW } from "../actions/appActions"

const defaultState = {
  menuOpen: false,
  appliedMapLayerName: "",
}

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload,
      }

    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload,
      }
    case SET_MAP_VIEW:
      return {
        ...state,
        appliedMapLayerName: action.payload,
      }
    default:
      return state
  }
}
