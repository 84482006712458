import { useState } from "react"
import { useRouter } from "next/router"
import classNames from "classnames"
import PropTypes from "prop-types"
import { removeVisitedScenario } from "redux/actions"
import { useDispatch, useSelector } from "react-redux"

import { CloseIcon, ScenarioIcon } from "components/core/SVGs"
import styles from "../../projectsSubMenu.module.scss"

const scenarioItemPropTypes = {
  /** scenario name */
  name: PropTypes.string,
  scenarioId: PropTypes.string,
  projectId: PropTypes.number,
}

const ScenarioItem = ({ projectId, scenarioId, name }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [showActionsIcons, setShowActionsIcons] = useState(false)

  const { visitingNowScenarioId } = useSelector((state) => state.sidebarReducer)

  return (
    <div
      className={classNames(
        "flex pb-10 last-child-no-pb pointer",
        visitingNowScenarioId === scenarioId ? styles.active : ""
      )}
      onClick={() => {
        router.push(`/sss/${scenarioId}`)
      }}
      onMouseEnter={() => setShowActionsIcons(true)}
      onMouseLeave={() => setShowActionsIcons(false)}
    >
      <div className={classNames(styles.iconNameWp, "content-verticaly-centered")}>
        <div className={classNames(styles.scenarioIcon)}>
          <ScenarioIcon />
        </div>
        <div className={classNames(styles.projectName, "fw-300 f-13 light")}>
          {name}
        </div>
      </div>
      {showActionsIcons && (
        <div className={classNames(styles.actions, "content-verticaly-centered")}>
          {/* #1zfpe2r: (removed temporarely untill functionality is added) */}
          {/* <ViewDetailsIcon /> */}
          <CloseIcon
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              dispatch(removeVisitedScenario(projectId, scenarioId))
            }}
          />
        </div>
      )}
    </div>
  )
}

ScenarioItem.propTypes = scenarioItemPropTypes

export default ScenarioItem
