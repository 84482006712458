import { useState } from "react"
import { useRouter } from "next/router"
import classNames from "classnames"
import PropTypes from "prop-types"
import { removeVisitedProject } from "redux/actions"
import { useDispatch, useSelector } from "react-redux"

import { ChartLineIcon, ArrowUpIcon, CloseIcon } from "components/core/SVGs"
import styles from "../projectsSubMenu.module.scss"

const projectSubmenuItemPropTypes = {
  /** project name */
  name: PropTypes.string,
  /** submenu with project scenarios */
  children: PropTypes.node,
  projectId: PropTypes.number,
}

const ProjectSubMenuItem = ({ name, children, projectId }) => {
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(true)
  const [showActionsIcons, setShowActionsIcons] = useState(false)
  const router = useRouter()

  const { visitingNowProjectId } = useSelector((state) => state.sidebarReducer)

  return (
    <>
      <div
        className={classNames("flex pb-15 last-child-no-pb pointer", {
          [styles.scenariosMenuClosed]: !menuOpen,
          [styles.active]: visitingNowProjectId === projectId,
        })}
        onClick={() => {
          router.push(`/mss/${projectId}`)
        }}
        onMouseEnter={() => setShowActionsIcons(true)}
        onMouseLeave={() => setShowActionsIcons(false)}
      >
        <div className={classNames(styles.iconNameWp, "content-verticaly-centered")}>
          <div className={classNames(styles.icon)}>
            <ChartLineIcon />
          </div>
          <div className={classNames(styles.projectName, "fw-400 f-16 light")}>
            {name}
          </div>
        </div>

        {showActionsIcons && (
          <div className={classNames(styles.actions, "content-verticaly-centered")}>
            {children && (
              <span
                className={styles.arrowIcon}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setMenuOpen(!menuOpen)
                }}
              >
                <ArrowUpIcon />
              </span>
            )}

            {/* #1zfpe2r (removed temporarely untill functionality is added) */}
            {/* <ViewDetailsIcon /> */}
            <CloseIcon
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                dispatch(removeVisitedProject(projectId))
              }}
            />
          </div>
        )}
      </div>
      {children}
    </>
  )
}

ProjectSubMenuItem.propTypes = projectSubmenuItemPropTypes

export default ProjectSubMenuItem
