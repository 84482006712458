import {
  SET_PRERENDER_FINISHED,
  SET_VERSION_MAP_SEARCH,
  SET_VERSION_MAP_FILTERS,
  SET_VERSION_MAP_ATTRIBUTES_LEGENDS,
  SET_VERSION_SELECTED_BUILDING,
  SET_VERSION_FILTERS_OPEN,
  INIT_VERSION_STATE,
  SET_VERSION_EDIT_MODE,
  SET_VERSION_MAP_ZOOM,
  SET_NOTIFY_USERS_BEFORE_LEAVE,
  SET_NEVER_SHOW_START_NEW_VERSION_MODAL,
  SET_NEVER_SHOW_FINISH_NEW_VERSION_MODAL,
  SET_VERSION_ZOOM_LOADING,
} from "../actions/svsActions"

const defaultState = {
  prerenderFinished: false,
  mapSelectedBuilding: null,
  mapAttributesLegends: [],
  mapSearchText: "",
  mapFilters: [],
  mapZoom: null,
  filtersOpen: false,
  editMode: false,
  notifyUsersBeforeLeave: false,
  neverOpenStartModal: false,
  neverOpenFinishModal: false,
  zoomLoading: true,
}

export default function svsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PRERENDER_FINISHED:
      return {
        ...state,
        prerenderFinished: action.payload,
      }
    case SET_VERSION_MAP_ATTRIBUTES_LEGENDS:
      return {
        ...state,
        mapAttributesLegends: action.payload,
      }
    case SET_VERSION_SELECTED_BUILDING:
      return {
        ...state,
        mapSelectedBuilding: action.payload,
      }
    case INIT_VERSION_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_VERSION_MAP_SEARCH:
      return {
        ...state,
        mapSearchText: action.payload,
      }
    case SET_VERSION_MAP_FILTERS:
      return {
        ...state,
        mapFilters: action.payload,
      }
    case SET_VERSION_FILTERS_OPEN:
      return {
        ...state,
        filtersOpen: action.payload,
      }
    case SET_VERSION_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      }
    case SET_NOTIFY_USERS_BEFORE_LEAVE:
      return {
        ...state,
        notifyUsersBeforeLeave: action.payload,
      }
    case SET_VERSION_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload.mapZoom,
        zoomLoading: action.payload.zoomLoading,
      }
    case SET_NEVER_SHOW_START_NEW_VERSION_MODAL:
      return {
        ...state,
        neverOpenStartModal: action.payload,
      }
    case SET_NEVER_SHOW_FINISH_NEW_VERSION_MODAL:
      return {
        ...state,
        neverOpenFinishModal: action.payload,
      }
    case SET_VERSION_ZOOM_LOADING:
      return {
        ...state,
        zoomLoading: action.payload,
      }
    default:
      return state
  }
}
