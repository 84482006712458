import { useEffect } from "react"
import { useDispatch } from "react-redux"
import ReactModal from "react-modal"
import PropTypes from "prop-types"

import { cloneMenuItem, getModalContentStyles } from "./helpers"
import { skipOnboarding } from "redux/actions/"

import Button from "components/core/Button"
import Text from "components/core/Text"
import Section from "components/core/Section"
import Flex from "components/core/Flex"
import styles from "./styles.module.scss"

const propTypes = {
  setStep: PropTypes.func,
}

const Notifications = ({ setStep }) => {
  const dispatch = useDispatch()

  const menuItem = document.querySelector(".Notifications")
  if (!menuItem?.getBoundingClientRect) {
    setStep(3)
    return null
  }
  const itemPosition = menuItem.getBoundingClientRect()

  const { content, arrow } = getModalContentStyles(itemPosition)

  useEffect(() => {
    const clonedElement = cloneMenuItem(menuItem)

    document.body.append(clonedElement)

    return () => document.body.removeChild(clonedElement)
  }, [])

  return (
    <ReactModal
      isOpen={true}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      style={{
        content,
      }}
      shouldCloseOnOverlayClick
      onRequestClose={() => dispatch(skipOnboarding())}
    >
      <Section style={arrow} className={styles.arrowLeft}></Section>
      <Text size="l" color="dark" fontWeight="semiBold" mB="s">
        Notification
      </Text>
      <Text color="light">
        You can track the recent activity of all your projects and follow the
        discussions of your teams.
      </Text>

      <Flex justifyContent="flexEnd" mT="s">
        <Button title="Next" sm onClick={() => setStep(3)} />
      </Flex>
    </ReactModal>
  )
}

Notifications.propTypes = propTypes

export default Notifications
