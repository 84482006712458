import requestService from "./requestService"

class criteriaService {
  async getCriteria(criteriaId = "462") {
    const data = await requestService.get(`/cr/${criteriaId}`)
    return data
  }

  async getCriteriaByName(
    criteriaName,
    classname,
    classNameQueryParam = "class_name"
  ) {
    const classnameQuery = classname ? `&${classNameQueryParam}=${classname}` : ""
    const data = await requestService.get(
      `/cr?name=${criteriaName}${classnameQuery}`
    )
    return data.find((criterion) => criterion.name === criteriaName)
  }
  async addCriteria(body) {
    const response = await requestService.post("/cr", null, body)
    return response
  }

  async patchCriteria(criteriaId, body) {
    const res = await requestService.patch(`/cr/${criteriaId}`, null, {
      ...body,
    })

    return res
  }

  async getCriteriaList(params) {
    const data = await requestService.get("/cr", params)
    return data
  }

  async getAvailableSources(criteriaId) {
    const data = await requestService.get(`/cr/${criteriaId}/sr`)

    return data
  }

  async getCriteriaLimits(criteriaId) {
    const data = await requestService.get(`/cr/${criteriaId}/limits`)

    return data
  }

  async discardCriteria(criteriaId) {
    const res = await requestService.patch(`/cr/${criteriaId}`, null, {
      discarded: true,
    })

    return res
  }
}

export default new criteriaService()
