export const marginPropTypes = ["xs", "s", "m", "l", "xl", "xxl", "huge"]

export const marginClassNames = (m, mT, mR, mB, mL, mH, mV) => ({
  // Margin
  "m-5": m === "xs",
  "m-10": m === "s",
  "m-15": m === "m",
  "m-20": m === "l",
  "m-30": m === "xl",
  "m-40": m === "xxl",
  "m-50": m === "huge",
  // Margin Tom
  "mt-5": mT === "xs",
  "mt-10": mT === "s",
  "mt-15": mT === "m",
  "mt-20": mT === "l",
  "mt-30": mT === "xl",
  "mt-40": mT === "xxl",
  "mt-50": mT === "huge",
  // Margin Right
  "mr-5": mR === "xs",
  "mr-10": mR === "s",
  "mr-15": mR === "m",
  "mr-20": mR === "l",
  "mr-30": mR === "xl",
  "mr-40": mR === "xxl",
  "mr-50": mR === "huge",
  // Margin Bottom
  "mb-5": mB === "xs",
  "mb-10": mB === "s",
  "mb-15": mB === "m",
  "mb-20": mB === "l",
  "mb-30": mB === "xl",
  "mb-40": mB === "xxl",
  "mb-50": mB === "huge",
  // Margin Left
  "ml-5": mL === "xs",
  "ml-10": mL === "s",
  "ml-15": mL === "m",
  "ml-20": mL === "l",
  "ml-30": mL === "xl",
  "ml-40": mL === "xxl",
  "ml-50": mL === "huge",
  // Margin Horizontal
  "mh-5": mH === "xs",
  "mh-10": mH === "s",
  "mh-15": mH === "m",
  "mh-20": mH === "l",
  "mh-30": mH === "xl",
  "mh-40": mH === "xxl",
  "mh-50": mH === "huge",
  // Margin Vertical
  "mv-5": mV === "xs",
  "mv-10": mV === "s",
  "mv-15": mV === "m",
  "mv-20": mV === "l",
  "mv-30": mV === "xl",
  "mv-40": mV === "xxl",
  "mv-50": mV === "huge",
})
