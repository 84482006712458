export const CLEAR_STATE = "CLEAR_STATE"
export const SET_UPDATE_ERROR = "SET_UPDATE_ERROR"
export const SET_HEADER_GROUP = "SET_HEADER_GROUP"
export const SET_HEATING_SYSTEM = "SET_HEATING_SYSTEM"
export const UPDATE_ADDED_SOURCES = "UPDATE_ADDED_SOURCES"
export const SET_LAST_ADDED_SOURCE = "SET_LAST_ADDED_SOURCE"
export const SET_ATTRIBUTES_GROUPS = "SET_ATTRIBUTES_GROUPS"
export const SET_UPDATE_IN_PROGRESS = "SET_UPDATE_IN_PROGRESS"
export const SET_LOADING_SOURCE_DATA = "SET_LOADING_SOURCE_DATA"
export const SET_CURRENT_DRAW_CONTROL = "SET_CURRENT_DRAW_CONTROL"
export const SET_SOURCES_FOR_ATTRIBUTE = "SET_SOURCES_FOR_ATTRIBUTE"
export const SET_IS_VALID_NEW_PERIMETER = "SET_IS_VALID_NEW_PERIMETER"
export const SET_BUILDINGS_OF_COLLECTION = "SET_BUILDINGS_OF_COLLECTION"
export const SHOW_CONFIRM_ATTRIBUTE_MODAL = "SHOW_CONFIRM_ATTRIBUTE_MODAL"
export const SET_LOADING_ATTRIBUTE_SOURCES = "SET_LOADING_ATTRIBUTE_SOURCES"
export const SET_PERIMETER_CREATION_ACTIVE = "SET_PERIMETER_CREATION_ACTIVE"
export const SET_SELECTED_ATTRIBUTE_DETAILS = "SET_SELECTED_ATTRIBUTE_DETAILS"
export const SET_AVAILABLE_ATTRIBUTE_SOURCES = "SET_AVAILABLE_ATTRIBUTE_SOURCES"
export const SET_SELECTED_GROUP_AND_ATTRIBUTE = "SET_SELECTED_GROUP_AND_ATTRIBUTE"
export const SET_FILES_TO_CREATE_COLLECTION_FROM =
  "SET_FILES_TO_CREATE_COLLECTION_FROM"
export const SET_COLLECTION_CREATION_IN_PROGRESS =
  "SET_COLLECTION_CREATION_IN_PROGRESS"
export const SET_ENERGY_CERTIFICATES_TO_SELECT_FROM =
  "SET_ENERGY_CERTIFICATES_TO_SELECT_FROM"

import addNewSource, { mapDataForSource } from "./helpers/sources"

import { MANUAL_UPDATE_SOURCE_ID } from "components/Collections/createCollectionVersion/constants"
import buildingsService from "services/buildingsService"
import collectionsService from "services/collectionsService"
import criteriaService from "services/criteriaService"
import { throwErrors } from "helpers/errors"
import { _EnergyConversionSystem } from "../buildingActions"

export * from "./energySystemAttributeActions.js"

export const clearState = () => ({
  type: CLEAR_STATE,
})

export const setHeaderGroup = (headerGroup) => ({
  type: SET_HEADER_GROUP,
  payload: headerGroup,
})

export const setCollectionCreationInProgress = (loading) => ({
  type: SET_COLLECTION_CREATION_IN_PROGRESS,
  payload: loading,
})

export const setAttributeUpdateInProgress = (loading) => ({
  type: SET_UPDATE_IN_PROGRESS,
  payload: loading,
})

export const setUpdateError = (error) => ({
  type: SET_UPDATE_ERROR,
  payload: error,
})

export const setShowConfirmAttributeModal = (isOpen) => ({
  type: SHOW_CONFIRM_ATTRIBUTE_MODAL,
  payload: isOpen,
})

export const setAttributesGroups = (attributesGroups) => ({
  type: SET_ATTRIBUTES_GROUPS,
  payload: attributesGroups,
})

export const setSelectedGroupAndAttribute = (group, attribute) => ({
  type: SET_SELECTED_GROUP_AND_ATTRIBUTE,
  payload: { group, attribute },
})

export const setCollectionHeatingSystem = (heatingSystem) => ({
  type: SET_HEATING_SYSTEM,
  payload: heatingSystem,
})

const getSources = async (dispatch, attributeId) => {
  dispatch({
    type: SET_LOADING_ATTRIBUTE_SOURCES,
  })

  const sources = await criteriaService.getAvailableSources(attributeId)

  dispatch({
    type: SET_AVAILABLE_ATTRIBUTE_SOURCES,
    payload: sources,
  })
}

export const getSelectedAttributeData = (attributeName, classname) => {
  return async (dispatch) => {
    const classNameQueryParam =
      classname === _EnergyConversionSystem ? "baseclass_name" : "class_name"

    const attributeData = await criteriaService.getCriteriaByName(
      attributeName,
      classname,
      classNameQueryParam
    )

    dispatch({
      type: SET_SELECTED_ATTRIBUTE_DETAILS,
      payload: attributeData
        ? { ...attributeData, propToQuery: attributeData.name }
        : {},
    })

    if (attributeData?.id) {
      getSources(dispatch, attributeData.id)
    }
  }
}

export const getDefaultCollectionBuildings =
  (collectionId, manualUpdateSourceName) => async (dispatch) => {
    try {
      const collectionsBuildings =
        await collectionsService.getAllBuildingsOfCollection(collectionId)

      collectionsBuildings.features = mapDataForSource(
        collectionsBuildings,
        manualUpdateSourceName,
        MANUAL_UPDATE_SOURCE_ID
      )
      dispatch({
        type: SET_BUILDINGS_OF_COLLECTION,
        payload: collectionsBuildings,
      })
    } catch (e) {
      throwErrors("Cannot fetch default collection buildings")
      // throw new Error("Cannot fetch default collection buildings")
    }
  }

export const getEnergyCertificates = () => {
  return async (dispatch) => {
    const energyCertificates = await buildingsService.getEnergyCertificates()

    dispatch({
      type: SET_ENERGY_CERTIFICATES_TO_SELECT_FROM,
      payload: Array.isArray(energyCertificates) ? energyCertificates : [],
    })
  }
}

export const getFilesToCreateCollectionFrom = () => {
  return async (dispatch) => {
    const buildingsFiles = await buildingsService.getBuildingsFiles()

    dispatch({
      type: SET_FILES_TO_CREATE_COLLECTION_FROM,
      payload: Array.isArray(buildingsFiles) ? buildingsFiles : [],
    })
  }
}

export const setCurrentDrawControl = (newDrawContext) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_DRAW_CONTROL,
      payload: newDrawContext,
    })
  }
}

export const setIsValidNewPerimeter = (isValid) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_VALID_NEW_PERIMETER,
      payload: isValid,
    })
  }
}

export const addSource = (
  { collectionId, criteriaId, source, sources },
  defaultError
) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_SOURCE_DATA,
      payload: true,
    })

    const newSources = await addNewSource(
      {
        collectionId,
        criteriaId,
        source,
        sources,
      },
      defaultError
    )

    // This is the case when addNewSource throws an error
    // ex: Added source doesn't has any data for it
    // In this case we don't update the sources
    if (newSources === sources) {
      dispatch({
        type: SET_LOADING_SOURCE_DATA,
        payload: false,
      })

      return
    }

    dispatch({
      type: SET_LAST_ADDED_SOURCE,
      payload: newSources[newSources.length - 1],
    })

    dispatch({
      type: UPDATE_ADDED_SOURCES,
      payload: newSources,
    })
  }
}

export const updateAddedSources = (newSources) => ({
  type: UPDATE_ADDED_SOURCES,
  payload: newSources,
})

const getManualUpdatesSource = (sources) => {
  const existingManualUpdatesSourceIndex = sources.findIndex(
    (source) => source.id === MANUAL_UPDATE_SOURCE_ID
  )

  return {
    sourceIndex: existingManualUpdatesSourceIndex,
    sourceObject:
      existingManualUpdatesSourceIndex !== -1
        ? sources[existingManualUpdatesSourceIndex]
        : null,
  }
}

const getFeatureDataObject = (
  feature,
  attributeName,
  value,
  manualUpdateSourceName
) => ({
  geometry: feature.geometry,
  type: feature.type,
  id: feature.id,
  properties: {
    ...feature.properties,
    [attributeName]: value,
    source_name: manualUpdateSourceName,
    source_id: MANUAL_UPDATE_SOURCE_ID,
  },
})

const addUpdates = (sourceObject, feature, featureData) => {
  const allUpdates = sourceObject.data.features
  const updatedFeatureIndex = sourceObject.data.features.findIndex(
    (oldFeature) => oldFeature.properties.featureId === feature.properties.featureId
  )

  if (updatedFeatureIndex === -1) {
    allUpdates.push(featureData)
  } else {
    allUpdates.splice(updatedFeatureIndex, 1, featureData)
  }

  return allUpdates
}

export const updateManuallyUpdatedFeatures = (
  value,
  attributeName,
  feature,
  addedSources,
  manualUpdateSourceName
) => {
  const newSources = addedSources.slice()
  const { sourceIndex, sourceObject } = getManualUpdatesSource(newSources)

  const featureData = getFeatureDataObject(
    feature,
    attributeName,
    value,
    manualUpdateSourceName
  )

  if (!sourceObject) {
    newSources.unshift({
      id: MANUAL_UPDATE_SOURCE_ID,
      name: manualUpdateSourceName,
      isVisible: true,
      data: {
        features: [featureData],
      },
    })

    return {
      type: UPDATE_ADDED_SOURCES,
      payload: newSources,
    }
  }

  const allUpdates = addUpdates(sourceObject, feature, featureData)

  sourceObject.data.features = allUpdates
  newSources[sourceIndex] = sourceObject

  return {
    type: UPDATE_ADDED_SOURCES,
    payload: newSources,
  }
}

export const setPerimeterCreationActive = (creationActive) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PERIMETER_CREATION_ACTIVE,
      payload: creationActive,
    })
  }
}
