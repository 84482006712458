const getValidJSON = (str) => {
  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}
const stringifyJSON = (el, keepWhiteSpace = false) => {
  if (keepWhiteSpace) {
    return el ? JSON.stringify(el).replace(/\s/g, " ") : el
  }
  return el ? JSON.stringify(el).replace(/\s/g, "") : el
}

const equalJSONs = (el1, el2) => {
  try {
    return stringifyJSON(el1) === stringifyJSON(el2)
  } catch {
    return false
  }
}

export { getValidJSON, stringifyJSON, equalJSONs }
