import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReactModal from "react-modal"
import PropTypes from "prop-types"
import Image from "next/image"

import { skipOnboarding, setMenuOpen } from "redux/actions/"

import { CloseIcon } from "components/core/SVGs"
import Button from "components/core/Button"
import Text from "components/core/Text"
import Section from "components/core/Section"
import Flex from "components/core/Flex"
import GroupImage from "assets/group.svg"
import styles from "./styles.module.scss"

const propTypes = {
  setStep: PropTypes.func,
}

const Intro = ({ setStep }) => {
  const dispatch = useDispatch()
  const userLastName = useSelector((state) => {
    return state.userReducer?.user?.last_name
  })

  useEffect(() => {
    dispatch(setMenuOpen(true))
  }, [])

  return (
    <ReactModal
      isOpen={true}
      overlayClassName={styles.overlay}
      className={styles.intro}
      ariaHideApp={false}
      onRequestClose={() => dispatch(skipOnboarding())}
      shouldCloseOnOverlayClick
    >
      <Section align="center" p="l">
        <Section
          className={styles.closeIcon}
          onClick={() => dispatch(skipOnboarding())}
        >
          <CloseIcon />
        </Section>

        <Image src={GroupImage} />

        <Text size="xl" mV="m">
          Hi {userLastName}, Lets take a quick walkthorugh
        </Text>

        <Text size="m" align="center" color="dark">
          Urbio is your companion through your journey of building sustainable
          neighborhoods and cities. Urbio will not only guide you through the best
          solutions for your project, but also provide you tools and resources to use
          throughout your project duration along with your team.
        </Text>
      </Section>
      <Flex
        alignItems="center"
        justifyContent="center"
        justifyCenter="center"
        bgColor="light"
        className={styles.footer}
      >
        <Button sm title="Start my walkthrough" onClick={() => setStep(2)} />
      </Flex>{" "}
    </ReactModal>
  )
}

Intro.propTypes = propTypes

export default Intro
