import {
  SET_CRITERIAS,
  SET_LOADING_CRITERIAS,
  DISCARD_CRITERIA,
} from "redux/actions/criteriaActions"
import { criteriaTypeMappingReversed } from "components/Criterias/helpers/modals"

const defaultState = {
  criterias: [],
  loadingCriterias: true,
}

export default function criteriaReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CRITERIAS:
      return {
        ...state,
        criterias: action.payload.criterias.map((criteria) => ({
          ...criteria,
          data_type: criteriaTypeMappingReversed(criteria.data_type),
        })),
        loadingCriterias: false,
      }
    case SET_LOADING_CRITERIAS:
      return {
        ...state,
        loadingCriterias: true,
      }
    case DISCARD_CRITERIA:
      return {
        ...state,
        criterias: state.criterias.filter((c) => c.id !== action.payload),
      }
    default:
      return state
  }
}
