const getArrayReducedByKey = (array, key = "id") =>
  array?.reduce((array, el) => [...array, el?.[key]], [])

const getJoinedArray = (array, key, sortArray) => {
  if (key) {
    array = getArrayReducedByKey(array, key)
  }
  return sortArray ? array?.concat?.().sort().join() : array?.join?.()
}

const compareArrays = (arr1, arr2, { key, sort } = {}) => {
  if (!Array.isArray(arr1)) arr1 = []
  if (!Array.isArray(arr2)) arr2 = []

  return getJoinedArray(arr1, key, sort) === getJoinedArray(arr2, key, sort)
}

const isArrayWithValues = (arr) => Array.isArray(arr) && arr.length > 0

const isArrayWithTruthyValues = (arr) => {
  if (!isArrayWithValues(arr)) return false
  for (const val of arr) {
    if (!!val) return true
  }
  return false
}

const sortByKey = (arr, key = "id") =>
  Array.isArray(arr) && arr.sort((t1, t2) => t1[key] - t2[key])

const removeElement = (array, elelementToRemove) => {
  const index = array.indexOf(elelementToRemove)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

const getLast = (arr) => (Array.isArray(arr) ? arr[arr.length - 1] : null)

export {
  getArrayReducedByKey,
  getJoinedArray,
  compareArrays,
  isArrayWithValues,
  sortByKey,
  removeElement,
  isArrayWithTruthyValues,
  getLast,
}
