import {
  EXPORT_BUILDINGS,
  EXPORT_CSV,
  EXPORT_SHP,
  EXPORT_SYSTEMS,
} from "components/core/BuildingsSidebarFilters/constants"
import { triggerDownload } from "helpers/downloadTrigger"
import { throwErrors } from "helpers/errors"
import buildingsService from "./buildingsService"

export const getFileTypeQuery = (fileType) => {
  if (fileType === EXPORT_CSV) return `format=csv`
  if (fileType === EXPORT_SHP) return `format=shp`
}

// sss and svs are using b2 endpoint for now while homepage is using b .
export const getExportTypePath = (exportType, buildingEndpointApiVersion = 1) => {
  if (exportType === EXPORT_BUILDINGS) {
    return buildingEndpointApiVersion == 1 ? `/b` : `/b${buildingEndpointApiVersion}`
  }
  if (exportType === EXPORT_SYSTEMS) return `/es`
}

export const downloadBuildings = async ({ url, fileType, trans, buildings }) => {
  const fileExtension = fileType === EXPORT_CSV ? ".csv" : ".zip"
  const params = buildings ? { buildings } : null

  try {
    const data = await buildingsService.exportBuildings(url, params)
    triggerDownload(data, `buildings${fileExtension}`)
  } catch (e) {
    throwErrors(
      e,
      trans(
        "Our apologies, something went wrong. Please contact our support in the help section of the app"
      ) + "."
    )
  }
}
