import { forwardRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
  colorsClassNames,
  colorsPropTypes,
  bgColorsClassNames,
  bgColorsPropTypes,
} from "../helpers/color"
import { paddingClassNames, paddingPropTypes } from "../helpers/padding"
import { marginClassNames, marginPropTypes } from "../helpers/margin"
import { alignClassNames, alignPropTypes } from "../helpers/typography"
import { widthClassNames, widthPropTypes } from "../helpers/width"
import { overflowClassNames, overflowPropTypes } from "../helpers/overflow"

const propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(alignPropTypes),
  color: PropTypes.oneOf(colorsPropTypes),
  bgColor: PropTypes.oneOf(bgColorsPropTypes),
  overflow: PropTypes.oneOf(overflowPropTypes),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onScroll: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  pointer: PropTypes.bool,
  width: PropTypes.oneOf(widthPropTypes),
  style: PropTypes.object,
  withTransition: PropTypes.bool,
  p: PropTypes.oneOf(paddingPropTypes),
  pT: PropTypes.oneOf(paddingPropTypes),
  pR: PropTypes.oneOf(paddingPropTypes),
  pB: PropTypes.oneOf(paddingPropTypes),
  pL: PropTypes.oneOf(paddingPropTypes),
  pH: PropTypes.oneOf(paddingPropTypes),
  pV: PropTypes.oneOf(paddingPropTypes),
  m: PropTypes.oneOf(marginPropTypes),
  mT: PropTypes.oneOf(marginPropTypes),
  mR: PropTypes.oneOf(marginPropTypes),
  mB: PropTypes.oneOf(marginPropTypes),
  mL: PropTypes.oneOf(marginPropTypes),
  mH: PropTypes.oneOf(marginPropTypes),
  mV: PropTypes.oneOf(marginPropTypes),
  data_testid: PropTypes.string,
}

// eslint-disable-next-line react/display-name
const Section = forwardRef(
  (
    {
      children,
      onClick,
      onScroll = () => {},
      onMouseEnter,
      onMouseLeave,
      pointer,
      align,
      color,
      bgColor,
      className,
      style,
      width,
      withTransition,
      overflow,
      p,
      pT,
      pR,
      pB,
      pL,
      pH,
      pV,
      m,
      mT,
      mR,
      mB,
      mL,
      mH,
      mV,
      data_testid,
      ...rest
    },
    ref
  ) => (
    <div
      ref={ref}
      className={classNames(className, {
        ...alignClassNames(align),
        ...colorsClassNames(color),
        ...bgColorsClassNames(bgColor),
        ...paddingClassNames(p, pT, pR, pB, pL, pH, pV),
        ...marginClassNames(m, mT, mR, mB, mL, mH, mV),
        ...widthClassNames(width),
        ...overflowClassNames(overflow),
        withTransition,
        pointer,
      })}
      onClick={onClick}
      onScroll={onScroll}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      data-testid={data_testid}
      {...rest}
    >
      {children}
    </div>
  )
)

Section.propTypes = propTypes

export default Section
