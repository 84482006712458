import {
  SET_MAP_SEARCH,
  SET_MAP_FILTERS,
  SET_LOADING_BUILDINGS,
  SET_BUILDINGS,
  APPEND_BUILDINGS,
  UPDATE_APPLIED_MAP_QUERRY_DATA,
  SET_MAP_ZOOM,
  SET_MAP_ATTRIBUTES_LEGENDS,
  SET_SELECTED_BUILDING,
  INIT_HOMEPAGE_STATE,
  SET_ORDER_BY,
  SET_SHOULD_GET_NEW_BUILDINGS,
  SET_ONLY_MAP_BOUNDS_CHANGED,
} from "../actions/homepageActions"

const defaultState = {
  buildings: [],
  loadingBuildings: false,
  mapSelectedBuilding: null,
  mapAttributesLegends: [],
  mapSearchText: "",
  mapFilters: [],
  orderBy: {},
  mapZoom: 0,
  mapQueryChanged: false,

  appliedMapQuery: {
    appliedMapFilters: [],
    appliedOrderBy: {},
    appliedMapBounds: {},
    appliedMapPerimeter: {},
  },
  onlyMapBoundsChanged: false,
}

export default function homepageReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_BUILDINGS:
      return {
        ...state,
        buildings: action.payload.buildings,
        loadingBuildings: action.payload.loadingBuildings || false,
      }
    case APPEND_BUILDINGS:
      return {
        ...state,
        buildings: [...state.buildings, ...action.payload.buildings],
        loadingBuildings: action.payload.loadingBuildings || false,
      }
    case UPDATE_APPLIED_MAP_QUERRY_DATA:
      return {
        ...state,
        appliedMapQuery: {
          appliedMapFilters: action.payload.mapFilters,
          appliedOrderBy: action.payload.orderBy,
          appliedMapBounds: action.payload.mapBounds,
          appliedMapPerimeter: action.payload.selectedPerimeter,
        },
      }
    case SET_LOADING_BUILDINGS:
      return {
        ...state,
        loadingBuildings: action.payload,
      }
    case SET_MAP_ATTRIBUTES_LEGENDS:
      return {
        ...state,
        mapAttributesLegends: action.payload,
      }
    case SET_SELECTED_BUILDING:
      return {
        ...state,
        mapSelectedBuilding: action.payload,
      }
    case INIT_HOMEPAGE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_MAP_SEARCH:
      return {
        ...state,
        mapSearchText: action.payload,
      }
    case SET_MAP_FILTERS:
      return {
        ...state,
        mapFilters: action.payload,
      }
    case SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload,
      }
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      }
    case SET_SHOULD_GET_NEW_BUILDINGS:
      return {
        ...state,
        mapQueryChanged: action.payload,
      }
    case SET_ONLY_MAP_BOUNDS_CHANGED:
      return {
        ...state,
        onlyMapBoundsChanged: action.payload,
      }

    default:
      return state
  }
}
