import PropTypes from "prop-types"

import styles from "../projectsSubMenu.module.scss"
import ScenarioItem from "./scenariosSubMenu/ScenarioItem"

const scenariosSubMenuPropTypes = {
  /** scenario name */
  scenarios: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.number,
}
const ScenariosSubMenu = ({ projectId, scenarios }) => (
  <div className={styles.scenariosSubMenu}>
    {Array.isArray(scenarios) &&
      scenarios.map((scenario) => (
        <ScenarioItem
          key={scenario?.id}
          name={scenario?.name}
          scenarioId={scenario?.id}
          projectId={projectId}
        />
      ))}
  </div>
)

ScenariosSubMenu.propTypes = scenariosSubMenuPropTypes

export default ScenariosSubMenu
