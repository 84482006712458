import requestService from "./requestService"

class ProjectsService {
  async getProjects() {
    const data = await requestService.get("/p?discarded=false")

    return data
  }

  async getProject(projectId = "923", discarded = false) {
    const data = await requestService.get(`/p/${projectId}?discarded=${discarded}`)

    return data
  }

  async createProject(projectData) {
    const data = await requestService.post(`/p`, null, projectData)

    return data
  }

  async discardProject(project) {
    const data = await requestService.put(`/p/${project.id}`, null, {
      ...project,
      discarded: true,
    })

    return data
  }

  async updateProject(projectId, data) {
    const res = await requestService.patch(`/p/${projectId}`, null, data)
    return res
  }

  async getProjectScenarios(
    projectId = "923",
    offset = 0,
    limit = 15,
    discarded = false
  ) {
    const data = await requestService.get(
      `/p/${projectId}/sc?offset=${offset}&limit=${limit}&discarded=${discarded}`
    )
    return data
  }

  async getBuildingEnergySystems() {
    const data = await requestService.get(
      "/ocl?is_parent=false&ancestor_name=_EnergyConversionSystem"
    )

    return data
  }

  async getStatuses() {
    const data = await requestService.get("/st")

    return data
  }

  async getUseCases() {
    const data = await requestService.get("/uc")

    return data
  }

  async getCollections() {
    const data = await requestService.get("/cl?discarded=false")

    return data
  }

  async getDatasets() {
    const data = await requestService.get("/ds?discarded=false")

    return data
  }

  async getVersion(versionId) {
    const data = await requestService.get(`/vs/${versionId}`)

    return data
  }

  async getBuildingsBasedOnVersion(versionId) {
    const data = await requestService.get(`/vs/${versionId}/b2`)

    return data
  }

  async getNetworksBasedOnVersion(versionId) {
    const data = await requestService.get(`/vs/${versionId}/nf`)

    return data
  }

  async createVersionNetworkLine(versionId, data) {
    return await requestService.post(`/vs/${versionId}/nf`, null, data)
  }

  async updateVersionNetworkLine(versionId, networkLineId, data) {
    return await requestService.put(
      `/vs/${versionId}/nf/${networkLineId}`,
      null,
      data
    )
  }

  async deleteVersionNetworkLine(versionId, networkLineId) {
    return await requestService.delete(`/vs/${versionId}/nf/${networkLineId}`, null)
  }

  async getIfVersionIsEditable(versionId) {
    try {
      await requestService.post(`/vs/${versionId}/nf`, null, {})
      return true
    } catch {
      return false
    }
  }
}

export default new ProjectsService()
