import classNames from "classnames"
import PropTypes from "prop-types"

import { colorsClassNames, colorsPropTypes } from "../helpers/color"
import { paddingClassNames, paddingPropTypes } from "../helpers/padding"
import { marginClassNames, marginPropTypes } from "../helpers/margin"
import {
  fontSizeClassNames,
  fontSizePropTypes,
  alignClassNames,
  alignPropTypes,
  fontWeightClassNames,
  fontWeightPropTypes,
  wordBreakClassNames,
  wordBreakPropTypes,
} from "../helpers/typography"

const propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(fontSizePropTypes),
  align: PropTypes.oneOf(alignPropTypes),
  color: PropTypes.oneOf(colorsPropTypes),
  wordBreak: PropTypes.oneOf(wordBreakPropTypes),
  fontWeight: PropTypes.oneOf(fontWeightPropTypes),
  className: PropTypes.string,
  inline: PropTypes.bool,
  pointer: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  uppercase: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOf(paddingPropTypes),
  pT: PropTypes.oneOf(paddingPropTypes),
  pR: PropTypes.oneOf(paddingPropTypes),
  pB: PropTypes.oneOf(paddingPropTypes),
  pL: PropTypes.oneOf(paddingPropTypes),
  pH: PropTypes.oneOf(paddingPropTypes),
  pV: PropTypes.oneOf(paddingPropTypes),
  m: PropTypes.oneOf(marginPropTypes),
  mT: PropTypes.oneOf(marginPropTypes),
  mR: PropTypes.oneOf(marginPropTypes),
  mB: PropTypes.oneOf(marginPropTypes),
  mL: PropTypes.oneOf(marginPropTypes),
  mH: PropTypes.oneOf(marginPropTypes),
  mV: PropTypes.oneOf(marginPropTypes),
  style: PropTypes.object,
}

const Text = ({
  children,
  onClick,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  size,
  align,
  color,
  fontWeight,
  wordBreak,
  className,
  inline,
  pointer,
  title,
  uppercase,
  style,
  p,
  pT,
  pR,
  pB,
  pL,
  pH,
  pV,
  m,
  mT,
  mR,
  mB,
  mL,
  mH,
  mV,
}) => (
  <p
    className={classNames(className, {
      ...fontSizeClassNames(size),
      ...fontWeightClassNames(fontWeight),
      ...alignClassNames(align),
      ...colorsClassNames(color),
      ...paddingClassNames(p, pT, pR, pB, pL, pH, pV),
      ...marginClassNames(m, mT, mR, mB, mL, mH, mV),
      ...wordBreakClassNames(wordBreak),
      inline,
      pointer,
      uppercase,
    })}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    title={`${title || ""}`}
    style={style}
  >
    {children}
  </p>
)

Text.propTypes = propTypes

export default Text
