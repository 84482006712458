import buildingsService, {
  downloadBuildings,
  getExportTypePath,
  getFileTypeQuery,
} from "services/buildingsService"

import { getBuildingsQueryUrl } from "components/Home/buildingsURLConstructor"
import mapBuildings from "helpers/buildings"
import { prepareBuildingsData } from "helpers/buildings"

export const SET_MAP_SEARCH = "SET_MAP_SEARCH"
export const SET_MAP_FILTERS = "SET_MAP_FILTERS"
export const SET_LOADING_BUILDINGS = "SET_LOADING_BUILDINGS"
export const SET_BUILDINGS = "SET_BUILDINGS"
export const APPEND_BUILDINGS = "APPEND_BUILDINGS"
export const SET_SELECTED_BUILDING = "SET_SELECTED_BUILDING"
export const SET_MAP_ZOOM = "SET_MAP_ZOOM"
export const SET_MAP_ATTRIBUTES_LEGENDS = "SET_MAP_ATTRIBUTES_LEGENDS"
export const INIT_HOMEPAGE_STATE = "INIT_HOMEPAGE_STATE"
export const SET_ORDER_BY = "SET_ORDER_BY"
export const SET_SHOULD_GET_NEW_BUILDINGS = "SET_SHOULD_GET_NEW_BUILDINGS"
export const SET_ONLY_MAP_BOUNDS_CHANGED = "SET_ONLY_MAP_BOUNDS_CHANGED"
export const UPDATE_APPLIED_MAP_QUERRY_DATA = "UPDATE_APPLIED_MAP_QUERRY_DATA"

let buildingOrdLoading = 0
export const DEFAULT_LIMIT = 200
export const DEFAULT_OFFSET = 0

export const setMapZoom = (zoom) => ({
  type: SET_MAP_ZOOM,
  payload: zoom,
})

const loadNextBuildings = async (
  dispatch,
  buildingsQueryUrl,
  offset,
  limit = 200,
  currentBuildingOrdLoading
) => {
  const data = await buildingsService.getHomeBuildings(
    `${buildingsQueryUrl}&offset=${offset}&limit=${limit}`
  )
  const buildingsWithId = prepareBuildingsData(data)

  if (
    buildingOrdLoading !== currentBuildingOrdLoading ||
    !buildingsWithId?.features?.length
  )
    return dispatch({ type: SET_LOADING_BUILDINGS, payload: false })

  const loadingBuildings = buildingsWithId?.features?.length === limit
  dispatch({
    type: APPEND_BUILDINGS,
    payload: {
      buildings: mapBuildings(buildingsWithId?.features || []),
      loadingBuildings: loadingBuildings,
    },
  })
  if (loadingBuildings) {
    loadNextBuildings(
      dispatch,
      buildingsQueryUrl,
      offset + limit,
      limit,
      currentBuildingOrdLoading
    )
  }
}

export const getHomepageBuildings = (
  mapBounds,
  mapFilters,
  orderBy,
  selectedPerimeter
) => {
  return async (dispatch, selector) => {
    const { buildingReducer } = selector()

    dispatch(setLoadingBuildings(true))
    dispatch(setMapQueryChanged(false))
    const buildingsQueryUrl = getBuildingsQueryUrl(
      mapBounds,
      mapFilters,
      orderBy,
      selectedPerimeter,
      buildingReducer.energySystems
    )
    const data = await buildingsService.getHomeBuildings(
      `${buildingsQueryUrl}&offset=${DEFAULT_OFFSET}&limit=${DEFAULT_LIMIT}`
    )
    const buildingsWithId = prepareBuildingsData(data)

    dispatch({
      type: SET_BUILDINGS,
      payload: {
        buildings: mapBuildings(buildingsWithId?.features || []),
        loadingBuildings: buildingsWithId?.features?.length === DEFAULT_LIMIT,
      },
    })
    buildingOrdLoading++
    loadNextBuildings(
      dispatch,
      buildingsQueryUrl,
      DEFAULT_OFFSET + DEFAULT_LIMIT,
      DEFAULT_LIMIT,
      buildingOrdLoading
    )
  }
}

export const updateAppliedMapQuery = (
  mapBounds,
  mapFilters,
  orderBy,
  selectedPerimeter
) => ({
  type: UPDATE_APPLIED_MAP_QUERRY_DATA,
  payload: { mapBounds, mapFilters, orderBy, selectedPerimeter },
})

export const exportDisplayedBuildings = ({ exportType, fileType, trans }) => {
  return async (_dispatch, selector) => {
    const { homepageReducer } = selector()
    const { buildingReducer } = selector()

    const {
      appliedMapBounds,
      appliedMapFilters,
      appliedOrderBy,
      appliedMapPerimeter,
    } = homepageReducer.appliedMapQuery

    const filteringConditionsUrl = getBuildingsQueryUrl(
      appliedMapBounds,
      appliedMapFilters,
      appliedOrderBy,
      appliedMapPerimeter,
      buildingReducer.energySystems
    )

    const exportTypePath = getExportTypePath(exportType)
    const urlWithExportType = `${exportTypePath}?${filteringConditionsUrl}`

    const endOrEmpty = filteringConditionsUrl.length > 0 ? "&" : ""
    const fileTypeUrl = getFileTypeQuery(fileType)
    const finalUrl = `${urlWithExportType}${endOrEmpty}${fileTypeUrl}`

    downloadBuildings({
      url: finalUrl,
      trans,
      fileType,
    })
  }
}

export const setLoadingBuildings = (loading) => ({
  type: SET_LOADING_BUILDINGS,
  payload: loading,
})

export const setMapQueryChanged = (shouldGet) => {
  return {
    type: SET_SHOULD_GET_NEW_BUILDINGS,
    payload: shouldGet,
  }
}

export const setOnlyMapBoundsChanged = (hasChanged) => {
  return {
    type: SET_ONLY_MAP_BOUNDS_CHANGED,
    payload: hasChanged,
  }
}

export const setMapAttributesLegends = (legends) => {
  return {
    type: SET_MAP_ATTRIBUTES_LEGENDS,
    payload: legends,
  }
}

export const setSelectedBuilding = (building) => {
  return {
    type: SET_SELECTED_BUILDING,
    payload: building,
  }
}

export const setMapFilters = (filters) => {
  localStorage.setItem("mapFilters", JSON.stringify(filters))

  return {
    type: SET_MAP_FILTERS,
    payload: filters,
  }
}

export const initMapFiltersOnRefresh = () => {
  const filters = JSON.parse(localStorage.getItem("mapFilters")) || []

  return {
    type: SET_MAP_FILTERS,
    payload: filters,
  }
}

export const setOrderBy = (class_name, criteriaName, directionSign) => ({
  type: SET_ORDER_BY,
  payload: {
    class_name,
    criteriaName,
    directionSign,
  },
})

export const resetOrderBy = () => ({
  type: SET_ORDER_BY,
  payload: {},
})
