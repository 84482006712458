import {
  getAllBuildingsWithUpdatedCustomValue,
  getBuildingWithUpdatedCustomValue,
} from "helpers/buildings"
import {
  downloadBuildings,
  getExportTypePath,
  getFileTypeQuery,
} from "services/buildingsService"
import { setVersionBuildings } from "./projectActions"

export const SET_PRERENDER_FINISHED = "SET_PRERENDER_FINISHED"
export const SET_VERSION_MAP_SEARCH = "SET_VERSION_MAP_SEARCH"
export const SET_VERSION_MAP_FILTERS = "SET_VERSION_MAP_FILTERS"
export const SET_VERSION_SELECTED_BUILDING = "SET_VERSION_SELECTED_BUILDING"
export const SET_VERSION_FILTERS_OPEN = "SET_VERSION_FILTERS_OPEN"
export const SET_VERSION_EDIT_MODE = "SET_VERSION_EDIT_MODE"
export const SET_VERSION_MAP_ATTRIBUTES_LEGENDS =
  "SET_VERSION_MAP_ATTRIBUTES_LEGENDS"
export const INIT_VERSION_STATE = "INIT_VERSION_STATE"
export const SET_VERSION_MAP_ZOOM = "SET_VERSION_MAP_ZOOM"
export const SET_NEVER_SHOW_START_NEW_VERSION_MODAL =
  "SET_NEVER_SHOW_NEW_VERSION_MODAL"
export const SET_NEVER_SHOW_FINISH_NEW_VERSION_MODAL =
  "SET_NEVER_SHOW_FINISH_NEW_VERSION_MODAL"
export const SET_NOTIFY_USERS_BEFORE_LEAVE = "SET_NOTIFY_USERS_BEFORE_LEAVE"
export const SET_VERSION_ZOOM_LOADING = "SET_VERSION_ZOOM_LOADING"

export const setVersionMapZoom = (mapZoom, zoomLoading) => ({
  type: SET_VERSION_MAP_ZOOM,
  payload: { mapZoom, zoomLoading },
})

export const setVersionZoomLoading = (zoomLoading) => ({
  type: SET_VERSION_ZOOM_LOADING,
  payload: zoomLoading,
})

export const setVersionEditMode = (editMode) => ({
  type: SET_VERSION_EDIT_MODE,
  payload: editMode,
})

export const setVersionMapAttributesLegends = (legends, versionId) => {
  localStorage.setItem(
    `collection${versionId}VersionMapAttributesLegends`,
    JSON.stringify(legends)
  )

  return {
    type: SET_VERSION_MAP_ATTRIBUTES_LEGENDS,
    payload: legends,
  }
}

export const setVersionFiltersOpen = (areOpen) => ({
  type: SET_VERSION_FILTERS_OPEN,
  payload: areOpen,
})

export const setVersionSelectedBuilding = (building) => {
  return {
    type: SET_VERSION_SELECTED_BUILDING,
    payload: building,
  }
}

export const setVersionMapSearch = (text) => ({
  type: SET_VERSION_MAP_SEARCH,
  payload: text,
})

export const setVersionMapFilters = (filters, projectId) => {
  localStorage.setItem(
    `project${projectId}VersionMapFilters`,
    JSON.stringify(filters)
  )

  return {
    type: SET_VERSION_MAP_FILTERS,
    payload: filters,
  }
}

export const setVersionNeverOpenStartModal = () => {
  localStorage.setItem("versionNeverOpenStartModal", true)

  return {
    type: SET_NEVER_SHOW_START_NEW_VERSION_MODAL,
    payload: true,
  }
}

export const setVersionNeverOpenFinishModal = () => {
  localStorage.setItem("versionNeverOpenFinishModal", true)

  return {
    type: SET_NEVER_SHOW_FINISH_NEW_VERSION_MODAL,
    payload: true,
  }
}

export const initVersionState = (versionId) => {
  return {
    type: INIT_VERSION_STATE,
    payload: {
      mapAttributesLegends:
        JSON.parse(
          localStorage.getItem(`collection${versionId}VersionMapAttributesLegends`)
        ) || [],
      mapFilters:
        JSON.parse(
          localStorage.getItem(`collection${versionId}VersionMapFilters`)
        ) || [],
      neverOpenStartModal:
        JSON.parse(localStorage.getItem(`versionNeverOpenStartModal`)) || false,
      neverOpenFinishModal:
        JSON.parse(localStorage.getItem(`versionNeverOpenFinishModal`)) || false,
      versionWasEdited: false,
    },
  }
}

export const setPrerenderFinished = (prerenderFinished) => {
  return {
    type: SET_PRERENDER_FINISHED,
    payload: prerenderFinished,
  }
}

export const setNotifyUsersBeforeLeave = (notifyUsers) => ({
  type: SET_NOTIFY_USERS_BEFORE_LEAVE,
  payload: notifyUsers,
})

export const updateVersionSelectedBuilding = (currentBuilding, criteria, value) => {
  return (dispatch, selector) => {
    const updatedBuilding = getBuildingWithUpdatedCustomValue(
      currentBuilding,
      criteria.name,
      value
    )
    dispatch({
      type: SET_VERSION_SELECTED_BUILDING,
      payload: updatedBuilding,
    })
    const { projectReducer } = selector()
    const buildings = [...projectReducer.versionBuildings]
    const newBuildings = getAllBuildingsWithUpdatedCustomValue(
      buildings,
      currentBuilding,
      criteria.name,
      value
    )
    dispatch(setVersionBuildings(newBuildings))
  }
}

export const exportSVSDisplayedBuildings = ({
  filteredBuildings,
  versionId,
  exportType,
  fileType,
  trans,
}) => {
  return async (_dispatch) => {
    const exportTypePath = getExportTypePath(exportType, 2)

    const fileTypeUrl = getFileTypeQuery(fileType)
    const finalUrl = `/vs/${versionId}${exportTypePath}?${fileTypeUrl}`

    downloadBuildings({
      url: finalUrl,
      trans,
      fileType,
      buildings: filteredBuildings,
    })
  }
}
