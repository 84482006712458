import { toast } from "react-toastify"

const ERRORS_STATUSES_WITH_MESSAGES = [400, 403, 404, 412]

const requestContainsError = (res) =>
  typeof res == "string" &&
  res.indexOf("Traceback ") !== -1 &&
  res.indexOf("Error") !== -1

const checkValidScenarios = (scenarios) => scenarios && Array.isArray(scenarios)

const throwDefaultError = (defaultError) =>
  defaultError &&
  toast(defaultError, {
    type: "error",
  })

const throwErrors = (e, defaultError) => {
  const errors = []

  console.groupCollapsed("Error(s) throwed(throwErrors): ")
  console.error(e)
  console.groupEnd()
  if (ERRORS_STATUSES_WITH_MESSAGES.includes(e?.status)) {
    if (Array.isArray(e.data)) {
      e.data.map((errorKey) => {
        const currentError = e.data[errorKey]
        if (currentError) {
          if (Array.isArray(currentError))
            currentError.forEach((error) => errors.push(error))
          else errors.push(currentError)
        }
      })
    } else {
      errors.push(e.data)
    }

    errors.length
      ? errors.forEach((error) => {
          let fieldError

          if (error?.non_field_errors?.length)
            fieldError = error?.non_field_errors[0]

          toast(error?.detail || fieldError || error, {
            type: "error",
          })
        })
      : throwDefaultError(defaultError)
  } else {
    throwDefaultError(defaultError)
  }
}

const getUnreachableAPIErrorMessage = (language) => {
  switch (language) {
    case "en":
      return "Our apologies, something went wrong. Please contact our support in the help section of the app."
    case "de":
      return "Wir bitten um Entschuldigung, etwas ist schief gelaufen. Bitte kontaktieren Sie unseren Support im Hilfemenü der App."
    case "fr":
      return "Toutes nos excuses, quelque chose a mal tourné. Veuillez contacter notre service d'assistance dans la section d'aide de l'application."
  }
}

export {
  requestContainsError,
  checkValidScenarios,
  throwErrors,
  getUnreachableAPIErrorMessage,
}
