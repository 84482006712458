import { getUpdatedTabs } from "components/MSS/commons/chartTabsHelpers"
import { sortByKey } from "helpers/arrayHelpers"
import {
  SET_MSS_STATE,
  CHANGE_COLORED_AXE,
  UPDATE_AXES,
  SET_DEFAULT_CHART_DATA,
  SET_DEFAULT_CHART_LOADING,
  SET_TABS,
  SET_SELECTED_TAB,
  ADD_TAB,
  DELETE_TAB,
  UPDATE_TAB,
  SET_HIGHLIGHTED_CARD,
  SET_HIGHLIGHTED_AXES,
  UPDATE_BRUSH,
  CHANGE_TAB_STATE,
  SET_CRITERIA_RANGE,
  SET_LIMIT,
  SET_AXES,
  SET_FILTERED_SCENARIOS,
  SET_CURRENT_CRITERIAS_TO_EXPORT,
  getUpdatedBrushes,
  UPDATE_MSS_STATE,
  SET_SSS_LOADING,
  RESET_MSS_STATE,
} from "redux/actions"

const defaultState = {
  brushes: {},
  highlightedCard: "",
  highlightedAxes: [],
  axes: [],
  limits: {},
  displayRanges: {},
  coloredAxe: "",
  initialised: false,
  loadingBrushes: true,
  defaultChartData: {},
  loadingDefaultChartData: true,
  filteredScenarios: [],
  currentCriteriasToExport: {},
  tabs: [],
  selectedTabIndex: 0,
  sssLoading: false,
}

export default function mssReducer(state = defaultState, action) {
  switch (action.type) {
    case RESET_MSS_STATE:
      return defaultState
    case SET_MSS_STATE:
      return {
        ...state,
        ...action.payload.state,
        tabs: sortByKey(getUpdatedTabs(state.tabs, action.payload.tab)),
        loadingBrushes: false,
      }
    case UPDATE_MSS_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_BRUSH:
      return {
        ...state,
        brushes: getUpdatedBrushes({ brushes: state.brushes, ...action.payload }),
      }
    case SET_CRITERIA_RANGE:
      return {
        ...state,
        displayRanges: { ...state.displayRanges, ...action.payload.range },
        tabs: action.payload.tabs,
      }
    case CHANGE_COLORED_AXE:
      return {
        ...state,
        coloredAxe: action.payload.coloredAxe,
        tabs: action.payload.tabs,
        loadingBrushes: false,
      }
    case SET_LIMIT:
      return {
        ...state,
        limits: { ...state.limits, ...action.payload.limit },
        tabs: action.payload.tabs,
      }
    case SET_HIGHLIGHTED_CARD:
      return {
        ...state,
        highlightedCard: action.payload,
      }
    case SET_HIGHLIGHTED_AXES:
      return {
        ...state,
        highlightedAxes: action.payload,
      }
    case SET_AXES:
      return {
        ...state,
        ...action.payload.state,
        tabs: action.payload.newTabs,
      }
    case UPDATE_AXES:
      return {
        ...state,
        axes: action.payload.axes,
        tabs: action.payload.tabs,
        loadingBrushes: false,
      }
    case SET_DEFAULT_CHART_LOADING:
      return {
        ...state,
        loadingDefaultChartData: action.payload,
      }
    case SET_DEFAULT_CHART_DATA:
      return {
        ...state,
        loadingDefaultChartData: false,
        defaultChartData: action.payload,
      }
    case SET_TABS:
      return {
        ...state,
        tabs: sortByKey(action.payload),
      }
    case CHANGE_TAB_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTabIndex: action.payload,
      }
    case ADD_TAB:
      return {
        ...state,
        tabs: sortByKey([...state.tabs, action.payload]),
      }
    case DELETE_TAB:
      return {
        ...state,
        tabs: state.tabs.filter((tab) => tab.id !== action.payload),
      }
    case UPDATE_TAB:
      return {
        ...state,
        tabs: getUpdatedTabs(state.tabs, action.payload),
      }
    case SET_FILTERED_SCENARIOS:
      return {
        ...state,
        filteredScenarios: action.payload.filteredScenarios,
      }
    case SET_CURRENT_CRITERIAS_TO_EXPORT:
      return {
        ...state,
        currentCriteriasToExport: action.payload.currentCriteriasToExport,
      }
    case SET_SSS_LOADING:
      return {
        ...state,
        sssLoading: action.payload,
      }
    default:
      return state
  }
}
