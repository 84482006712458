import {
  SET_SCENARIO_MAP_SEARCH,
  SET_SCENARIO_MAP_FILTERS,
  SET_SCENARIO_MAP_ATTRIBUTES_LEGENDS,
  SET_SCENARIO_DRAW_MODE,
  SET_SCENARIO_SELECTED_BUILDING,
  SET_SCENARIO_ALWAYS_COPY,
  SET_SCENARIO_FILTERS_OPEN,
  SET_SCENARIO_MODAL_OPEN,
  INIT_SCENARIO_STATE,
  SET_SCENARIO_EDIT_MODE,
  SET_SCENARIO_MAP_ZOOM,
  SET_SCENARIO_ZOOM_LOADING,
} from "../actions/sssActions"

const defaultState = {
  mapSelectedBuilding: null,
  mapAttributesLegends: [],
  mapSearchText: "",
  mapFilters: [],
  mapZoom: null,
  drawMode: null,
  alwaysCopy: false,
  filtersOpen: false,
  modalOpen: false,
  editMode: false,
  zoomLoading: true,
}

export default function sssReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SCENARIO_MAP_ATTRIBUTES_LEGENDS:
      return {
        ...state,
        mapAttributesLegends: action.payload,
      }
    case SET_SCENARIO_SELECTED_BUILDING:
      return {
        ...state,
        mapSelectedBuilding: action.payload,
      }
    case INIT_SCENARIO_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SCENARIO_MAP_SEARCH:
      return {
        ...state,
        mapSearchText: action.payload,
      }
    case SET_SCENARIO_MAP_FILTERS:
      return {
        ...state,
        mapFilters: action.payload,
      }
    case SET_SCENARIO_DRAW_MODE:
      return {
        ...state,
        drawMode: action.payload,
      }
    case SET_SCENARIO_ALWAYS_COPY:
      return {
        ...state,
        alwaysCopy: true,
      }
    case SET_SCENARIO_FILTERS_OPEN:
      return {
        ...state,
        filtersOpen: action.payload,
      }
    case SET_SCENARIO_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      }
    case SET_SCENARIO_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      }
    case SET_SCENARIO_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload.zoom,
        zoomLoading: action.payload.zoomLoading,
      }
    case SET_SCENARIO_ZOOM_LOADING:
      return {
        ...state,
        zoomLoading: action.payload,
      }
    default:
      return state
  }
}
