export const getUpdatedTabs = (tabs, newTab, newAxe) => {
  if (!newTab && !newAxe) {
    return tabs
  }
  if (newAxe) {
    newTab.axes = newTab.axes.map((axe) =>
      axe.id === newAxe.id ? { ...axe, ...newAxe } : axe
    )
  }
  return tabs.map((tab) => (tab.id === newTab.id ? { ...tab, ...newTab } : tab))
}

export const getStatePayload = ({
  brushes,
  axes,
  limits,
  selectedTabIndex,
  newTabs,
  coloredAxe,
  displayRanges,
}) => {
  const newState = {}
  if (brushes) {
    newState.brushes = brushes
  }
  if (axes) {
    newState.axes = axes
  }
  if (limits) {
    newState.limits = limits
  }
  if (Math.sign(selectedTabIndex) >= 0) {
    newState.selectedTabIndex = selectedTabIndex
  }
  if (coloredAxe) {
    newState.coloredAxe = coloredAxe
  }
  if (newTabs) {
    newState.tabs = newTabs
  }
  if (displayRanges) {
    newState.displayRanges = displayRanges
  }
  return newState
}

export const getChartBrushes = (brushes, criteriaId) => {
  if (!brushes) return {}
  const axBrush = brushes[criteriaId]
  if (!axBrush) return { brush_min: null, brush_max: null }
  return {
    brush_min: axBrush[0],
    brush_max: axBrush[1],
  }
}

export const getChartDetailData = ({
  brushes,
  axes,
  limits,
  coloredAxe,
  displayRanges,
}) => {
  const chartAxes = []
  if (axes) {
    for (const index in axes) {
      const criteriaId = axes[index]
      let chartAxe = {
        criteria: criteriaId,
        position: index + 1,
        ...getChartBrushes(brushes, criteriaId),
      }
      if (displayRanges?.[criteriaId]) {
        const [display_min, display_max] = displayRanges[criteriaId]
        chartAxe = { ...chartAxe, display_min, display_max }
      }
      if (limits) {
        chartAxe.optim_sense = limits[criteriaId] || null
      }
      if (coloredAxe) {
        chartAxe.color_by = coloredAxe === criteriaId
      }
      chartAxes.push(chartAxe)
    }
  }
  return { axes: chartAxes }
}

export const getChartAxeByCriteriaId = (axes, criteriaId) =>
  axes.find((el) => el.criteria.toString() === criteriaId.toString())

export const getTabAxisByCriteriaId = (tab, criteriaId) =>
  tab?.axes?.find((axe) => axe.criteria.toString() === criteriaId?.toString())

export const getUpdatedAxesPositioning = ({ tabs, selectedTabIndex, axes }) => {
  const { axes: tabAxes } = tabs[selectedTabIndex]
  const getAxeOrder = (axe, index) => {
    const axePosition = axes.indexOf(axe.criteria.toString())
    return {
      ...axe,
      position: axePosition !== -1 ? axePosition + 1 : axePosition + 99 + index,
    }
  }
  const changedAxes = tabAxes.map(getAxeOrder)
  const newTabs = [...tabs]
  newTabs[selectedTabIndex].axes = changedAxes
  return {
    chartId: tabs[selectedTabIndex].id,
    changedAxes,
    newTabs,
  }
}
