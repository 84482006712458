import { toast } from "react-toastify"
import { throwErrors } from "helpers/errors"

export const SET_PERIMETERS = "SET_PERIMETERS"
export const SET_PERIMETER_GEOMETRY = "SET_PERIMETER_GEOMETRY"
export const RESET_PERIMETER_GEOMETRY = "RESET_PERIMETER_GEOMETRY"
export const ADD_NEW_PERIMETER = "ADD_NEW_PERIMETER"
export const SET_LOADING_PERIMETERS = "SET_LOADING_PERIMETERS"
export const SET_SELECTED_PERIMETER = "SET_SELECTED_PERIMETER"

import perimeterService from "services/perimeterService"

export const getPerimeters = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_PERIMETERS,
      payload: true,
    })

    try {
      const perimeters = await perimeterService.getPerimeters()

      dispatch({
        type: SET_PERIMETERS,
        payload: { perimeters: perimeters?.features },
      })
    } catch (e) {
      dispatch({
        type: SET_PERIMETERS,
        payload: { perimeters: [] },
      })
    }
  }
}

export const getPerimeterGeometry = (perimeterId) => async (dispatch) => {
  if (!perimeterId) {
    dispatch({
      type: SET_PERIMETER_GEOMETRY,
      payload: { perimeterGeometry: {} },
    })
    return false
  }

  try {
    const geometry = await perimeterService.getPerimeterGeometry(perimeterId)

    dispatch({
      type: SET_PERIMETER_GEOMETRY,
      payload: { perimeterGeometry: geometry },
    })

    return true
  } catch (e) {
    dispatch({
      type: SET_PERIMETER_GEOMETRY,
      payload: { perimeterGeometry: {} },
    })
    throwErrors(e)
    return false
  }
}

export const resetPerimeterGeometry = () => ({
  type: RESET_PERIMETER_GEOMETRY,
})

export const createProjectPerimeter = (params, cb) => async (dispatch) => {
  try {
    const createdPerimeter = await perimeterService.createPerimeter(params)

    /**
     * Sometimes error is not thrown, but a phyton error string is returned, so the string
     * won't be catched by the catch block
     */
    if (typeof createdPerimeter === "string") {
      toast("Cannot create new perimeter", {
        type: "error",
      })

      return
    }

    dispatch({
      type: ADD_NEW_PERIMETER,
      payload: createdPerimeter,
    })

    cb(createdPerimeter)
  } catch (err) {
    throwErrors(err)
  }
}

export const setSelectedPerimeter = (perimeter) => {
  return {
    type: SET_SELECTED_PERIMETER,
    payload: perimeter,
  }
}
