import {
  SET_PERIMETERS,
  ADD_NEW_PERIMETER,
  SET_LOADING_PERIMETERS,
  SET_PERIMETER_GEOMETRY,
  RESET_PERIMETER_GEOMETRY,
  SET_SELECTED_PERIMETER,
} from "redux/actions/perimeterActions"

const defaultState = {
  loading: true,
  selectedPerimeter: null,
  perimeterGeometry: {},
  perimeters: [],
  filteredBuildingsByPerimeter: [],
}

export default function perimeterReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING_PERIMETERS:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_PERIMETERS:
      return {
        ...state,
        perimeters: action.payload.perimeters,
        loading: false,
      }
    case SET_SELECTED_PERIMETER:
      return {
        ...state,
        selectedPerimeter: action.payload ? { ...action.payload } : null,
      }
    case SET_PERIMETER_GEOMETRY:
      return {
        ...state,
        perimeterGeometry: action.payload.perimeterGeometry,
      }
    case RESET_PERIMETER_GEOMETRY:
      return {
        ...state,
        perimeterGeometry: {},
      }

    case ADD_NEW_PERIMETER:
      return {
        ...state,
        perimeters: [...state.perimeters, action.payload],
      }

    default:
      return state
  }
}
