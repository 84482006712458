import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"
import PropTypes from "prop-types"

const propTypes = {
  children: PropTypes.any,
}

const LayoutSimple = ({ children }) => {
  const { boot, update, shutdown } = useIntercom()

  const setItercomSettings = () => {
    window.intercomSettings = {
      alignment: "left",
      horizontal_padding: 20,
    }
  }

  const updateItercomPosition = () => {
    setItercomSettings()
    update()
  }

  useEffect(() => {
    let timeout
    timeout = setTimeout(() => {
      boot()
      updateItercomPosition()
    }, 1000)
    return () => {
      timeout && clearTimeout(timeout)
      shutdown()
    }
  }, [])

  return <div>{children}</div>
}

LayoutSimple.propTypes = propTypes

export default LayoutSimple
