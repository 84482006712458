import { SET_SCENARIO_BUILDINGS } from "redux/actions"
import {
  SET_PROJECT,
  SET_SCENARIO,
  SET_SCENARIOS,
  SET_LOADING_SCENARIO,
  SET_LOADING_SCENARIOS,
  SET_NETWORKS_AND_BUILDINGS,
  SET_LOADING_NETWORKS_AND_BUILDINGS,
  ADD_SCENARIOS,
  SET_CARDS_TAB_SCENARIOS,
  SET_ITERATION_ID,
  DISCARD_SCENARIO,
  SET_PROJECT_ERROR,
  getNewScenariosAfterDiscard,
} from "redux/actions/scenariosActions"

const defaultState = {
  project: null,
  scenarios: [],
  cardsTabScenarios: [],
  projectIdToLoadScenarios: "",
  scenario: null,
  loadingScenario: true,
  networks: [],
  buildings: [],
  loadingNetworksAndBuildings: true,
  networksAndBuildingsError: false,
  projectError: null,
}

export default function scenariosReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PROJECT:
      return {
        ...state,
        project: action.payload.project,
        projectIdToLoadScenarios: action.payload.projectIdToLoadScenarios || "",
        projectError: action.payload.projectError,
      }
    case SET_SCENARIOS:
      return {
        ...state,
        scenarios: action.payload.scenarios,
        projectIdToLoadScenarios: action.payload.projectIdToLoadScenarios || "",
      }
    case SET_CARDS_TAB_SCENARIOS:
      return {
        ...state,
        cardsTabScenarios: action.payload.cardsTabScenarios,
      }
    case ADD_SCENARIOS:
      return {
        ...state,
        scenarios: [...state.scenarios, ...action.payload.scenarios],
        projectIdToLoadScenarios: action.payload.projectIdToLoadScenarios || "",
      }
    case SET_LOADING_SCENARIOS:
      return {
        ...state,
        projectIdToLoadScenarios: action.payload.projectIdToLoadScenarios
          ? action.payload.projectIdToLoadScenarios
          : "",
      }
    case SET_NETWORKS_AND_BUILDINGS:
      return {
        ...state,
        buildings: action.payload.buildings,
        networks: action.payload.networks,
        loadingNetworksAndBuildings: false,
        networksAndBuildingsError: action.payload.networksAndBuildingsError,
      }
    case SET_SCENARIO:
      return {
        ...state,
        scenario: action.payload,
        loadingScenario: false,
      }
    case SET_LOADING_SCENARIO:
      return {
        ...state,
        loadingScenario: true,
      }
    case SET_LOADING_NETWORKS_AND_BUILDINGS:
      return {
        ...state,
        loadingNetworksAndBuildings: true,
      }
    case SET_ITERATION_ID:
      return {
        ...state,
        project: { ...state.project, current_iteration: action.payload },
      }
    case DISCARD_SCENARIO:
      return {
        ...state,
        ...getNewScenariosAfterDiscard(
          action.payload,
          state.scenarios,
          state.cardsTabScenarios
        ),
      }
    case SET_PROJECT_ERROR:
      return {
        ...state,
        projectError: action.payload,
      }

    case SET_SCENARIO_BUILDINGS:
      return {
        ...state,
        buildings: action.payload,
      }
    default:
      return state
  }
}
