import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  initVisitedProjectsAndScenarios,
  setProjectsPageNotification,
  setMssPageNotification,
} from "redux/actions"

import useTranslation from "hooks/useTranslation"

const useVisitedProjectsAndScenarios = () => {
  const [urlToLatestVisitedProject, setUrlToLatestVisitedProject] = useState("")
  const [urlToLatestVisitedScenario, setUrlToLatestVisitedScenario] = useState("")

  const dispatch = useDispatch()
  const { t: trans } = useTranslation("common")

  const urlToMarkActiveOn_LatestProject = useMemo(
    () =>
      urlToLatestVisitedProject.startsWith("/mss")
        ? urlToLatestVisitedProject
        : "/dummyUrlToAvoidMarkingSidebarOptionActive", // something that will never be matched by the current path
    [urlToLatestVisitedProject]
  )

  const urlToMarkActiveOn_LatestScenario = useMemo(
    () =>
      urlToLatestVisitedScenario.startsWith("/sss")
        ? urlToLatestVisitedScenario
        : "dummyUrlToAvoidMarkingSidebarOptionActive", // something that will never be matched by the current path
    [urlToLatestVisitedScenario]
  )

  const {
    visitedProjects,
    visitedScenarios,
    latestVisitedProjectId,
    latestVisitedScenarioId,
  } = useSelector((state) => state.sidebarReducer)

  useEffect(() => {
    const newUrl = latestVisitedProjectId
      ? `/mss/${latestVisitedProjectId}`
      : "/projects"
    setUrlToLatestVisitedProject(newUrl)
  }, [latestVisitedProjectId])

  useEffect(() => {
    const getUrlForLatestVisitedScenario = () => {
      if (latestVisitedProjectId && !latestVisitedScenarioId) {
        return `/mss/${latestVisitedProjectId}`
      } else if (!latestVisitedProjectId && !latestVisitedScenarioId) {
        return `/projects`
      } else return `/sss/${latestVisitedScenarioId}`
    }

    const newUrl = getUrlForLatestVisitedScenario()
    setUrlToLatestVisitedScenario(newUrl)
  }, [latestVisitedProjectId, latestVisitedScenarioId])

  useEffect(() => {
    dispatch(initVisitedProjectsAndScenarios())
  }, [])

  const handleInvalidProjectUrl = () => {
    if (!latestVisitedProjectId)
      dispatch(setProjectsPageNotification("Please select a project to display"))
  }
  const handleInvalidScenarioUrl = () => {
    if (latestVisitedProjectId && !latestVisitedScenarioId)
      dispatch(setMssPageNotification(trans("Please select a scenario to display")))
    else if (!latestVisitedProjectId && !latestVisitedScenarioId)
      dispatch(setProjectsPageNotification(trans("Please select a project first")))
  }

  return {
    visitedProjects,
    visitedScenarios,

    urlToLatestVisitedProject,
    urlToLatestVisitedScenario,

    urlToMarkActiveOn_LatestProject,
    urlToMarkActiveOn_LatestScenario,

    handleInvalidProjectUrl,
    handleInvalidScenarioUrl,
  }
}

export default useVisitedProjectsAndScenarios
