export const paddingPropTypes = ["xs", "s", "m", "l", "xl", "xxl", "huge", "none"]

export const paddingClassNames = (p, pT, pR, pB, pL, pH, pV) => ({
  // Padding
  "p-5": p === "xs",
  "p-10": p === "s",
  "p-15": p === "m",
  "p-20": p === "l",
  "p-30": p === "xl",
  "p-40": p === "xxl",
  "p-50": p === "huge",
  // Padding Top
  "pt-5": pT === "xs",
  "pt-10": pT === "s",
  "pt-15": pT === "m",
  "pt-20": pT === "l",
  "pt-30": pT === "xl",
  "pt-40": pT === "xxl",
  "pt-50": pT === "huge",
  "pt-none": pT === "none",
  // Padding Right
  "pr-5": pR === "xs",
  "pr-10": pR === "s",
  "pr-15": pR === "m",
  "pr-20": pR === "l",
  "pr-30": pR === "xl",
  "pr-40": pR === "xxl",
  "pr-50": pR === "huge",
  "pr-none": pR === "none",
  // Padding Bottom
  "pb-5": pB === "xs",
  "pb-10": pB === "s",
  "pb-15": pB === "m",
  "pb-20": pB === "l",
  "pb-30": pB === "xl",
  "pb-40": pB === "xxl",
  "pb-50": pB === "huge",
  "pb-none": pB === "none",
  // Padding Left
  "pl-5": pL === "xs",
  "pl-10": pL === "s",
  "pl-15": pL === "m",
  "pl-20": pL === "l",
  "pl-30": pL === "xl",
  "pl-40": pL === "xxl",
  "pl-50": pL === "huge",
  "pl-none": pL === "none",
  // Padding Horizontal
  "ph-5": pH === "xs",
  "ph-10": pH === "s",
  "ph-15": pH === "m",
  "ph-20": pH === "l",
  "ph-30": pH === "xl",
  "ph-40": pH === "xxl",
  "ph-50": pH === "huge",
  "ph-none": pH === "none",
  // Padding Vertical
  "pv-5": pV === "xs",
  "pv-10": pV === "s",
  "pv-15": pV === "m",
  "pv-20": pV === "l",
  "pv-30": pV === "xl",
  "pv-40": pV === "xxl",
  "pv-50": pV === "huge",
  "pv-none": pV === "none",

  "p-none": p === "none",
})
