export const EXPORT_BUILDINGS = "Export buildings"
export const EXPORT_SYSTEMS = "Export systems"

export const EXPORT_CSV = "CSV"
export const EXPORT_SHP = "SHP"

export const ATTRIBUTES_UNIQUE_IDENTIFIERS = [
  "id",
  "energy_carrier",
  "energy_system",
]

export const FILTER_FORMAT_KEYS = [
  "id",
  "name",
  "label",
  "value",
  "unit",
  "aggregation",
  "data_type",
  "class_name",
  "section",
  "subsection",
  "selectedSystemType",
  "selectedCarrierType",
  "propertyToFilter",
  "position",
  ...ATTRIBUTES_UNIQUE_IDENTIFIERS,
]

// This const purpose is to match the BE filter class names possible values
export const FILTER_CLASS_NAMES = {
  ENERGY_CONVERSION_SYSTEM: "_EnergyConversionSystem",
  ENERGY_CARRIER: "EnergyCarrier",
}

// This const purpose is to match the BE filter data type possible values
export const FILTER_DATA_TYPE = {
  CATEGORICAL: "categorical",
  STRING: "string",
  BOOLEAN: "boolean",
  FLOAT: "float",
  INTEGER: "integer",
  DATE: "date",
  PERIMETER: "perimeter",
}

// This const purpose is to match the BE filter section
export const FILTER_ATTRIBUTES_SECTION = {
  CUSTOM: "custom",
}

export const AGGREGABLE_CUSTOM_DATA_TYPES = ["integer", "float"]
