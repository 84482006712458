const path = require("path")
/* eslint-disable no-undef */
module.exports = {
  i18n: {
    defaultLocale: "en-US",
    locales: ["de-CH", "fr-CH", "en-US"],
    // keySeparator: '>',
    // nsSeparator: '|'
  },
  react: {
    // Solving bug: https://app.clickup.com/t/1pkpfmw
    // https://react.i18next.com/latest/usetranslation-hook#not-using-suspense
    //
    // as long as all the namespaces are defined below, in ns: [...], they will be preloaded, no loading handling
    // is required, check more on: https://github.com/isaachinman/next-i18next/issues/1255#issuecomment-864520380
    useSuspense: false,
  },
  // backend: {
  //   projectId: process.env.LOCIZE_PID,
  //   apiKey: process.env.LOCIZE_KEY,
  //   version: process.env.LOCIZE_VER,
  //   referenceLng: process.env.LOCIZE_REFERENCE_LNG,
  // },
  // use: [ require("i18next-locize-backend/cjs") ],
  ns: ["common", "flatfileImporter"], // the namespaces needs to be listed here, to make sure they got preloaded
  serializeConfig: false, // because of the custom use i18next plugin
  localePath: path.resolve("./locales"),
  localeStructure: "{{lng}}/{{ns}}",
}
