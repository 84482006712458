import { SET_PALETTE, INIT_COLORING } from "redux/actions/colorsActions"

const defaultState = {
  palette: [],
  allColors: [],
  energySystemsColors: [],
  buildingsTypesColors: [],
}

export default function colorsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PALETTE:
      return {
        ...state,
        palette: action.payload,
      }
    case INIT_COLORING:
      return {
        ...state,
        allColors: action.payload.allColors,
        energySystemsColors: action.payload.energySystemsColors,
        buildingsTypesColors: action.payload.buildingsTypesColors,
      }
    default:
      return state
  }
}
