import { throwErrors } from "helpers/errors"
import collectionsService from "services/collectionsService"

export const mapDataForSource = (data, sourceName, sourceId) => {
  return data?.features?.map((feature) => {
    /**
     * Mapbox doesn't support feature.id as string
     * So when a feature with an id of type string is returned,
     * the id is removed from the feature
     */
    feature.properties.featureId = feature.id
    feature.properties.source_id = sourceId
    feature.properties.source_name = sourceName

    return feature
  })
}

const addNewSource = async (
  { collectionId, criteriaId, source, sources },
  defaultError = ""
) => {
  try {
    const data = await collectionsService.getSourceValues(
      collectionId,
      criteriaId,
      source.id
    )

    if (typeof data === "string" || !data?.features?.length) {
      throwErrors(null, defaultError)
      source.isVisible = true

      return sources
    }

    source.isVisible = true

    data.features = mapDataForSource(data, source.name, source.id)

    const newSources = sources.slice(0)

    newSources.push({
      ...source,
      data,
    })

    return newSources
  } catch (e) {
    throwErrors(e)
    return sources
  }
}

export default addNewSource
