import { useEffect, useState } from "react"
import { useIntercom } from "react-use-intercom"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { HelpIcon } from "components/core/SVGs"
import { LANGUAGES } from "config/constants"
import SideBarItem from "./SideBarItem"

const propTypes = {
  menuOpen: PropTypes.bool,
}

const HelpMenuItem = ({ menuOpen }) => {
  const { show, hide, boot, update, shutdown } = useIntercom()
  const { user } = useSelector((state) => state.userReducer)

  const [helpOpened, setHelpOpened] = useState(false)
  const [loading, setLoading] = useState(true)

  const isHelpOpenedStorageKey = `is-help-opened-for-${user?.id}`

  useEffect(() => {
    if (user?.id) {
      const shouldOpenUserHelp =
        localStorage.getItem(isHelpOpenedStorageKey) != "false"

      boot({
        email: user?.email,
        name: `${user?.first_name} ${user?.last_name}`,
        hideDefaultLauncher: !shouldOpenUserHelp,
      })
      setItercomSettings()
      update()
      setLoading(false)

      if (shouldOpenUserHelp) {
        openItercom()
        setHelpOpened(true)
      } else {
        setHelpOpened(false)
      }
    }
    return () => {
      const intercomContainer = document.querySelector("#intercom-container")
      if (intercomContainer) intercomContainer.style.display = "none"
      user?.id && shutdown()
    }
  }, [user?.id])

  const setItercomSettings = () => {
    const lng = LANGUAGES[user?.language] || "en"
    const newSettings = {
      alignment: "left",
      horizontal_padding: menuOpen ? 223 : 73,
    }
    if (window.intercomSettings?.language_override !== lng) {
      newSettings.language_override = lng
    }
    window.intercomSettings = { ...newSettings }
  }

  useEffect(() => {
    if (loading) return
    setItercomSettings()
    update()
  }, [menuOpen])

  useEffect(() => {
    if (loading) return
    setItercomSettings()
    update()
  }, [user?.language])

  const openItercom = () => {
    update({ hideDefaultLauncher: false })
    show()
    localStorage.setItem(isHelpOpenedStorageKey, "true")
    setHelpOpened(true)
  }

  const closeItercom = () => {
    hide()
    update({ hideDefaultLauncher: true })
    localStorage.setItem(isHelpOpenedStorageKey, "false")
    setHelpOpened(false)
  }

  const toggleIntercom = () => {
    return helpOpened ? closeItercom() : openItercom()
  }

  return (
    <SideBarItem Icon={HelpIcon} text="Help" onClick={toggleIntercom} url={null} />
  )
}

HelpMenuItem.propTypes = propTypes

export default HelpMenuItem
