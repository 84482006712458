import {
  LOGIN_USER_PENDING,
  LOGIN_USER_FULFILLED,
  USER_INITIALIZED,
  ONBOARDING_SKIPPED,
  ONBOARDING_SEEN,
  LOGIN_USER_REJECTED,
  SIGNUP_USER_PENDING,
  SIGNUP_USER_FULFILLED,
  SIGNUP_USER_REJECTED,
  UPDATE_PASSWORD_PENDING,
  UPDATE_USER,
  UPDATE_PASSWORD_FULFILLED,
  LOGOUT_USER_FULFILLED,
  UPDATE_PASSWORD_REJECTED,
  SET_USER_ORGANIZATION_TERITORY,
} from "redux/actions/userActions"

const defaultState = {
  user: null,
  token: null,
  userInitialized: false,
  loginPending: false,
  registerPending: false,
  updatePasswordPending: false,
  onboardingSkipped: false,
  onboardingSeen: false,
  userTerritory: null,
}

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case LOGIN_USER_PENDING:
      return {
        ...state,
        loginPending: true,
      }
    case LOGIN_USER_FULFILLED:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        userInitialized: true,
        loginPending: false,
      }
    case USER_INITIALIZED:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        onboardingSkipped: action.payload.onboardingSkipped,
        onboardingSeen: action.payload.onboardingSeen,
      }
    case ONBOARDING_SKIPPED:
      return {
        ...state,
        onboardingSkipped: true,
      }
    case ONBOARDING_SEEN:
      return {
        ...state,
        onboardingSeen: true,
      }
    case LOGIN_USER_REJECTED:
      return {
        ...state,
        loginPending: false,
      }
    case SIGNUP_USER_PENDING:
      return {
        ...state,
        registerPending: true,
      }
    case SIGNUP_USER_FULFILLED:
      return {
        ...state,
        registerPending: false,
      }
    case SIGNUP_USER_REJECTED:
      return {
        ...state,
        registerPending: false,
      }
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      }
    case UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        updatePasswordPending: true,
      }
    case UPDATE_PASSWORD_FULFILLED:
      return {
        ...state,
        user: action.payload,
        updatePasswordPending: false,
      }
    case UPDATE_PASSWORD_REJECTED:
      return {
        ...state,
        updatePasswordPending: false,
      }
    case SET_USER_ORGANIZATION_TERITORY:
      return {
        ...state,
        userTerritory: action.payload,
      }
    case LOGOUT_USER_FULFILLED:
      return defaultState
    default:
      return state
  }
}
