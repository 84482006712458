import {
  UPDATE_VISITED_PROJECTS,
  UPDATE_VISITED_SCENARIOS,
  INIT_VISITED_PROJECTS_AND_SCENARIOS,
  UPDATE_VISITING_NOW_SCENARIO_ID,
  UPDATE_VISITING_NOW_PROJECT_ID,
  UPDATE_LATEST_VISITED_PROJECT_ID,
  UPDATE_LATEST_VISITED_SCENARIO_ID,
  SET_PROJECTS_PAGE_NOTIFICATION,
  SET_MSS_PAGE_NOTIFICATION,
} from "../actions/sidebarActions"

const defaultState = {
  visitedInitialized: false,
  visitedProjects: [],
  visitedScenarios: {},

  visitingNowProjectId: null,
  visitingNowScenarioId: null,

  latestVisitedProjectId: null,
  latestVisitedScenarioId: null,

  projectPageNotification: "", 
  mssPageNotification: "",
}

export default function sidebarReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_VISITED_PROJECTS:
      return {
        ...state,
        visitedProjects: action.payload,
      }
    case UPDATE_VISITED_SCENARIOS:
      return {
        ...state,
        visitedScenarios: action.payload,
      }
    case INIT_VISITED_PROJECTS_AND_SCENARIOS:
      return {
        ...state,
        visitedProjects: action.payload.visitedProjects,
        visitedScenarios: action.payload.visitedScenarios,
        latestVisitedProjectId: action.payload.latestVisitedProjectId,
        latestVisitedScenarioId: action.payload.latestVisitedScenarioId,
        visitedInitialized: true,
      }
    case UPDATE_VISITING_NOW_PROJECT_ID:
      return {
        ...state,
        visitingNowProjectId: action.payload,
      }
    case UPDATE_VISITING_NOW_SCENARIO_ID:
      return {
        ...state,
        visitingNowScenarioId: action.payload,
      }

    case UPDATE_LATEST_VISITED_PROJECT_ID:
      return {
        ...state,
        latestVisitedProjectId: action.payload,
      }
    case UPDATE_LATEST_VISITED_SCENARIO_ID:
      return {
        ...state,
        latestVisitedScenarioId: action.payload,
      }
    case SET_PROJECTS_PAGE_NOTIFICATION:
      return {
        ...state,
        projectPageNotification: action.payload,
      }
    case SET_MSS_PAGE_NOTIFICATION:
      return {
        ...state,
        mssPageNotification: action.payload,
      }

    default:
      return state
  }
}
