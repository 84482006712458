export const MANUAL_UPDATE_SOURCE_ID = "manuallyUpdated"

import useTranslation from "hooks/useTranslation"

export const useSourceCounstants = () => {
  const { t: trans } = useTranslation("common")

  return {
    DEFAULT_SOURCE_NAME: trans("Default system"),
    MANUAL_UPDATE_SOURCE_NAME: trans("Manual Update"),
  }
}
