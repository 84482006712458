export const API_ENDPOINT =
  process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_ENDPOINT
export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again."
export const LANGUAGES = {
  "": "en",
  en: "en",
  de: "de",
  fr: "fr",
  "en-US": "en",
  "de-CH": "de",
  "fr-CH": "fr",
}

export const NUMBER_FORMAT_LANGUAGE_CODES = {
  "": "en-US",
  en: "en-US",
  de: "de-CH",
  fr: "de-CH",
  "en-US": "en-US",
  "de-CH": "de-CH",
  "fr-CH": "de-CH",
}

export const GA_TRACKING_ID = "G-7TPB18G3S6"

export const ENERGY_SYSTEM = "_EnergyConversionSystem"
export const ENERGY_CARRIER = "EnergyCarrier"

export const HOME_PAGE_URL = "/"
export const SVS_PAGE_URL = "/svs/[versionId]"
export const SSS_PAGE_URL = "/sss/[scenarioId]"
