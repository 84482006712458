export const TEMPLATE_ACTIONS_BUTTONS_STYLE = {
  border: "none",
  fontSize: "14px",
  fontWeight: "600",
  height: "20px",
  padding: "0px 14px",
  minWidth: "0",
}

export const SUMMARY_TYPE = "summary"

export const AUTOSAVE_LOCAL_STORAGE_KEY = `urb-autosave-checked`

export const ACTION_SAVE = "ACTION_SAVE"
export const ACTION_AUTOSAVE = "ACTION_AUTOSAVE"
export const ACTION_SAVE_NEW = "ACTION_SAVE_NEW"
