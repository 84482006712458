export const SET_COLLECTIONS = "SET_COLLECTIONS"
export const SET_LOADING_COLLECTIONS = "SET_LOADING_COLLECTIONS"

export const SET_LOADING_COLLECTION_VERSIONS = "SET_LOADING_COLLECTION_VERSIONS"
export const SET_COLLECTION_VERSIONS = "SET_COLLECTION_VERSIONS"

export const SET_CURRENT_COLLECTION_ID = "SET_CURRENT_COLLECTION_ID"

export const SET_COLLECTION = "SET_COLLECTION"
export const SET_LOADING_COLLECTION = "SET_LOADING_COLLECTION"

export const REMOVE_COLLECTION = "REMOVE_COLLECTION"

export const UPDATE_COLLECTION_NAME = "UPDATE_COLLECTION_NAME"

import collectionsService from "services/collectionsService"

export const getCollections = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_COLLECTIONS,
    })

    try {
      const collections = await collectionsService.getCollections()
      dispatch({
        type: SET_COLLECTIONS,
        payload: { collections },
      })
    } catch (e) {
      dispatch({
        type: SET_COLLECTIONS,
        payload: { collections: [] },
      })
    }
  }
}

export const getCollection = (collectionId) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_COLLECTION,
    })

    try {
      const collection = await collectionsService.getCollection(collectionId)

      dispatch({
        type: SET_COLLECTION,
        payload: collection,
      })
    } catch (e) {
      dispatch({
        type: SET_COLLECTION,
        payload: null,
      })
    }
  }
}

export const fetchCollectionVersions = (collectionId) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_COLLECTION_VERSIONS,
    })

    dispatch({
      type: SET_CURRENT_COLLECTION_ID,
      payload: { collectionId },
    })

    try {
      const collectionVersions = await collectionsService.getCollectionVersions(
        collectionId
      )
      dispatch({
        type: SET_COLLECTION_VERSIONS,
        payload: { collectionVersions },
      })
    } catch (e) {
      dispatch({
        type: SET_COLLECTION_VERSIONS,
        payload: { collectionVersions: [] },
      })
    }
  }
}

export const discardYourCollection = (collection) => {
  return async (dispatch) => {
    try {
      collection.discarded = true
      await collectionsService.discardYourCollection(collection)
      dispatch({
        type: REMOVE_COLLECTION,
        payload: collection,
      })
    } catch (e) {
      throw new Error("Cannot delete collection")
    }
  }
}

export const discardACollectionYouDontOwn = (collection) => {
  return async (dispatch) => {
    try {
      await collectionsService.discardACollectionYouDontOwn(collection)
      dispatch({
        type: REMOVE_COLLECTION,
        payload: collection,
      })
    } catch (e) {
      throw new Error("Cannot delete collection")
    }
  }
}

export const updateCollectionNameInRedux = (collectionId, newName) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COLLECTION_NAME,
      payload: { id: collectionId, name: newName },
    })
  }
}
