import { createStore, applyMiddleware } from "redux"
// import logger from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import { createWrapper } from "next-redux-wrapper"
import { composeWithDevTools } from "redux-devtools-extension"

import reducers from "./reducers"

const makeStore = () =>
  createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(
        thunk,
        promise
        // logger
      )
    )
  )

export const storeWrapper = createWrapper(makeStore)
