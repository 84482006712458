import classNames from "classnames"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import Link from "next/link"
import Image from "next/image"
import useTranslation from "hooks/useTranslation"

import useVisitedProjectsAndScenarios from "./sideBar/useVisitedProjectsAndScenarios"
import {
  HomeIcon,
  ProjectsIcon,
  ChartLineIcon,
  ScenarioIcon,
  // RingIcon,
  // ComparerIcon,
  // ReporterIcon,
  CriteriaIcon,
  // DatasetsIcon,
  // ProfileIcon,
  CollectionIcon,
  SettingsIcon,
  // ProfileIcon,
  // SearchIcon,
} from "components/core/SVGs.js"
// import SearchField from "components/core/Forms/fields/SearchField"
import Logo from "assets/urbio-logo.svg"
import LogoWithoutName from "assets/urbio_logo_without_name.svg"
import ProjectsSubMenu from "./sideBar/ProjectsSubMenu"
import SideBarItem from "./sideBar/SideBarItem"
import HelpMenuItem from "./sideBar/HelpMenuItem"
// Modules used for sidebar
import styled from "./sideBar.module.scss"

const sideBarLinkItemPropTypes = {
  /** The url of the route */
  url: PropTypes.string,
  urlToMarkActiveOn: PropTypes.string,
  children: PropTypes.node,
}

const routesAreEqual = (route1, route2) =>
  route1 === route2 ||
  route1 === `${route2}?editModeEnabled=true` ||
  `${route1}?editModeEnabled=true` === route2

const SideBarLinkItem = ({
  url = "/",
  children,
  urlToMarkActiveOn,
  ...sideBarItemProps
}) => {
  const router = useRouter()
  const urlForActive = urlToMarkActiveOn || url

  return (
    <>
      <Link href={url}>
        <SideBarItem
          {...sideBarItemProps}
          active={routesAreEqual(router.asPath, urlForActive)}
        />
      </Link>
      {children}
    </>
  )
}

SideBarLinkItem.propTypes = sideBarLinkItemPropTypes

const sideBarPropTypes = {
  /** Flag that defines if menu is open */
  menuOpen: PropTypes.bool,
}

const SideBar = ({ menuOpen }) => {
  const {
    visitedProjects,
    visitedScenarios,
    urlToLatestVisitedProject,
    urlToLatestVisitedScenario,

    urlToMarkActiveOn_LatestProject,
    urlToMarkActiveOn_LatestScenario,

    handleInvalidProjectUrl,
    handleInvalidScenarioUrl,
  } = useVisitedProjectsAndScenarios()
  const { trans } = useTranslation("common")

  return (
    <div
      className={classNames(styled.sideMenuContainer, {
        [styled.sideMenuContainerClosed]: !menuOpen,
      })}
    >
      <nav
        className={classNames(styled.sideMenu, {
          [styled.sideMenuClosed]: !menuOpen,
        })}
      >
        <div className={styled.topMenuSection}>
          <div className={styled.sideMenuLogoWp}>
            <Link href="/">
              <a>
                <Image
                  className={classNames("pointer", { [styled.logo]: menuOpen })}
                  src={menuOpen ? Logo : LogoWithoutName}
                  alt="Urbio Logo"
                />
              </a>
            </Link>
          </div>
          {/* {menuOpen ? (
            <div className={styled.searchWp}>
              <SearchField />
            </div>
          ) : (
            <div className={styled.sideMenuItem}>
              <SearchIcon />
            </div>
          )} */}
          <SideBarLinkItem Icon={HomeIcon} text={trans("Home")} url="/" />
          {/* <SideBarLinkItem
            Icon={RingIcon}
            text="Notifications"
            url="/notifications"
            notifications="10"
          /> */}
          <SideBarLinkItem
            Icon={ProjectsIcon}
            text={trans("Projects")}
            url="/projects"
          >
            {menuOpen && visitedProjects ? (
              <ProjectsSubMenu
                projects={visitedProjects ? visitedProjects : []}
                allProjectsScenarios={visitedScenarios}
              />
            ) : null}
          </SideBarLinkItem>
          {!menuOpen ? (
            <>
              <SideBarLinkItem
                Icon={ChartLineIcon}
                url={urlToLatestVisitedProject}
                urlToMarkActiveOn={urlToMarkActiveOn_LatestProject}
                onClick={handleInvalidProjectUrl}
              />
              <SideBarLinkItem
                Icon={ScenarioIcon}
                url={urlToLatestVisitedScenario}
                urlToMarkActiveOn={urlToMarkActiveOn_LatestScenario}
                onClick={handleInvalidScenarioUrl}
              />
            </>
          ) : null}

          {/* <SideBarLinkItem
            Icon={ComparerIcon}
            text="Comparer"
            url="/comparer"
            notifications="2"
          /> */}
          {/* <SideBarLinkItem Icon={ReporterIcon} text="Reporter" url="/reporter" /> */}
        </div>

        <div>
          <SideBarLinkItem
            Icon={CriteriaIcon}
            text={trans("Criteria")}
            url="/criterias"
          />
          <SideBarLinkItem
            Icon={CollectionIcon}
            text={trans("Collection")}
            url="/collections"
          />
          {/* <SideBarLinkItem Icon={DatasetsIcon} text="Datasets" url="/datasets" /> */}
          <SideBarLinkItem
            Icon={SettingsIcon}
            text={trans("Settings")}
            url="/settings"
          />
          {/* <SideBarLinkItem Icon={ProfileIcon} text="Profile" url="/profile" /> */}
          <HelpMenuItem menuOpen={menuOpen} />
        </div>
      </nav>
    </div>
  )
}

SideBar.propTypes = sideBarPropTypes

export default SideBar
