import {
  SET_SUMMARY_STATE,
  SET_SUMMARY_TEMPLATES,
  SET_SELECTED_SUMMARY_CRITERIAS,
  SET_TEMPLATE,
  SAVE_TEMPLATE,
} from "../actions/summaryActions"

const defaultState = {
  summaryTemplates: [],
  selectedSummaryCriterias: [],
  selectedTemplateId: null,
}

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SUMMARY_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SUMMARY_TEMPLATES:
      return {
        ...state,
        summaryTemplates: action.payload,
      }
    case SET_TEMPLATE:
      return {
        ...state,
        selectedTemplateId: action.payload,
      }
    case SAVE_TEMPLATE:
      return {
        ...state,
        selectedTemplateId: action.payload.id,
        summaryTemplates: [...state.summaryTemplates, action.payload],
      }
    case SET_SELECTED_SUMMARY_CRITERIAS:
      return {
        ...state,
        selectedSummaryCriterias: action.payload,
      }
    default:
      return state
  }
}
