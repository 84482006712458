import "react-toastify/dist/ReactToastify.css"
import "styles/main.scss"

import * as ga from "helpers/googleAnalytics"

import { LayoutSimple, LayoutWithSideBar } from "components/core/Layout"
import { useDispatch, useSelector } from "react-redux"

import Head from "next/head"
import { IntercomProvider } from "react-use-intercom"
import Onboarding from "components/Onboarding/"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import { appWithTranslation } from "next-i18next"
import { getCookie } from "helpers/cookie"
import nextI18NextConfig from "next-i18next.config.js"
import { reinitUser } from "redux/actions/"
import { storeWrapper } from "redux/store"
import { useEffect } from "react"
import { useRouter } from "next/router"

const propTypes = {
  /** App is the component that wraps all other components */
  Component: PropTypes.elementType,
  /** Props that will be passed to Component */
  pageProps: PropTypes.object,
}

/**
 * Show layout with sidebar only when user has a token and is not on one of the below routes
 */
const showLayoutWithSidebar = (token, route) => {
  if (["/login", "/signup", "/forgot-password"].includes(route)) {
    return false
  }

  return !!token
}

function App({ Component, pageProps }) {
  const dispatch = useDispatch()
  const router = useRouter()

  const { token } = useSelector((state) => {
    return state.userReducer
  })

  const showOnboarding = false

  useEffect(() => {
    const sessionToken = token || getCookie("token")
    if (sessionToken) dispatch(reinitUser(router.locale))
  }, [token])

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  const Layout = showLayoutWithSidebar(token, router.route)
    ? LayoutWithSideBar
    : LayoutSimple

  return (
    <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
      <Head>
        <title>Urbio</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Layout>
        <Component {...pageProps} />
        <ToastContainer
          hideProgressBar
          position="bottom-center"
          closeOnClick={false}
          draggable={false}
        />
      </Layout>
      {showLayoutWithSidebar(token, router.route) && showOnboarding && (
        <Onboarding />
      )}
    </IntercomProvider>
  )
}

App.propTypes = propTypes

export default storeWrapper.withRedux(appWithTranslation(App, nextI18NextConfig))
