import chartService from "services/chartService"
import {
  getAutosavedState,
  getNewSummaryTemplates,
  getSelectedTemplateFormattedAttributtes,
  getTemplateAxesData,
} from "./helpers"

export const SET_SUMMARY_STATE = "SET_SUMMARY_STATE"
export const SET_SUMMARY_TEMPLATES = "SET_SUMMARY_TEMPLATES"
export const SET_TEMPLATE = "SET_TEMPLATE"
export const SAVE_TEMPLATE = "SAVE_TEMPLATE"
export const SET_SELECTED_SUMMARY_CRITERIAS = "SET_SELECTED_SUMMARY_CRITERIAS"

export const initSummaryTemplates = () => {
  return async (dispatch) => {
    const selectedTemplateId = localStorage.getItem("urbioSelectedTemplate")
    const selectedSummaryCriterias =
      JSON.parse(localStorage.getItem("urbioSelectedSummaryCriterias")) || []
    const templates = await chartService.getChartList("summary")
    return dispatch({
      type: SET_SUMMARY_STATE,
      payload: {
        summaryTemplates: templates,
        selectedTemplateId,
        selectedSummaryCriterias,
      },
    })
  }
}

export const selectTemplate = (templateId) => {
  return async (dispatch, selector) => {
    dispatch(setselectedTemplateId(templateId))
    const { buildingReducer, summaryReducer } = selector()
    const { summaryTemplates } = summaryReducer
    const { filteredAttributes, totalBuildingsNrAttribute } = buildingReducer

    const templateAxesData = getTemplateAxesData(summaryTemplates, templateId)
    const selectedTemplateFormattedAttributtes =
      getSelectedTemplateFormattedAttributtes(
        filteredAttributes,
        templateAxesData,
        totalBuildingsNrAttribute
      )

    dispatch(setSelectedSummaryCriterias(selectedTemplateFormattedAttributtes))
  }
}

export const setselectedTemplateId = (templateId) => {
  localStorage.setItem("urbioSelectedTemplate", templateId.toString())
  return {
    type: SET_TEMPLATE,
    payload: templateId,
  }
}

export const saveNewTemplate = (newTemplate) => {
  localStorage.setItem("urbioSelectedTemplate", newTemplate.id.toString())
  return {
    type: SAVE_TEMPLATE,
    payload: newTemplate,
  }
}

export const updateTemplate = (templateId, templateName) => {
  return async (dispatch, selector) => {
    const updatedTemplate = await chartService.updateChartDetail(templateId, {
      name: templateName,
    })
    const { summaryReducer } = selector()
    const newTemplates = summaryReducer.summaryTemplates.map((template) =>
      template.id == templateId ? updatedTemplate : template
    )
    return dispatch(setSummaryTemplates(newTemplates))
  }
}

export const deleteTemplate = (templateId) => {
  return async (dispatch, selector) => {
    const { summaryReducer } = selector()
    if (templateId == summaryReducer.selectedTemplateId) {
      dispatch(setselectedTemplateId(""))
    }
    await chartService.deleteChart(templateId)
    const newTemplates = summaryReducer.summaryTemplates.filter(
      (template) => template.id !== templateId
    )

    return dispatch(setSummaryTemplates(newTemplates))
  }
}

export const setSummaryTemplates = (newTemplates) => ({
  type: SET_SUMMARY_TEMPLATES,
  payload: newTemplates,
})

export const setSelectedSummaryCriterias = (criterias) => {
  return async (dispatch, selector) => {
    const newCriterias = criterias.filter((criterion) => !!criterion)
    localStorage.setItem(
      "urbioSelectedSummaryCriterias",
      JSON.stringify(newCriterias)
    )

    dispatch({
      type: SET_SELECTED_SUMMARY_CRITERIAS,
      payload: newCriterias,
    })
    // update template automatically when selectedSummaryCriterias are updated if there is a selected template
    const { summaryReducer } = selector()
    const autosaveChecked = getAutosavedState()
    if (!summaryReducer.selectedTemplateId || !autosaveChecked) return
    const newTemplates = await getNewSummaryTemplates(
      summaryReducer.summaryTemplates,
      summaryReducer.selectedTemplateId,
      newCriterias
    )
    if (!newTemplates) return
    dispatch({
      type: SET_SUMMARY_TEMPLATES,
      payload: newTemplates,
    })
  }
}
