import colorPaletteService from "services/colorPaletteService"

export const SET_PALETTE = "SET_PALETTE"
export const INIT_COLORING = "INIT_COLORS"

const getColorCodeDictionary = (rawDictionary, allColorsDictionary) => {
  const finalDictionary = {}
  rawDictionary.forEach((system) => {
    finalDictionary[system.label] = allColorsDictionary[system.color_name]
  })
  return finalDictionary
}

const getAllColorsDictionary = (rawAllColors) => {
  const finalDictionary = {}
  rawAllColors.forEach((color) => {
    finalDictionary[color.name] = color.code_hex
  })
  return finalDictionary
}

export const initColorPalette = () => async (dispatch) => {
  const palette = await colorPaletteService.getPalette()

  dispatch({
    type: SET_PALETTE,
    payload: palette,
  })
}

export const initColoring = () => async (dispatch) => {
  dispatch(initColorPalette())

  Promise.all([
    colorPaletteService.getAllColors(),
    colorPaletteService.getAllEnergySystems(),
    colorPaletteService.getAllBuildingTypes(),
  ]).then(([allColors, allEnergySystems, allEnergyTypes]) => {
    const colorsNameDictionary = getAllColorsDictionary(allColors)
    const energySystemsColors = getColorCodeDictionary(
      allEnergySystems,
      colorsNameDictionary
    )
    const buildingsTypesColors = getColorCodeDictionary(
      allEnergyTypes,
      colorsNameDictionary
    )
    dispatch({
      type: INIT_COLORING,
      payload: {
        allColors,
        energySystemsColors,
        buildingsTypesColors,
      },
    })
  })
}
