export const INIT_VISITED_PROJECTS_AND_SCENARIOS =
  "INIT_VISITED_PROJECTS_AND_SCENARIOS"

// projects are stored as: [{ id: projectId, name: projectName}, { id: projectId, name: projectName}, { id: projectId, name: projectName}]
export const UPDATE_VISITED_PROJECTS = "UPDATE_VISITED_PROJECTS"

// scenarios are stored as:
// {
//   projectId: [{ id: scenarioName, name: scenarioName }, { id: scenarioName, name: scenarioName } }]
//   projectId: [{ id: scenarioName, name: scenarioName }, { id: scenarioName, name: scenarioName } }]
// }
//
export const UPDATE_VISITED_SCENARIOS = "UPDATE_VISITED_SCENARIOS"
export const UPDATE_VISITING_NOW_SCENARIO_ID = "UPDATE_VISITING_NOW_SCENARIO_ID"
export const UPDATE_VISITING_NOW_PROJECT_ID = "UPDATE_VISITING_NOW_PROJECT_ID"
export const UPDATE_LATEST_VISITED_PROJECT_ID = "UPDATE_LATEST_VISITED_PROJECT_ID"
export const UPDATE_LATEST_VISITED_SCENARIO_ID = "UPDATE_LATEST_VISITED_SCENARIO_ID"
export const SET_PROJECTS_PAGE_NOTIFICATION = "SET_PROJECTS_PAGE_NOTIFICATION"
export const SET_MSS_PAGE_NOTIFICATION = "SET_MSS_PAGE_NOTIFICATION"

const isIdInArray = (array, id) =>
  positionOfIdInArray(array, id) > -1 ? true : false

const positionOfIdInArray = (array, id) => {
  for (let i = 0; i < array.length; i++) {
    const object = array[i]
    if (object.id == id) return i
  }

  return -1
}

const getVisitedProjects = () =>
  JSON.parse(localStorage.getItem("SidebarVisitedProjectsHistory")) || []

const setVisitedProjects = (visitedProjects) =>
  localStorage.setItem(
    `SidebarVisitedProjectsHistory`,
    JSON.stringify(visitedProjects)
  )

const getVisitedScenarios = () =>
  JSON.parse(localStorage.getItem("SidebarVisitedScenariosHistory")) || {}

const setVisitedScenarios = (visitedScenarios) =>
  localStorage.setItem(
    `SidebarVisitedScenariosHistory`,
    JSON.stringify(visitedScenarios)
  )

const getLatestVisitedProjectId = () =>
  JSON.parse(localStorage.getItem("latestVisitedProjectId")) || null
const getLatestVisitedScenarioId = () =>
  JSON.parse(localStorage.getItem("latestVisitedScenarioId")) || null

export const addNewVisitedProjectIfNew = (projectId, projectName) => {
  let visitedProjects = getVisitedProjects()

  const projectToAdd = { id: projectId, name: projectName }
  if (!isIdInArray(visitedProjects, projectToAdd.id)) {
    visitedProjects.push(projectToAdd)

    setVisitedProjects(visitedProjects)

    return {
      type: UPDATE_VISITED_PROJECTS,
      payload: visitedProjects,
    }
  }
  return {
    type: UPDATE_VISITED_PROJECTS,
    payload: visitedProjects,
  }
}

export const addVisitedScenarioIfNew = (projectId, scenario, projectName) => {
  const visitedScenarios = getVisitedScenarios()

  const projectScenarios = visitedScenarios[projectId]
  if (projectScenarios) {
    if (!isIdInArray(projectScenarios, scenario?.id))
      visitedScenarios[projectId]?.push(scenario)
  } else {
    visitedScenarios[projectId] = [scenario]
  }

  setVisitedScenarios(visitedScenarios)

  return (dispatch) => {
    dispatch(addNewVisitedProjectIfNew(projectId, projectName))
    dispatch({
      type: UPDATE_VISITED_SCENARIOS,
      payload: visitedScenarios,
    })
  }
}

export const initVisitedProjectsAndScenarios = () => {
  return {
    type: INIT_VISITED_PROJECTS_AND_SCENARIOS,
    payload: {
      visitedProjects: getVisitedProjects(),
      visitedScenarios: getVisitedScenarios(),
      latestVisitedProjectId: getLatestVisitedProjectId(),
      latestVisitedScenarioId: getLatestVisitedScenarioId(),
    },
  }
}

export const removeVisitedProject = (projectId) => {
  let visitedProjects = getVisitedProjects()

  visitedProjects = visitedProjects.filter((project) => project.id !== projectId)

  setVisitedProjects(visitedProjects)

  return {
    type: UPDATE_VISITED_PROJECTS,
    payload: visitedProjects,
  }
}

export const removeVisitedScenario = (projectId, scenarioId) => {
  let visitedScenarios = getVisitedScenarios()

  let projectScenarios = visitedScenarios[projectId]
  if (projectScenarios) {
    visitedScenarios[projectId] = projectScenarios.filter(
      (scenario) => scenario?.id !== scenarioId
    )
  }

  setVisitedScenarios(visitedScenarios)

  return {
    type: UPDATE_VISITED_SCENARIOS,
    payload: visitedScenarios,
  }
}

export const updateVisitedProjectName = (projectId, projectName) => {
  let visitedProjects = getVisitedProjects()

  const position = positionOfIdInArray(visitedProjects, projectId)

  if (position >= 0 && visitedProjects[position]?.name)
    visitedProjects[position].name = projectName

  setVisitedProjects(visitedProjects)

  return {
    type: UPDATE_VISITED_PROJECTS,
    payload: visitedProjects,
  }
}

export const updateVisitedScenarioName = (scenarioId, scenarioName) => {
  let visitedScenarios = getVisitedScenarios()

  for (const projectId in visitedScenarios) {
    const projectScenarios = visitedScenarios[projectId]
    const position = positionOfIdInArray(projectScenarios, scenarioId)
    if (position >= 0 && visitedScenarios[projectId][position]?.name)
      visitedScenarios[projectId][position].name = scenarioName
  }

  setVisitedScenarios(visitedScenarios)

  return {
    type: UPDATE_VISITED_SCENARIOS,
    payload: visitedScenarios,
  }
}

export const updateVisitingNowProjectId = (projectId) => {
  return {
    type: UPDATE_VISITING_NOW_PROJECT_ID,
    payload: projectId,
  }
}

export const updateVisitingNowScenarioId = (scenarioId) => {
  if (scenarioId) updateLatestVisitedScenarioId(scenarioId)

  return {
    type: UPDATE_VISITING_NOW_SCENARIO_ID,
    payload: scenarioId,
  }
}

export const updateLatestVisitedProjectId = (projectId) => {
  localStorage.setItem(`latestVisitedProjectId`, JSON.stringify(projectId))

  return {
    type: UPDATE_LATEST_VISITED_PROJECT_ID,
    payload: projectId,
  }
}

export const updateLatestVisitedScenarioId = (scenarioId) => {
  localStorage.setItem(`latestVisitedScenarioId`, JSON.stringify(scenarioId))

  return {
    type: UPDATE_LATEST_VISITED_SCENARIO_ID,
    payload: scenarioId,
  }
}

export const setProjectsPageNotification = (message) => {
  return {
    type: SET_PROJECTS_PAGE_NOTIFICATION,
    payload: message,
  }
}

export const setMssPageNotification = (message) => {
  return {
    type: SET_MSS_PAGE_NOTIFICATION,
    payload: message,
  }
}
