export const fontSizePropTypes = ["xs", "s", "m", "l", "xl"]

export const fontSizeClassNames = (value) => ({
  "f-12": value === "xs",
  "f-13": value === "s",
  "f-16": value === "m",
  "f-19": value === "l",
  "f-23": value === "xl",
})

export const alignPropTypes = ["left", "right", "center"]

export const alignClassNames = (value) => ({
  "text-left": value === "left",
  "text-right": value === "right",
  "text-center": value === "center",
})

export const fontWeightPropTypes = [
  "normal",
  "medium",
  "semiBold",
  "bold",
  "extraBold",
]

export const fontWeightClassNames = (value) => ({
  "fw-400": value === "normal",
  "fw-500": value === "medium",
  "fw-600": value === "semiBold",
  "fw-700": value === "bold",
  "fw-900": value === "extraBold",
})

export const wordBreakPropTypes = ["normal", "breakAll", "none", "breakWord"]
export const wordBreakClassNames = (value) => ({
  "word-break-normal": value === "normal",
  "word-break-all": value === "breakAll",
  "word-break-none": value === "none",
  "word-break-word": value === "breakWord",
})
