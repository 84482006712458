import { combineReducers } from "redux"

import appReducer from "./appReducer"
import userReducer from "./userReducer"
import buildingReducer from "./buildingReducer"
import collectionReducer from "./collectionReducer"
import projectReducer from "./projectReducer"
import svsReducer from "./svsReducer"
import homepageReducer from "./homepageReducer"
import scenariosReducer from "./scenariosReducer"
import criteriaReducer from "./criteriaReducer"
import mssReducer from "./mssReducer"
import sssReducer from "./sssReducer"
import colorsReducer from "./colorsReducer"
import sidebarReducer from "./sidebarReducer"
import perimeterReducer from "./perimeterReducer"
import createCollectionPageReducer from "./createCollectionPageReducer"
import summaryReducer from "./summaryReducer"

export default combineReducers({
  appReducer,
  userReducer,
  collectionReducer,
  projectReducer,
  svsReducer,
  homepageReducer,
  scenariosReducer,
  buildingReducer,
  sssReducer,
  criteriaReducer,
  mssReducer,
  colorsReducer,
  sidebarReducer,
  perimeterReducer,
  createCollectionPageReducer,
  summaryReducer,
})
