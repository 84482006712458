export const SET_CRITERIAS = "SET_CRITERIAS"
export const SET_LOADING_CRITERIAS = "SET_LOADING_CRITERIAS"
export const DISCARD_CRITERIA = "DISCARD_CRITERIA"

import criteriaService from "services/criteriaService"

export const getCriterias = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_CRITERIAS, loadingCriterias: true })
    const criterias = await criteriaService.getCriteriaList(query)

    dispatch({
      type: SET_CRITERIAS,
      payload: { criterias, loadingCriterias: false },
    })
  }
}

export const discardCriteria = (criteriaId) => {
  return async (dispatch) => {
    try {
      await criteriaService.discardCriteria(criteriaId)
      dispatch({
        type: DISCARD_CRITERIA,
        payload: criteriaId,
      })
    } catch (e) {
      throw new Error(e)
    }
  }
}
