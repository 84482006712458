import requestService from "../requestService"

class BuildingsService {
  async getHomeBuildings(queryParams) {
    const data = await requestService.get(`/b?${queryParams}`)

    return data
  }

  async exportBuildings(url, params = null) {
    const responseType = "blob"
    const data = params
      ? await requestService.post(url, null, params, { responseType })
      : await requestService.get(url, null, { responseType })
    return data
  }

  async getAttributes(
    class_name,
    offset,
    extraQuerryParams = "",
    initQueryParam = "class_name"
  ) {
    // we add "&" to extraQuerryParams that doesn't start with "&"
    // in order to get a proper formatted qerry params for the request
    if (extraQuerryParams && extraQuerryParams[0] !== "&")
      extraQuerryParams = "&" + extraQuerryParams
    const data = await requestService.get(
      `/cr?${initQueryParam}=${class_name}&offset=${offset}${extraQuerryParams}`
    )

    return data
  }

  async getHomeBuildingAttributeSource(buildingId, criteriaId, criteriaType) {
    const data = await requestService.get(`/b/${buildingId}/cr/${criteriaId}/sr`, {
      type: criteriaType,
    })

    return data
  }

  async getSVSBuildingAttributeSource(
    versionId,
    buildingId,
    criteriaId,
    criteriaType
  ) {
    const data = await requestService.get(
      `/vs/${versionId}/b/${buildingId}/cr/${criteriaId}/sr`,
      {
        type: criteriaType,
      }
    )

    return data
  }

  async getSSSBuildingAttributeSource(
    scenarioId,
    buildingId,
    criteriaId,
    criteriaType
  ) {
    const data = await requestService.get(
      `/sc/${scenarioId}/b/${buildingId}/cr/${criteriaId}/sr`,
      { type: criteriaType }
    )

    return data
  }

  async getEnergySystems() {
    const data = await requestService.get(
      "/ocl?is_parent=false&ancestor_name=_EnergyConversionSystem"
    )

    return data
  }

  async getBuildingsFiles() {
    const data = await requestService.get("/f?discarded=false&class_name=Building")

    return data
  }

  async getHeatingSystems() {
    const boilerTypes = await requestService.get(
      "/ocl?is_parent=false&ancestor_name=_Boiler"
    )

    const heatPumpTypes = await requestService.get(
      "/ocl?is_parent=false&ancestor_name=_HeatPump&name=HeatPumpAmbientAir"
    )

    const electricalResistanceTypes = await requestService.get(
      "/ocl?is_parent=false&ancestor_name=_EnergyConversionSystem&name=ElectricalResistance"
    )

    return [...boilerTypes, ...heatPumpTypes, ...electricalResistanceTypes]
  }

  async getEnergyCarriers() {
    const data = await requestService.get("/obj?class_name=EnergyCarrier")

    return data
  }

  async getEnergyCertificates() {
    const data = await requestService.get("/obj?class_name=Norm")

    return data
  }

  async getBuildingBoundsById(criteriaId) {
    return await requestService.get(`/cr/${criteriaId}/bounds`)
  }

  async updateBuildingCustomCriteria(
    buildingId,
    criteriaId,
    value,
    { scenarioId, versionId }
  ) {
    let url = "/"

    if (scenarioId) url += `sc/${scenarioId}`
    else if (versionId) url += `vs/${versionId}`

    url += `/b/${buildingId}/cr/${criteriaId}`
    return await requestService.patch(url, null, { value })
  }

  async getTotalBuildingsNrAttribute() {
    const data = await requestService.get("/cr?name=num_blgs_total")

    return data[0]
  }
}

export default new BuildingsService()
