import requestService from "./requestService"

class PerimeterService {
  async getPerimeters() {
    const data = await requestService.get("/pm?discarded=false")

    return data
  }

  async getPerimeterGeometry(perimeterId) {
    const data = await requestService.get(`/pm/${perimeterId}`)

    return data
  }

  async createPerimeter(params) {
    const data = await requestService.post("/pm", null, params)

    return data
  }
}

export default new PerimeterService()
