import {
  SET_ENERGY_SYSTEMS,
  SET_HEATING_SYSTEMS,
  SET_BUILDING_ATTRIBUTES,
  SET_LOADING_HEATING_SYSYEMS,
  SET_BUILDING_FILTERED_ATTRIBUTES,
  SET_ENERGY_CARRIERS,
  SET_TOTAL_BUILDINGS_NR_ATTRIBUTE,
  SET_BUILDING_BOUNDS_BY_ID,
  Building,
  EnergyCarrier,
  _EnergyConversionSystem,
} from "../actions/buildingActions"

const defaultState = {
  attributes: {
    [Building]: [],
    [EnergyCarrier]: [],
    [_EnergyConversionSystem]: [],
  },
  filteredAttributes: {
    [Building]: [],
    [EnergyCarrier]: [],
    [_EnergyConversionSystem]: [],
  },
  energySystems: [],
  heatingSystems: [],
  loadingHeatingSystems: true,
  energyCarriers: [],
  totalBuildingsNrAttribute: {},
  buildingAttributesBoundsById: {},
}

export default function buildingReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_BUILDING_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload,
      }
    case SET_BUILDING_FILTERED_ATTRIBUTES:
      return {
        ...state,
        filteredAttributes: action.payload,
      }
    case SET_ENERGY_SYSTEMS:
      return {
        ...state,
        energySystems: action.payload,
      }
    case SET_LOADING_HEATING_SYSYEMS:
      return {
        ...state,
        loadingHeatingSystems: true,
      }
    case SET_HEATING_SYSTEMS:
      return {
        ...state,
        heatingSystems: action.payload,
        loadingHeatingSystems: false,
      }
    case SET_ENERGY_CARRIERS:
      return {
        ...state,
        energyCarriers: action.payload,
      }
    case SET_TOTAL_BUILDINGS_NR_ATTRIBUTE:
      return {
        ...state,
        totalBuildingsNrAttribute: action.payload,
      }
    case SET_BUILDING_BOUNDS_BY_ID:
      return {
        ...state,
        buildingAttributesBoundsById: {
          ...state.buildingAttributesBoundsById,
          [action.payload.id]: action.payload.value,
        },
      }
    default:
      return state
  }
}
