import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./Button.module.scss"

const propTypes = {
  sm: PropTypes.bool,
  outline: PropTypes.bool,
  filled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.PropTypes.node,
  leftIcon: PropTypes.PropTypes.node,
  rightIcon: PropTypes.PropTypes.node,
  loading: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  styles: PropTypes.object,
}

function Button({
  sm,
  outline,
  fullWidth,
  disabled,
  title,
  filled = true,
  children,
  leftIcon,
  rightIcon,
  loading,
  tooltip,
  type = null,
  onClick = () => {},
  className,
  dataTestId,
  ...rest
}) {
  if (outline) filled = false

  return (
    <button
      data-testid={dataTestId}
      onClick={onClick}
      className={classNames(styles.btn, className, {
        [styles.btnSm]: sm,
        [styles.btnFilled]: filled,
        [styles.btnOutline]: outline,
        [styles.btnFullWidth]: fullWidth,
        [styles.btnLoading]: loading,
      })}
      disabled={disabled || loading}
      type={type}
      title={tooltip}
      {...rest}
    >
      {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
      {title || children}
      {rightIcon && <span className={styles.rightIcon}>{rightIcon}</span>}
    </button>
  )
}

Button.propTypes = propTypes

export default Button
