import requestService from "./requestService"

class axisService {
  async getAxis(axisId) {
    const data = await requestService.get(`/ax/${axisId}`)
    return data
  }

  async updateAxe(axisData) {
    /*
    Axis can be updated by: 
    position (type: int) * required, 
    criteria (type:  valid criteria id) * required, 
    display_min  && display_max (type:  float), 
    brush_min && brush_max (type: float), 
    color_by (type: bool)
    optim_sense (oneOf: "min", "max", null),
    visible: (type bool)
    */
    return requestService.put(`/ax/${axisData.id}`, null, axisData)
  }

  async discardAxis(axisId) {
    return await requestService.delete(`/ax/${axisId}`, null)
  }

  async updateMultipleAxes(chartId, changedAxes) {
    /*
  Update mutiple axes at once. Fileds unprovided won't be changed.
  It requires an array of axes.
  Required fields:
  id, position, criteria.
  same properties for each axe as update of single axe.
  example of use: updateAxesOrder
  */
    return await requestService.patch(`/ch/${chartId}/ax`, null, changedAxes)
  }
}
export default new axisService()
