export const overflowPropTypes = [
  "scroll",
  "scrollX",
  "scrollY",
  "scrollHover",
  "scrollHoverX",
  "scrollHoverY",
  "hidden",
  "visible",
]

export const overflowClassNames = (value) => ({
  scroll: value === "scroll",
  "scroll-x": value === "scrollX",
  "scroll-y": value === "scrollY",
  scrollOnHover: value === "scrollHover",
  scrollOnHoverX: value === "scrollHoverX",
  scrollOnHoverY: value === "scrollHoverY",
  overflowHidden: value === "hidden",
  overflowVisible: value === "visible",
})
