import { API_ENDPOINT, LANGUAGES } from "config/constants"
import { getCookie, removeCookie, setCookie } from "helpers/cookie"
import { getUnreachableAPIErrorMessage, requestContainsError } from "helpers/errors"

import axios from "axios"
import qs from "qs"
import { toast } from "react-toastify"
import userService from "services/userService"

class RequestService {
  constructor() {
    this.user = null
  }

  setToken(token) {
    if (token) {
      setCookie("token", token, 10)
    } else {
      removeCookie("token")
    }
  }

  setUser(user) {
    if (user) {
      this.user = user
      localStorage.setItem("user", JSON.stringify(user))
    } else {
      this.user = null
      localStorage.removeItem("user")
    }
  }

  async getUser() {
    if (localStorage) {
      let user = await JSON.parse(localStorage.getItem("user"))
      if (!user) {
        user = await userService.getUser()
      }
      return user
    }
  }

  getUserLanguage() {
    return this.user?.language || "en"
  }

  async reinitUser() {
    this.user = await this.getUser()

    if (this.user.language !== getCookie("NEXT_LOCALE")) {
      setCookie("NEXT_LOCALE", this.user.language)
    }

    return this.user
  }

  getToken(req = null) {
    return getCookie("token", req)
  }

  get(url, params, { responseType, ...opts } = {}) {
    url = this._addParamsToURL(url, params)

    return this._request(url, "GET", "", opts, responseType)
  }

  post(url, params, body = {}, { responseType, ...opts } = {}) {
    url = this._addParamsToURL(url, params)

    return this._request(url, "POST", body, opts, responseType)
  }

  put(url, params, body, { responseType, ...opts } = {}) {
    url = this._addParamsToURL(url, params)

    return this._request(url, "PUT", body, opts, responseType)
  }

  patch(url, params, body, opts) {
    url = this._addParamsToURL(url, params)

    return this._request(url, "PATCH", body, opts)
  }

  delete(url, params, body = {}, opts) {
    url = this._addParamsToURL(url, params)

    return this._request(url, "DELETE", body, opts)
  }

  _addParamsToURL(url, params) {
    if (!params) return url

    return url + "?" + qs.stringify(params, { arrayFormat: "comma" })
  }

  _request(url, method, body, opts, responseType) {
    url = API_ENDPOINT + LANGUAGES[this.getUserLanguage()] + url
    opts = opts || {}

    const options = {
      method: method,
      data: JSON.stringify(body),
      ...opts,
      headers: {
        "Content-Type": "application/json",
        ...opts.headers,
      },
      responseType: responseType,
    }

    if (this.getToken()) {
      options.headers.Authorization = `Token ${this.getToken()}`
    }

    return new Promise((resolve, reject) => {
      axios(url, options)
        .then((response) => {
          if (requestContainsError(response.data)) {
            console.error(`Unkown error: ${url} failed with code 200`)
            throw new Error({})
          }
          resolve(response?.data)
        })
        .catch((err) => {
          if (
            !err?.response ||
            (err?.response?.status > 499 && err?.response?.status < 600)
          ) {
            //catching Server errors
            toast.warn(
              getUnreachableAPIErrorMessage(LANGUAGES[this.user?.language || "en"]),
              {
                toastId: "unreachable-api-toast",
                position: toast.POSITION.TOP_CENTER,
              }
            )
          } else if (axios.isCancel(err)) {
            reject({
              isCancel: true,
            })
            return
          } else if (err?.response?.status === 401) {
            this.setUser(null)
            this.setToken(null)
            window.location.href = "/login"
            return
          }

          reject(err?.response)
        })
    })
  }
}

export default new RequestService()
