import {
  getAllBuildingsWithUpdatedCustomValue,
  getBuildingWithUpdatedCustomValue,
} from "helpers/buildings"
import {
  downloadBuildings,
  getExportTypePath,
  getFileTypeQuery,
} from "services/buildingsService"
import { setScenarioBuildings } from "./scenariosActions"

export const SET_SCENARIO_MAP_SEARCH = "SET_SCENARIO_MAP_SEARCH"
export const SET_SCENARIO_MAP_FILTERS = "SET_SCENARIO_MAP_FILTERS"
export const SET_SCENARIO_DRAW_MODE = "SET_SCENARIO_DRAW_MODE"
export const SET_SCENARIO_SELECTED_BUILDING = "SET_SCENARIO_SELECTED_BUILDING"
export const SET_SCENARIO_ALWAYS_COPY = "SET_SCENARIO_ALWAYS_COPY"
export const SET_SCENARIO_FILTERS_OPEN = "SET_SCENARIO_FILTERS_OPEN"
export const SET_SCENARIO_MODAL_OPEN = "SET_SCENARIO_MODAL_OPEN"
export const SET_SCENARIO_EDIT_MODE = "SET_SCENARIO_EDIT_MODE"
export const SET_SCENARIO_MAP_ATTRIBUTES_LEGENDS =
  "SET_SCENARIO_MAP_ATTRIBUTES_LEGENDS"
export const INIT_SCENARIO_STATE = "INIT_SCENARIO_STATE"
export const SET_SCENARIO_MAP_ZOOM = "SET_SCENARIO_MAP_ZOOM"
export const SET_SCENARIO_ZOOM_LOADING = "SET_SCENARIO_ZOOM_LOADING"

export const setScenarioMapZoom = (zoom, zoomLoading) => ({
  type: SET_SCENARIO_MAP_ZOOM,
  payload: { zoom, zoomLoading },
})

export const setScenarioZoomLoading = (zoomLoading) => ({
  type: SET_SCENARIO_ZOOM_LOADING,
  payload: zoomLoading,
})

export const setScenarioEditMode = (editMode) => ({
  type: SET_SCENARIO_EDIT_MODE,
  payload: editMode,
})

export const setScenarioMapAttributesLegends = (legends, projectId) => {
  localStorage.setItem(
    `project${projectId}ScenarioMapAttributesLegends`,
    JSON.stringify(legends)
  )

  return {
    type: SET_SCENARIO_MAP_ATTRIBUTES_LEGENDS,
    payload: legends,
  }
}

export const setScenarioAlwaysCopy = () => {
  localStorage.setItem("scenarioAlwaysCopy", true)

  return {
    type: SET_SCENARIO_ALWAYS_COPY,
    payload: true,
  }
}

export const setScenarioModalOpen = (isOpen) => ({
  type: SET_SCENARIO_MODAL_OPEN,
  payload: isOpen,
})

export const setScenarioFiltersOpen = (areOpen) => ({
  type: SET_SCENARIO_FILTERS_OPEN,
  payload: areOpen,
})

export const setScenarioSelectedBuilding = (building) => {
  return {
    type: SET_SCENARIO_SELECTED_BUILDING,
    payload: building,
  }
}

export const setScenarioMapSearch = (text) => ({
  type: SET_SCENARIO_MAP_SEARCH,
  payload: text,
})

export const setScenarioDrawMode = (mode) => ({
  type: SET_SCENARIO_DRAW_MODE,
  payload: mode,
})

export const initScenarioState = (projectId) => {
  return {
    type: INIT_SCENARIO_STATE,
    payload: {
      mapAttributesLegends:
        JSON.parse(
          localStorage.getItem(`project${projectId}ScenarioMapAttributesLegends`)
        ) || [],
      mapFilters:
        JSON.parse(localStorage.getItem(`project${projectId}ScenarioMapFilters`)) ||
        [],
      alwaysCopy: !!JSON.parse(localStorage.getItem("scenarioAlwaysCopy")),
    },
  }
}

export const setScenarioMapFilters = (filters, projectId) => {
  localStorage.setItem(
    `project${projectId}ScenarioMapFilters`,
    JSON.stringify(filters)
  )

  return {
    type: SET_SCENARIO_MAP_FILTERS,
    payload: filters,
  }
}

export const updateScenarioSelectedBuilding = (currentBuilding, criteria, value) => {
  return (dispatch, selector) => {
    const updatedBuilding = getBuildingWithUpdatedCustomValue(
      currentBuilding,
      criteria.name,
      value
    )
    dispatch({
      type: SET_SCENARIO_SELECTED_BUILDING,
      payload: updatedBuilding,
    })
    const { scenariosReducer } = selector()
    const buildings = [...scenariosReducer.buildings]
    const newBuildings = getAllBuildingsWithUpdatedCustomValue(
      buildings,
      currentBuilding,
      criteria.name,
      value
    )
    dispatch(setScenarioBuildings(newBuildings))
  }
}

export const exportSSSDisplayedBuildings = ({
  filteredBuildings,
  scenarioId,
  exportType,
  fileType,
  trans,
}) => {
  return async (_dispatch) => {
    const exportTypePath = getExportTypePath(exportType, 2)

    const fileTypeUrl = getFileTypeQuery(fileType)
    const finalUrl = `/sc/${scenarioId}${exportTypePath}?${fileTypeUrl}`

    downloadBuildings({
      url: finalUrl,
      trans,
      fileType,
      buildings: filteredBuildings,
    })
  }
}
