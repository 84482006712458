import {
  SET_COLLECTION,
  SET_LOADING_COLLECTION,
  SET_COLLECTIONS,
  SET_LOADING_COLLECTIONS,
  SET_LOADING_COLLECTION_VERSIONS,
  SET_COLLECTION_VERSIONS,
  SET_CURRENT_COLLECTION_ID,
  REMOVE_COLLECTION,
  UPDATE_COLLECTION_NAME,
} from "redux/actions/collectionActions"

const defaultState = {
  collections: [],
  collection: null,
  loadingCollection: false,
  collectionVersions: [],
  loadingCollections: true,
  loadingCollectionVersions: false,
  currentCollectionId: null,
}

export default function collectionReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING_COLLECTIONS:
      return {
        ...state,
        loadingCollections: true,
      }
    case SET_COLLECTIONS:
      return {
        ...state,
        collections: action.payload.collections,
        loadingCollections: false,
      }
    case SET_LOADING_COLLECTION:
      return {
        ...state,
        loadingCollection: true,
      }
    case SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
        loadingCollection: false,
      }
    case SET_CURRENT_COLLECTION_ID:
      return {
        ...state,
        currentCollectionId: action.payload.collectionId,
      }
    case SET_LOADING_COLLECTION_VERSIONS:
      return {
        ...state,
        loadingCollectionVersions: true,
      }
    case SET_COLLECTION_VERSIONS:
      return {
        ...state,
        collectionVersions: action.payload.collectionVersions,
        loadingCollectionVersions: false,
      }
    case REMOVE_COLLECTION:
      return {
        ...state,
        collections: state.collections.filter(
          (collection) => collection.id !== action.payload.id
        ),
        collectionVersions:
          state.collection?.id === action.payload?.id
            ? []
            : state.collectionVersions,
      }
    case UPDATE_COLLECTION_NAME:
      return {
        ...state,
        collections: state.collections.map((collection) => {
          if (collection.id === action.payload.id) {
            collection.name = action.payload.name
            return collection
          } else return collection
        }),
      }
    default:
      return state
  }
}
