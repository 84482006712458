import { forwardRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
  colorsClassNames,
  colorsPropTypes,
  bgColorsClassNames,
  bgColorsPropTypes,
} from "../helpers/color"
import { paddingClassNames, paddingPropTypes } from "../helpers/padding"
import { marginClassNames, marginPropTypes } from "../helpers/margin"
import { widthClassNames, widthPropTypes } from "../helpers/width"
import { overflowClassNames, overflowPropTypes } from "../helpers/overflow"

const alignmentPropTypes = [
  "flexStart",
  "flexEnd",
  "center",
  "baseline",
  "spaceBetween",
  "spaceAround",
  "stretch",
]

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
    PropTypes.object,
  ]),
  color: PropTypes.oneOf(colorsPropTypes),
  bgColor: PropTypes.oneOf(bgColorsPropTypes),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  column: PropTypes.bool,
  pointer: PropTypes.bool,
  width: PropTypes.oneOf(widthPropTypes),
  alignItems: PropTypes.oneOf(alignmentPropTypes),
  justifyContent: PropTypes.oneOf(alignmentPropTypes),
  overflow: PropTypes.oneOf(overflowPropTypes),
  resizable: PropTypes.bool,
  style: PropTypes.object,
  p: PropTypes.oneOf(paddingPropTypes),
  pT: PropTypes.oneOf(paddingPropTypes),
  pR: PropTypes.oneOf(paddingPropTypes),
  pB: PropTypes.oneOf(paddingPropTypes),
  pL: PropTypes.oneOf(paddingPropTypes),
  pH: PropTypes.oneOf(paddingPropTypes),
  pV: PropTypes.oneOf(paddingPropTypes),
  m: PropTypes.oneOf(marginPropTypes),
  mT: PropTypes.oneOf(marginPropTypes),
  mR: PropTypes.oneOf(marginPropTypes),
  mB: PropTypes.oneOf(marginPropTypes),
  mL: PropTypes.oneOf(marginPropTypes),
  mH: PropTypes.oneOf(marginPropTypes),
  mV: PropTypes.oneOf(marginPropTypes),
  direction: PropTypes.oneOf(["rtl", "ltr"]),
}

// eslint-disable-next-line react/display-name
const Flex = forwardRef(
  (
    {
      children,
      onClick,
      onMouseDown,
      onDoubleClick,
      onMouseEnter,
      onMouseLeave,
      color,
      bgColor,
      className,
      width,
      overflow,
      p,
      pT,
      pR,
      pB,
      pL,
      pH,
      pV,
      m,
      mT,
      mR,
      mB,
      mL,
      mH,
      mV,
      pointer,
      alignItems,
      justifyContent,
      column,
      style = {},
      direction,
      ...rest
    },
    ref
  ) => (
    <div
      ref={ref}
      className={classNames(className, "flex", {
        ...colorsClassNames(color),
        ...bgColorsClassNames(bgColor),
        ...paddingClassNames(p, pT, pR, pB, pL, pH, pV),
        ...marginClassNames(m, mT, mR, mB, mL, mH, mV),
        ...widthClassNames(width),
        ...overflowClassNames(overflow),
        pointer,
        column,
        alignEnd: alignItems === "flexEnd",
        alignStart: alignItems === "flexStart",
        alignCenter: alignItems === "center",
        alignBaseline: alignItems === "baseline",
        justifySpaceBetween: justifyContent === "spaceBetween",
        justifySpaceAround: justifyContent === "spaceAround",
        justifyEnd: justifyContent === "flexEnd",
        justifyStart: justifyContent === "flexStart",
        justifyCenter: justifyContent === "center",
        justifyStretch: justifyContent === "stretch",
      })}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      style={{ direction, ...style }}
      {...rest}
    >
      {children}
    </div>
  )
)

Flex.propTypes = propTypes

export default Flex
