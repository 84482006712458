import {
  getChartDetailData,
  getStatePayload,
  getUpdatedAxesPositioning,
  getUpdatedTabs,
} from "components/MSS/commons/chartTabsHelpers"
import { sortByKey } from "helpers/arrayHelpers"
import axisService from "services/axisService"
import chartService from "services/chartService"

export const RESET_MSS_STATE = "RESET_MSS_STATE"
export const SET_MSS_STATE = "SET_MSS_STATE"
export const UPDATE_MSS_STATE = "UPDATE_MSS_STATE"
export const UPDATE_AXES = "UPDATE_AXES"
export const CHANGE_COLORED_AXE = "CHANGE_COLORED_AXE"
export const SET_HIGHLIGHTED_CARD = "SET_HIGHLIGHTED_CARD"
export const SET_HIGHLIGHTED_AXES = "SET_HIGHLIGHTED_AXES"
export const UPDATE_BRUSH = "UPDATE_BRUSH"
export const SET_CRITERIA_RANGE = "SET_CRITERIA_RANGE"
export const SET_LIMIT = "SET_LIMIT"

export const SET_DEFAULT_CHART_DATA = "SET_DEFAULT_CHART_DATA"
export const SET_DEFAULT_CHART_LOADING = "SET_DEFAULT_CHART_LOADING"
export const SET_TABS = "SET_TABS"
export const SET_AXES = "SET_AXES"
export const CHANGE_TAB_STATE = "CHANGE_TAB_STATE"
export const SET_SELECTED_TAB = "SET_SELECTED_TAB"
export const ADD_TAB = "ADD_TAB"
export const DELETE_TAB = "DELETE_TAB"
export const UPDATE_TAB = "UPDATE_TAB"

export const SET_FILTERED_SCENARIOS = "SET_FILTERED_SCENARIOS"
export const SET_CURRENT_CRITERIAS_TO_EXPORT = "SET_CURRENT_CRITERIAS_TO_EXPORT"
export const SET_SSS_LOADING = "SET_SSS_LOADING"

export const updateBrush = (axeData, tabs, selectedTabIndex) => {
  axisService.updateAxe(axeData)
  const newTabs = getUpdatedTabs(tabs, tabs[selectedTabIndex], axeData)
  const { criteria, brush_min, brush_max } = axeData
  return {
    type: UPDATE_BRUSH,
    payload: {
      brushId: criteria,
      brushVal: [brush_min, brush_max],
      tabs: newTabs,
    },
  }
}

export const setCriteriaRange = (axeData, tabs, selectedTabIndex) => {
  axisService.updateAxe(axeData)
  const newTabs = getUpdatedTabs(tabs, tabs[selectedTabIndex], axeData)
  const { criteria, display_min, display_max } = axeData
  return {
    type: SET_CRITERIA_RANGE,
    payload: {
      range: { [criteria]: { display_min, display_max } },
      tabs: newTabs,
    },
  }
}

export const setLimit = (axeData, tabs, selectedTabIndex) => {
  axisService.updateAxe(axeData)
  const newTabs = getUpdatedTabs(tabs, tabs[selectedTabIndex], axeData)
  const { criteria, optim_sense } = axeData
  return {
    type: SET_LIMIT,
    payload: {
      limit: { [criteria]: optim_sense },
      tabs: newTabs,
    },
  }
}

export const setColoredAxe = (
  axeData,
  tabs,
  selectedTabIndex,
  previousSelectedAxe
) => {
  axisService.updateAxe(axeData)
  let newTabs = getUpdatedTabs(tabs, tabs[selectedTabIndex], axeData)
  if (previousSelectedAxe)
    newTabs = getUpdatedTabs(newTabs, newTabs[selectedTabIndex], {
      ...previousSelectedAxe,
      color_by: false,
    })
  return {
    type: CHANGE_COLORED_AXE,
    payload: {
      coloredAxe: axeData.color_by ? axeData.criteria : "",
      tabs: newTabs,
    },
  }
}

export const setHighlightedCard = (scenarioId) => {
  return {
    type: SET_HIGHLIGHTED_CARD,
    payload: scenarioId,
  }
}

export const setHighlightedAxes = (newHighlightedAxes) => {
  return {
    type: SET_HIGHLIGHTED_AXES,
    payload: newHighlightedAxes,
  }
}

export const setAxes = ({ newAxes, selectedTabIndex, ...rest }) => {
  return (dispatch, selector) => {
    const { mssReducer } = selector()
    const newTabs = [...mssReducer.tabs]
    if (!selectedTabIndex) selectedTabIndex = mssReducer.selectedTabIndex
    newTabs[selectedTabIndex].axes = newAxes
    dispatch({
      type: SET_AXES,
      payload: {
        newTabs,
        statePayload: rest,
      },
    })
  }
}

export const updateAxes = (newAxes, selectedTabIndex) => (dispatch, selector) => {
  const { mssReducer } = selector()
  const { chartId, changedAxes, newTabs } = getUpdatedAxesPositioning({
    tabs: mssReducer.tabs,
    selectedTabIndex: selectedTabIndex || mssReducer.selectedTabIndex,
    axes: newAxes,
  })
  dispatch({
    type: UPDATE_AXES,
    payload: {
      axes:
        selectedTabIndex === mssReducer.selectedTabIndex ? newAxes : mssReducer.axes,
      tabs: newTabs || mssReducer.tabs,
    },
  })
  axisService.updateMultipleAxes(chartId, changedAxes)
}

export const initDefaultAxis = (useCase) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DEFAULT_CHART_LOADING,
      payload: true,
    })
    chartService.getUseCase(useCase).then(({ chart }) =>
      chartService.getChartDetail(chart).then((chartDefault) => {
        dispatch({
          type: SET_DEFAULT_CHART_DATA,
          payload: chartDefault || [],
        })
      })
    )
  }
}

export const setMssState = ({ chartId, ...newState }) => {
  return async (dispatch) => {
    const chartData = getChartDetailData(newState)
    const newTabData = await chartService.updateChartDetail(chartId, chartData)
    const statePayload = getStatePayload(newState)
    dispatch({
      type: SET_MSS_STATE,
      payload: {
        statePayload,
        tab: { ...newTabData },
      },
    })
  }
}

export const updateMssState = ({ chartId, newTab, ...updatedState }) => {
  return async (dispatch) => {
    axisService.updateMultipleAxes(chartId, newTab.axes)
    const statePayload = getStatePayload(updatedState)
    dispatch({
      type: SET_MSS_STATE,
      payload: {
        statePayload,
        tab: { ...newTab },
      },
    })
  }
}

export const changeTab = (newTabState) => {
  const statePayload = getStatePayload(newTabState)
  return {
    type: CHANGE_TAB_STATE,
    payload: statePayload,
  }
}

export const initTabs = (projectId) => {
  return async (dispatch) => {
    const projectTabs = await chartService.getAllProjectCharts(projectId)
    dispatch({
      type: UPDATE_MSS_STATE,
      payload: {
        tabs: sortByKey(projectTabs),
        selectedTabIndex: 0,
      },
    })
  }
}

export const setSelectedChartTab = (tabId) => {
  return {
    type: SET_SELECTED_TAB,
    payload: tabId,
  }
}

export const addTab = (tab) => {
  return {
    type: ADD_TAB,
    payload: tab,
  }
}
export const deleteTab = (tabId) => {
  return {
    type: DELETE_TAB,
    payload: tabId,
  }
}
export const updateTab = (newTabData) => {
  return {
    type: UPDATE_TAB,
    payload: newTabData,
  }
}

export const setTabs = (newTabs) => {
  return {
    type: SET_TABS,
    payload: newTabs,
  }
}

export const getUpdatedBrushes = ({ brushes, brushId, brushVal }) => {
  const newBrushes = { ...brushes }
  if (brushVal[0] || brushVal[1]) {
    newBrushes[brushId] = brushVal
  } else {
    delete newBrushes[brushId]
  }
  return newBrushes
}

export const setFilteredScenarios = (scenarios) => {
  return {
    type: SET_FILTERED_SCENARIOS,
    payload: {
      filteredScenarios: scenarios,
    },
  }
}

export const setCurrentCriteriasToExport = (criterias) => {
  return {
    type: SET_CURRENT_CRITERIAS_TO_EXPORT,
    payload: {
      currentCriteriasToExport: criterias,
    },
  }
}

export const setSssLoading = (loadingState) => {
  return {
    type: SET_SSS_LOADING,
    payload: loadingState,
  }
}
