export const colorsPropTypes = [
  "",
  "primary",
  "light",
  "dark",
  "warning",
  "danger",
  "success",
  "darkestGrey",
  "mediumGrey",
  "darkerGrey",
  "lightDark",
  "lightGrey",
  "brandLightGrey",
  "brandBlue",
  "brandGrey",
  "brandBlack",
]

export const colorsClassNames = (value) => ({
  primary: value === "primary",
  light: value === "light",
  dark: value === "dark",
  warning: value === "warning",
  danger: value === "danger",
  success: value === "success",
  darkestGrey: value === "darkestGrey",
  mediumGrey: value === "mediumGrey",
  darkerGrey: value === "darkerGrey",
  lightDark: value === "lightDark",
  brandLightGrey: value === "brandLightGrey",
  lightGrey: value === "lightGrey",
  brandBlue: value === "brandBlue",
  brandGrey: value === "brandGrey",
  brandBlack: value === "brandBlack",
})

export const bgColorsPropTypes = [
  "",
  "primary",
  "light",
  "dark",
  "warning",
  "danger",
  "success",
  "white",
  "darkerGrey",
  "lightDark",
  "brandLightGrey",
  "blues",
]

export const bgColorsClassNames = (value) => ({
  "bg-primary": value === "primary",
  "bg-light": value === "light",
  "bg-dark": value === "dark",
  "bg-white": value === "white",
  "bg-danger": value === "danger",
  "bg-success": value === "success",
  "bg-warning": value === "warning",
  "bg-darkerGrey": value === "darkerGrey",
  "bg-lightDark": value === "lightDark",
  "bg-brandLightGrey": value === "brandLightGrey",
  "bg-blues-6": value === "blues",
})
