import requestService from "./requestService"

class scenariosService {
  async getScenario(scenarioId = "3565") {
    const data = await requestService.get(`/sc/${scenarioId}`)

    return data
  }

  async getNetworks(scenarioId = "3788") {
    const data = await requestService.get(`/sc/${scenarioId}/nf`)

    return data
  }

  async getBuildings(scenarioId = "3788") {
    const data = await requestService.get(`/sc/${scenarioId}/b2`)

    return data
  }

  async createScenario(scenarioId = "3788") {
    try {
      const { id } = await requestService.post(`/sc/${scenarioId}`)

      return id
    } catch (e) {
      console.log("Cannot create scenario", e)
      return null
    }
  }

  async updateScenario(scenarioId, data) {
    const res = await requestService.patch(`/sc/${scenarioId}`, null, data)
    return res
  }

  async discardScenario(scenarioId) {
    const res = await requestService.patch(`/sc/${scenarioId}`, null, {
      discarded: true,
    })
    return res
  }

  async createNetworkLine(scenarioId, data) {
    return await requestService.post(`/sc/${scenarioId}/nf`, null, data)
  }

  async updateNetworkLine(scenarioId, networkLineId, data) {
    return await requestService.put(
      `/sc/${scenarioId}/nf/${networkLineId}`,
      null,
      data
    )
  }

  async deleteNetworkLine(scenarioId, networkLineId) {
    return await requestService.delete(`/sc/${scenarioId}/nf/${networkLineId}`, null)
  }
}

export default new scenariosService()
