export const SET_MENU_OPEN = "SET_MENU_OPEN"
export const SET_MAP_VIEW = "SET_MAP_VIEW"

export const setMenuOpen = (menuOpen) => {
  return {
    type: SET_MENU_OPEN,
    payload: menuOpen,
  }
}

export const setAppliedMapLayerName = (selectedMapView) => {
  return {
    type: SET_MAP_VIEW,
    payload: selectedMapView,
  }
}
