import requestService from "./requestService"

class chartService {
  async getUseCaseList() {
    const data = await requestService.get(`/uc`)
    return data
  }

  async getUseCase(id) {
    const data = await requestService.get(`/uc?id=${id}`)
    return data[0]
  }

  async getAllProjectCharts(projectId) {
    const data = await requestService.get(`/p/${projectId}/ch`)
    return data
  }

  async createNewProjectChart(projectId, newChartData) {
    const data = await requestService.post(`/p/${projectId}/ch`, null, newChartData)
    return data
  }

  async getChartDetail(chartId) {
    const data = await requestService.get(`/ch/${chartId}`)
    return data
  }

  async createNewGlobalChart(newChartData) {
    const data = await requestService.post(`/ch`, null, newChartData)
    return data
  }

  async updateChartDetail(chartId, data) {
    return requestService.patch(`/ch/${chartId}`, null, data)
  }

  async deleteChart(chartId) {
    return await requestService.delete(`/ch/${chartId}`, null)
  }

  async getChartList(chart_type) {
    const data = await requestService.get(`/ch?chart_type=${chart_type}`)
    return data
  }
}

export default new chartService()
