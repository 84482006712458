export const cloneMenuItem = (item) => {
  const clonedElement = item.cloneNode(true)
  const itemPosition = item.getBoundingClientRect()

  clonedElement.style.position = "absolute"
  clonedElement.style.top = `${itemPosition.y}px`
  clonedElement.style.width = `${itemPosition.width}px`
  clonedElement.style.height = `${itemPosition.height}px`
  clonedElement.style.left = `${itemPosition.left}px`
  clonedElement.style.backgroundColor = "#fff"
  clonedElement.style.zIndex = 110

  return clonedElement
}

export const getModalContentStyles = (itemPosition, height = 150, width = 285) => {
  const padding = 10
  // Get body height - padding
  const bodyHeight = document.body.offsetHeight - 25

  // Calculate modal top based on item position and body height
  const modalTop =
    itemPosition?.y + height < bodyHeight ? itemPosition.y : bodyHeight - height

  const arrowTop = itemPosition.y - modalTop + padding

  return {
    content: {
      width: `${width}px`,
      height: `${height}px`,
      position: "absolute",
      padding: `${padding}px`,
      overflow: "visible",
      boxShadow: "4px 4px 24px rgba(51, 51, 51, 0.28)",
      top: `${modalTop}px`,
      left: itemPosition?.width + 20,
    },
    arrow: {
      top: `${arrowTop}px`,
    },
  }
}
