export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING"
export const LOGIN_USER_FULFILLED = "LOGIN_USER_FULFILLED"
export const USER_INITIALIZED = "USER_INITIALIZED"
export const ONBOARDING_SKIPPED = "ONBOARDING_SKIPPED"
export const ONBOARDING_SEEN = "ONBOARDING_SEEN"
export const LOGIN_USER_REJECTED = "LOGIN_USER_REJECTED"
export const SIGNUP_USER_PENDING = "SIGNUP_USER_PENDING"
export const SIGNUP_USER_FULFILLED = "SIGNUP_USER_FULFILLED"
export const SIGNUP_USER_REJECTED = "SIGNUP_USER_REJECTED"
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING"
export const UPDATE_PASSWORD_FULFILLED = "UPDATE_PASSWORD_FULFILLED"
export const UPDATE_USER = "UPDATE_USER"
export const LOGOUT_USER_FULFILLED = "LOGOUT_USER_FULFILLED"
export const UPDATE_PASSWORD_REJECTED = "UPDATE_PASSWORD_REJECTED"
export const SET_USER_ORGANIZATION_TERITORY = "SET_USER_ORGANIZATION_TERITORY"

import { RESET_MSS_STATE } from "."
import requestService from "services/requestService"
import { throwErrors } from "helpers/errors"
import { toast } from "react-toastify"
import userService from "services/userService"

const notifyAboutErrors = (err) => {
  const errors = err?.data || {}

  for (let errKey in errors) {
    const errVal = errors[errKey]

    if (Array.isArray(errVal)) {
      return errVal.forEach((msg) =>
        toast(msg, {
          type: "error",
        })
      )
    }

    toast(errVal, {
      type: "error",
    })
  }
}

export const reinitUser = (language) => {
  return async (dispatch) => {
    const token = requestService.getToken()
    const user = await requestService.reinitUser()
    if (language && user && language !== user.language) user.language = language

    const now = new Date()

    const onboardingSeen = !!localStorage.getItem("onboardingSeen")
    const onboardingSkippedAt = localStorage.getItem("onboardingSkipped")

    /**
     * If onboarding was skipped 12 ours ago, show the onboarding again
     */
    const onboardingSkipped =
      !onboardingSkippedAt && onboardingSeen
        ? false
        : (now.valueOf() - parseInt(onboardingSkippedAt, 10)) / 1000 / 60 / 60 < 12

    dispatch({
      type: USER_INITIALIZED,
      payload: { user, token, onboardingSkipped, onboardingSeen },
    })
  }
}

export const skipOnboarding = () => {
  const now = new Date()
  localStorage.setItem("onboardingSkipped", now.valueOf())

  return {
    type: ONBOARDING_SKIPPED,
  }
}

export const setOnboardingSeen = () => {
  localStorage.setItem("onboardingSeen", true)

  return {
    type: ONBOARDING_SEEN,
  }
}

export const loginUser = (data, cb) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER_PENDING,
    })

    try {
      const token = await userService.login(data)
      requestService.setToken(token)

      const user = await userService.getUser()
      requestService.setUser(user)

      dispatch({
        type: LOGIN_USER_FULFILLED,
        payload: { user, token },
      })

      localStorage.setItem("user", JSON.stringify(user))
      cb(user.language)
    } catch (err) {
      notifyAboutErrors(err)

      dispatch({
        type: LOGIN_USER_REJECTED,
      })
    }
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    requestService.setToken(null)
    requestService.setUser(null)
    dispatch({
      type: LOGOUT_USER_FULFILLED,
      payload: null,
    })
    dispatch({
      type: RESET_MSS_STATE,
    })
  }
}

export const signupUser = (data, successCallback) => {
  return async (dispatch) => {
    dispatch({
      type: SIGNUP_USER_PENDING,
    })

    try {
      const user = await userService.signup(data)

      dispatch({
        type: SIGNUP_USER_FULFILLED,
        payload: user,
      })

      typeof successCallback === "function" && successCallback()
    } catch (err) {
      notifyAboutErrors(err)

      dispatch({
        type: SIGNUP_USER_REJECTED,
      })
    }
  }
}

export const sendResetPasswordEmail = (data, successCallback) => {
  return async () => {
    try {
      await userService.sendResetPasswordEmail(data)

      typeof successCallback === "function" && successCallback()
    } catch (err) {
      notifyAboutErrors(err)
    }
  }
}

export const updateUserPassword = (data, successCallback) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD_PENDING,
    })

    try {
      const user = await userService.sendNewPasswordForReset(data)

      dispatch({
        type: UPDATE_PASSWORD_FULFILLED,
        payload: user,
      })

      toast("Password updated successfully", {
        type: "success",
      })

      typeof successCallback === "function" && successCallback()
    } catch (err) {
      notifyAboutErrors(err)

      dispatch({
        type: UPDATE_PASSWORD_REJECTED,
      })
    }
  }
}

export const updateUser = (user, token) => (dispatch) => {
  localStorage.setItem("user", JSON.stringify(user))
  return dispatch({
    type: UPDATE_USER,
    payload: { user, token },
  })
}

export const getUserOrganizationTeritory = () => {
  return async (dispatch) => {
    try {
      const data = await userService.getUserOrganizationTeritory()
      dispatch({
        type: SET_USER_ORGANIZATION_TERITORY,
        payload: data?.territory || null,
      })
    } catch (err) {
      throwErrors(err)
      dispatch({
        type: SET_USER_ORGANIZATION_TERITORY,
        payload: null,
      })
    }
  }
}
