import buildingsService from "services/buildingsService"

export const SET_BUILDING_ATTRIBUTES = "SET_BUILDING_ATTRIBUTES"
export const SET_ENERGY_SYSTEMS = "SET_ENERGY_SYSTEMS"
export const SET_ENERGY_CARRIERS = "SET_ENERGY_CARRIERS"
export const SET_BUILDING_FILTERED_ATTRIBUTES = "SET_BUILDING_FILTERED_ATTRIBUTES"
export const SET_LOADING_HEATING_SYSYEMS = "SET_LOADING_HEATING_SYSYEMS"
export const SET_HEATING_SYSTEMS = "SET_HEATING_SYSYEMS"
export const SET_TOTAL_BUILDINGS_NR_ATTRIBUTE = "SET_TOTAL_BUILDINGS_NR_ATTRIBUTE"
export const SET_BUILDING_BOUNDS_BY_ID = "SET_BUILDING_BOUNDS_BY_ID"
export const Building = "Building"
export const EnergyCarrier = "EnergyCarrier"
export const _EnergyConversionSystem = "_EnergyConversionSystem"
export const Custom = "Custom"

export const defaultLimit = 200

const getAllClassAttributes = async (
  class_name,
  extraQuerryParams,
  prevAttributes = [],
  offset = 0
) => {
  const initQueryParam =
    class_name === _EnergyConversionSystem ? "baseclass_name" : "class_name"

  const attributes = await buildingsService.getAttributes(
    class_name,
    offset,
    extraQuerryParams,
    initQueryParam
  )

  if (Array.isArray(attributes)) {
    if (attributes.length < defaultLimit) {
      return [...prevAttributes, ...attributes]
    }
    const nextAttributes = [...prevAttributes, ...attributes]
    return getAllClassAttributes(
      class_name,
      extraQuerryParams,
      nextAttributes,
      offset + defaultLimit
    )
  }
  return prevAttributes
}

export const getBuildingAttributes = () => async (dispatch) => {
  const data = {
    [Building]: await getAllClassAttributes(Building),
    [EnergyCarrier]: await getAllClassAttributes(EnergyCarrier),
    [_EnergyConversionSystem]: await getAllClassAttributes(_EnergyConversionSystem),
    [Custom]: await getAllClassAttributes(Custom),
  }

  dispatch({
    type: SET_BUILDING_ATTRIBUTES,
    payload: data,
  })
}

export const getBuildingFilteredAttributes =
  (extraQuerryParams = "") =>
  async (dispatch) => {
    try {
      extraQuerryParams = extraQuerryParams + "&filterable=true"
      const data = {
        [Building]: await getAllClassAttributes(Building, extraQuerryParams),
        [EnergyCarrier]: await getAllClassAttributes(
          EnergyCarrier,
          extraQuerryParams
        ),
        [_EnergyConversionSystem]: await getAllClassAttributes(
          _EnergyConversionSystem,
          extraQuerryParams
        ),
      }

      if (!Array.isArray(data[Building])) {
        data[Building] = []
      }
      if (!Array.isArray(data[EnergyCarrier])) {
        data[EnergyCarrier] = []
      }
      if (!Array.isArray(data[_EnergyConversionSystem])) {
        data[_EnergyConversionSystem] = []
      }

      dispatch({
        type: SET_BUILDING_FILTERED_ATTRIBUTES,
        payload: data,
      })
    } catch (e) {
      dispatch({
        type: SET_BUILDING_FILTERED_ATTRIBUTES,
        payload: [],
      })
    }
  }

export const getBuildingEnergySystems = () => async (dispatch) => {
  const data = await buildingsService.getEnergySystems()

  dispatch({
    type: SET_ENERGY_SYSTEMS,
    payload: data,
  })
}

export const getHeatingSystems = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_HEATING_SYSYEMS })

      const systems = await buildingsService.getHeatingSystems()
      dispatch({
        type: SET_HEATING_SYSTEMS,
        payload: systems,
      })
    } catch (e) {
      throw new Error("Cannot get heating systems")
    }
  }
}

export const getBuildingEnergyCarriers = () => async (dispatch) => {
  const data = await buildingsService.getEnergyCarriers()

  dispatch({
    type: SET_ENERGY_CARRIERS,
    payload: data,
  })
}

export const setTotalBuildingsNumberAttribute = () => async (dispatch) => {
  const data = await buildingsService.getTotalBuildingsNrAttribute()

  dispatch({
    type: SET_TOTAL_BUILDINGS_NR_ATTRIBUTE,
    payload: data,
  })
}

export const getBuildingBoundsById = (id) => async (dispatch) => {
  const buildingAttributesBounds = await buildingsService.getBuildingBoundsById(id)
  dispatch({
    type: SET_BUILDING_BOUNDS_BY_ID,
    payload: { id, value: buildingAttributesBounds?.bounds },
  })
}
