export const SET_SCENARIOS = "SET_SCENARIOS"
export const SET_CARDS_TAB_SCENARIOS = "SET_CARDS_TAB_SCENARIOS"
export const ADD_SCENARIOS = "ADD_SCENARIOS"
export const SET_LOADING_SCENARIOS = "SET_LOADING_SCENARIOS"
export const SET_PROJECT = "SET_PROJECT"
export const SET_SCENARIO_BUILDINGS = "SET_SCENARIO_BUILDINGS"
export const SET_SCENARIO = "SET_SCENARIO"
export const SET_LOADING_SCENARIO = "SET_LOADING_SCENARIO"
export const SET_NETWORKS_AND_BUILDINGS = "SET_NETWORKS_AND_BUILDINGS"
export const SET_LOADING_NETWORKS_AND_BUILDINGS =
  "SET_LOADING_NETWORKS_AND_BUILDINGS"
export const SET_ITERATION_ID = "SET_ITERATION_ID"
export const DISCARD_SCENARIO = "DISCARD_SCENARIO"
export const SET_PROJECT_ERROR = "SET_PROJECT_ERROR"

import mapBuildings from "helpers/buildings"
import { checkValidScenarios } from "helpers/errors"
import { prepareBuildingsData } from "helpers/buildings"
import projectsService from "services/projectsService"
import scenarioService from "services/scenariosService"

export const scenarioLimit = 15
let currentProjectId = ""

const loadNextScenarios = (dispatch, projectId, offset, limit) => {
  projectsService
    .getProjectScenarios(projectId, offset, limit)
    .then((newScenarios) => {
      if (checkValidScenarios(newScenarios) && projectId === currentProjectId) {
        dispatch({
          type: ADD_SCENARIOS,
          payload: { scenarios: newScenarios, projectIdToLoadScenarios: projectId },
        })
        loadNextScenarios(dispatch, projectId, offset + limit, limit)
      } else {
        dispatch({
          type: SET_LOADING_SCENARIOS,
          payload: { projectIdToLoadScenarios: "" },
        })
      }
    })
}

export const getProjectAndScenarios = (projectId) => {
  currentProjectId = projectId
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_SCENARIOS,
      payload: { projectIdToLoadScenarios: projectId },
    })
    Promise.all([
      projectsService.getProject(projectId),
      projectsService.getProjectScenarios(projectId, 0, scenarioLimit),
    ])
      .then(([project, scenarios]) => {
        dispatch({
          type: SET_PROJECT,
          payload: { project, projectIdToLoadScenarios: projectId },
        })
        dispatch({
          type: SET_SCENARIOS,
          payload: {
            scenarios: Array.isArray(scenarios) ? scenarios : [],
            projectIdToLoadScenarios: projectId,
          },
        })
        loadNextScenarios(dispatch, projectId, scenarioLimit, scenarioLimit)
      })
      .catch((e) => {
        dispatch({
          type: SET_PROJECT,
          payload: { project: {}, projectError: e },
        })
      })
  }
}

export const checkProject = (projectId, scenariosLength) => {
  return (dispatch) => {
    projectsService
      .getProjectScenarios(projectId, 0, scenarioLimit)
      .then((scenarios) => {
        if (scenarios.length > scenariosLength) {
          dispatch({
            type: SET_SCENARIOS,
            payload: {
              scenarios: Array.isArray(scenarios) ? scenarios : [],
              projectIdToLoadScenarios: projectId,
            },
          })
          loadNextScenarios(dispatch, projectId, scenarioLimit, scenarioLimit)
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_PROJECT,
          payload: { project: {}, projectError: e },
        })
      })
  }
}

export const setScenarios = (scenarios) => {
  return {
    type: SET_SCENARIOS,
    payload: { scenarios },
  }
}

export const setCardsTabScenarios = (cardsTabScenarios) => {
  return {
    type: SET_CARDS_TAB_SCENARIOS,
    payload: { cardsTabScenarios },
  }
}

export const getScenarioBuildingsAndNetworks =
  (scenarioId, loadOnSilent) => async (dispatch) => {
    if (!loadOnSilent) {
      dispatch({ type: SET_LOADING_NETWORKS_AND_BUILDINGS })
    }

    try {
      const networksPromise = scenarioService.getNetworks(scenarioId)
      const buildingsPromise = scenarioService.getBuildings(scenarioId)
      const networks = await networksPromise
      const buildings = await buildingsPromise

      const buildingsWithId = prepareBuildingsData(buildings)

      dispatch({
        type: SET_NETWORKS_AND_BUILDINGS,
        payload: {
          buildings: mapBuildings(buildingsWithId?.features || []),
          networks: networks?.features || [],
          loadingNetworksAndBuildings: false,
        },
      })
    } catch (e) {
      dispatch({
        type: SET_NETWORKS_AND_BUILDINGS,
        payload: {
          buildings: [],
          networks: [],
          loadingNetworksAndBuildings: false,
          networksAndBuildingsError: "Cannot get networks and buildings",
        },
      })
    }
  }

export const getScenario = (scenarioId) => async (dispatch) => {
  dispatch({ type: SET_LOADING_SCENARIO })

  const scenario = await scenarioService.getScenario(scenarioId)

  dispatch({
    type: SET_SCENARIO,
    payload: scenario,
  })
}

export const addNewScenario = (scenario) => {
  return {
    type: ADD_SCENARIOS,
    payload: {
      scenarios: [scenario],
      projectIdToLoadScenarios: "",
    },
  }
}

export const setIterationId = (id) => {
  return {
    type: SET_ITERATION_ID,
    payload: id,
  }
}

export const discardScenario = (id) => async (dispatch) => {
  await scenarioService.discardScenario(id)
  dispatch({
    type: DISCARD_SCENARIO,
    payload: id,
  })
}

export const getNewScenariosAfterDiscard = (id, scenarios, cardsTabScenarios) => {
  return {
    scenarios: scenarios.filter((sc) => sc.id !== id),
    cardsTabScenarios: cardsTabScenarios.filter((sc) => sc.id !== id),
  }
}

export const setCurrentProject = (newProjectData) => {
  return {
    type: SET_PROJECT,
    payload: newProjectData,
  }
}

export const setProjectError = (value) => ({
  type: SET_PROJECT_ERROR,
  payload: value,
})

export const setScenarioBuildings = (buildings) => ({
  type: SET_SCENARIO_BUILDINGS,
  payload: buildings,
})
