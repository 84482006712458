import requestService from "./requestService"

class CollectionsService {
  async getCollections() {
    const data = await requestService.get("/cl?discarded=false")

    return data
  }

  async getCollection(collectionId) {
    const data = await requestService.get(`/cl/${collectionId}`)

    return data
  }

  async getCollectionVersions(collectionId = "1968690") {
    const data = await requestService.get(`/cl/${collectionId}/vs?discarded=false`)

    return data
  }

  async discardYourCollection(collection) {
    const data = await requestService.put(`/cl/${collection?.id}`, null, collection)

    return data
  }

  async discardACollectionYouDontOwn(collection) {
    const data = await requestService.patch(`/cl/${collection.id}`, null, {
      discarded: true,
    })

    return data
  }

  async deleteCollection(collectionId) {
    const data = await requestService.delete(`/cl/${collectionId}`)

    return data
  }

  async updateCollection(collectionId, data) {
    const res = await requestService.patch(`/cl/${collectionId}`, null, data)
    return res
  }

  async createVersionForCollection(collectionId = "1968690") {
    try {
      const { version_id } = await requestService.post(`/cl/${collectionId}/vs`)

      return version_id
    } catch (e) {
      console.log("Cannot create version of collection", e)
      return null
    }
  }

  async createCollection(collectionData) {
    const data = await requestService.post("/cl", null, collectionData)

    return data
  }

  async getSourceValues(collectionId, criteriaId, sourceId) {
    const data = await requestService.get(
      `/cl/${collectionId}/cr/${criteriaId}/sr/${sourceId}`
    )

    return data
  }

  async getSourceMetaData(sourceId) {
    const data = await requestService.get(`/sr/${sourceId}`)
    return data
  }

  async sendImportedCsvData(importedData) {
    const data = await requestService.post(`/f`, null, importedData)
    return data
  }

  async getAllCRSToSelectFrom() {
    const data = await requestService.get(`/crs?limit=10000&offset=0`)
    return data
  }

  async patchFavoriteCRS(crs) {
    const data = await requestService.patch(`/crs/${crs?.code}`, null, {
      favorite: !crs?.favorite,
    })
    return data
  }

  async patchSelectCRS(fileId, selectedCRSCode) {
    const data = await requestService.patch(`/f/${fileId}`, null, {
      crs: selectedCRSCode,
    })
    return data
  }

  async finaliseCreationProcess(collectionId) {
    const data = await requestService.post(`/cl/${collectionId}`)

    return data
  }

  async addDataToCollection(collectionData, collectionId, criteriaId) {
    const data = await requestService.post(
      `/cl/${collectionId}/cr/${criteriaId}`,
      null,
      collectionData
    )

    return data
  }

  async getGenericCriteriaValuesForSource(sourceId, criteriaId) {
    const data = await requestService.get(`/sr/${sourceId}/cr/${criteriaId}`)
    return data
  }

  async getBuildingsWithDefaultSystem(collectionId, systemName) {
    const data = await requestService.get(`/cl/${collectionId}/es/${systemName}`)

    return data
  }

  async getAllBuildingsOfCollection(collectionId) {
    const data = await requestService.get(`/cl/${collectionId}/b`)

    return data
  }
}

export default new CollectionsService()
