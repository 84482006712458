import { get, set } from "lodash"

export const constructionPeriodIntervals = [
  ["<", 1919],
  [1919, 1945],
  [1946, 1960],
  [1961, 1970],
  [1971, 1980],
  [1981, 1985],
  [1986, 1990],
  [1991, 1995],
  [1996, 2000],
  [2001, 2005],
  [2006, 2010],
  [2011, 2015],
  [2016, 2020],
  [">", 2020],
]

export const prepareBuildingsData = (rawBuildings) => {
  if (!Array.isArray(rawBuildings?.features)) return rawBuildings

  const newFeatures = rawBuildings?.features?.map((feature) => {
    set(feature, ["properties", "base", "id"], feature.id)

    const year_of_construction = get(feature, [
      "properties",
      "base",
      "year_of_construction",
    ])

    const [start, end] = getStartAndEndFromConstrPeriond(feature)

    set(feature, ["properties", "base", "constr_period_start"], start)
    set(feature, ["properties", "base", "constr_period_end"], end)

    if (year_of_construction) {
      set(
        feature,
        ["properties", "base", "year_of_construction_derived"],
        year_of_construction
      )

      return { ...feature }
    } else {
      return deriveYearOfConstruciton(feature, start, end)
    }
  })

  rawBuildings.features = [...newFeatures]

  return rawBuildings
}

const deriveYearOfConstruciton = (feature, start, end) => {
  if (start && end) {
    const calculatedYearOfConstruction = getYearOfConstructionByStartEnd(start, end)

    set(
      feature,
      ["properties", "base", "year_of_construction_derived"],
      calculatedYearOfConstruction
    )
  }

  return { ...feature }
}

const getStartAndEndFromConstrPeriond = (feature) => {
  const constr_period = get(feature, ["properties", "base", "constr_period"])

  if (!constr_period) return [null, null]

  if (constr_period[0] === "<") {
    const end = parseInt(constr_period.split("<")[1])
    return [end - 1, end - 1] // use 1 in order to avoid false negative of 0, !start
  }

  if (constr_period[0] === ">") {
    const start = parseInt(constr_period.split(">")[1])
    return [start + 1, start + 1] // random number, 3000, imaginary end of the years interval
  }

  const startEnd = constr_period.split("-")
  if (Array.isArray(startEnd)) return [parseInt(startEnd[0]), parseInt(startEnd[1])]

  return [null, null]
}

const getYearOfConstructionByStartEnd = (period_start, period_end) => {
  let intervalWithBiggestOverlap = constructionPeriodIntervals[0]
  let biggestOverlapYears = 0

  constructionPeriodIntervals.forEach((currentInterval) => {
    const onlyNumbersInterval = convertIfNeeded(currentInterval)

    const currentOverlapSize = getIntervalOverlapSize(
      [period_start, period_end],
      onlyNumbersInterval
    )

    if (currentOverlapSize > biggestOverlapYears) {
      intervalWithBiggestOverlap = [...currentInterval]
      biggestOverlapYears = currentOverlapSize
    }
  })

  if (biggestOverlapYears > 0) {
    if (intervalWithBiggestOverlap[0] === "<")
      return intervalWithBiggestOverlap[1] - 1

    if (intervalWithBiggestOverlap[0] === ">")
      return intervalWithBiggestOverlap[1] + 1

    return intervalWithBiggestOverlap[0] + 1
  } else return undefined
}

const getIntervalOverlapSize = (i1, i2) => {
  // intervals do not intersect
  if (i1[0] > i2[1] || i1[1] < i2[0]) return 0
  else {
    const intersection = [Math.max(i1[0], i2[0]), Math.min(i1[1], i2[1])]
    return intersection[1] - intersection[0]
  }
}

const convertIfNeeded = (interval) => {
  if (interval[0] === "<") return [0, interval[1]]
  if (interval[0] === ">") return [interval[1], 3000]

  return interval
}

export const getConstructionPeriodOverlappedInterval = () => {}

const normalizeText = (text = "") => {
  if (!text) {
    return ""
  }

  return text
    .toString()
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "")
    ?.toLowerCase()
}

const mapBuildings = (buildings = []) => {
  const searchableFields = ["city", "street", "house_number", "name"]

  return buildings.map((building) => {
    const properties = building.properties
    let textToSearch = []

    /**
     * We create a single property for search textToSearch, that will contain
     * the normalized property value and the value with accents
     * In this way, the user can also search for properties with è or à
     */
    searchableFields.forEach((key) => {
      const normalizedText = normalizeText(get(properties.base, key))

      if (normalizedText !== get(properties, key)?.toLowerCase()) {
        textToSearch.push(get(properties, key)?.toLowerCase())
      }

      textToSearch.push(normalizedText)
    })

    properties.textToSearch = textToSearch.join(" ")

    return building
  })
}

export const getBuildingWithUpdatedCustomValue = (
  building,
  criteriaName,
  newValue
) => ({
  ...building,
  custom: JSON.stringify({
    ...JSON.parse(building.custom),
    [criteriaName]: newValue,
  }),
})
export const getAllBuildingsWithUpdatedCustomValue = (
  buildings,
  currentBuilding,
  criteriaName,
  newValue
) => {
  const selectedBuildingId = JSON.parse(currentBuilding.base).id
  for (const index in buildings) {
    if (buildings[index].id === selectedBuildingId) {
      buildings[index].properties.custom[criteriaName] = newValue
    }
  }
  return buildings
}

export default mapBuildings
